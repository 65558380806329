<template>
  <div class="modal-mask" v-if="$store.state.modalImprimir" transition="modal" id="suporteAtModal" style="z-index: 9999;">
    <div class="modalScrollMobile">
      <div class="modal-wrapper">
        <div class="modal-fundo"></div>
        <div class="modal-container padding-0 modal-800">
          <div class="modal-header">
            <h3 class="modal-title">{{$t('imprimir')}}<button @click="SET_IMPRIMIR('')" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15 text-center">
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a4 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="guia" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?noprice='" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4_guias_sem_precos')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a4 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?sem_logo=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a4_sem_logo')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="copia || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?copia_do_original=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('copia')}}</a>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="triplicado || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?triplicado=1'" class="btn btn-primary btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('triplicado')}}</a>

            <div class="clearfix"></div>
            <a target="_blank" @click="SET_IMPRIMIR('')" v-if="a5 || $store.state.Demo || $store.state.dev" :href="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + form1.id + '/' + form1.token_download + '?tamanho=A5'" class="btn btn-info btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('a5')}}</a>
            <button v-if="talao" @click="imprimir_talao()" class="btn btn-warning btn-block-mobile margin-horizontal-10 margin-bottom-20" >{{l('talao')}}</button>
            <div class="clearfix margin-bottom-15"></div>
            <router-link v-if="PERMISSOES.configuracoes_empresa && !$store.state.modulo" to="/configuracoes/impressao">{{l('configuracao_impressoes')}}</router-link>
            <div class="clearfix"></div>
            <button class="btn btn-default btn-pure pull-right" type="button" @click="SET_IMPRIMIR('')">{{$t('sair')}}</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'modalImprimir',
  data () {
    return {
      form1: '',
      a4: false,
      a5: false,
      talao: false,
      triplicado: false,
      copia: false,
      guia: false
    }
  },
  watch: {
    '$route' () {
      this.SET_IMPRIMIR('')
    },
    '$store.state.modalImprimir' (val) {
      this.form1 = val
      if (val) {
        window.scrollTo(0, 0)
        let loja = this.$store.state.lojas.find(a => a.id === val.loja_id)
        if (!loja) {
          loja = {}
        }
        if (loja.a4 || (!loja.a4 && !loja.a5 && !loja.termico)) {
          this.a4 = true
        }
        if (loja.a5) {
          this.a5 = true
        }
        let tp = this.$store.state['tipos-documento'].find(a => a.id * 1 === val.tipo_documento_id * 1).tipificacao
        if (tp === 'GT' || tp === 'GR') {
          this.guia = true
        }
        if ((loja.termico || (!loja.a4 && !loja.a5 && !loja.termico)) && tp !== 'QS') {
          if (tp !== 'SSTK' && tp !== 'SINIV' && tp !== 'SINIC' && tp !== 'RFC' && tp !== 'FO') {
            this.talao = true
          }
        }
        if (val.impresso) {
          this.copia = true
        }
        if (this.$store.getters.TRIPLICADO && tp !== 'SSTK') {
          this.triplicado = true
        }
      }
    }
  },
  computed: {
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    }
  },
  mounted () {
    this.a4 = false
    this.a5 = false
    this.talao = false
    this.triplicado = false
    this.copia = false
    this.guia = false
  },
  methods: {
    ...mapMutations([
      'SET_IMPRIMIR',
      'SET_LOAD'
    ]),
    l (a) {
      return this.$t('impressao.' + a)
    },
    imprimir_talao () {
      /*
      let self = this
      self.SET_LOAD(true)
      let myframe = document.createElement('IFRAME')
      myframe.src = (this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + this.form1.id + '/' + this.form1.token_download + '?tamanho=talao'
      myframe.style.position = 'absolute'
      myframe.style.top = '-10000px'
      document.body.appendChild(myframe)
      myframe.onload = function () {
        myframe.contentWindow.focus()
        myframe.contentWindow.print()
        myframe.parentNode.removeChild(myframe)
        self.SET_LOAD()
      }
      this.SET_IMPRIMIR('')
      }
      */
      this.SET_LOAD(true)
      this.axios.get((this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + this.form1.id + '/' + this.form1.token_download + '?tamanho=talao').then((dta) => {
        let _id = 'id_' + new Date().getTime()

        let myframe = document.createElement('IFRAME')
        myframe.id = _id
        myframe.style = 'visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;'
        document.body.appendChild(myframe)
        myframe.contentWindow.document.open()
        myframe.contentWindow.document.write(dta.data)
        myframe.contentWindow.document.close()
        this.loadIframe(document.getElementById(_id))
      }, () => {
        this.SET_LOAD()
      })
    },
    loadIframe (doc) {
      let iframeDoc = doc.contentDocument || doc.contentWindow.document
      if ( iframeDoc.readyState  === 'complete' ) {
        window.setTimeout((a, doc) => {
          doc.contentWindow.focus()
          doc.contentWindow.print()
          a.SET_LOAD()
          a.SET_IMPRIMIR('')
        }, 10, this, doc)
        return
      }
      window.setTimeout((doc, a) => { a.loadIframe(doc) }, 10, doc, this)
    }
  }
}
</script>
