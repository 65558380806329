import Vue from 'vue'

Vue.filter('valor', val => {
  // , { style: 'currency', currency: 'EUR' }
  return new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale()).format(val)
})
Vue.filter('valor2', (val) => {
  /*
  let a1 = new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale()).format(val)
  if (Vue.i18n.locale() === 'pt') {
    let a = a1.split(',')
    if (a.length === 2) {
      if (a[1].length === 1) {
        return a1 + '0'
      }
      return new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale()).format(Math.round(val * 100) / 100)
    }
    return a1 + ',00'
  } else {
    let a = a1.split('.')
    if (a.length === 2) {
      if (a[1].length === 1) {
        return a1 + '0'
      }
      return new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale()).format(Math.round(val * 100) / 100)
    }
    return a1 + '.00'
  }
  */
  return new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale(), { minimumFractionDigits: 2 }).format(Math.round(val * 100) / 100)
})

Vue.filter('data', dta => {
  if (!dta) {
    return ''
  }
  let dta1 = dta.split('-')
  let data = new Date()
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth() + 1) && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('hoje')
  }
  data.setDate(data.getDate() - 1)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth() + 1) && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('ontem')
  }
  data.setDate(data.getDate() + 2)
  if (parseInt(dta1[0]) === parseInt(data.getFullYear()) && parseInt(dta1[1]) === parseInt(data.getMonth() + 1) && parseInt(dta1[2]) === parseInt(data.getDate())) {
    return Vue.i18n.translate('amanha')
  }
  return parseInt(dta1[2]) + ' ' + Vue.i18n.translate('meses.' + parseInt(dta1[1])).substring(0, 3).toUpperCase() + ' ' + dta1[0].substring(2, 4)
  // (parseInt(data.getFullYear()) !== parseInt(dta1[0]) ? ' ' + dta1[0].substring(2, 4) : '')
})
