<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group form-material padding-top-10" v-if="show">
    <div class="input-group pesquisarFixo" v-if="hideSearch">
      <b class="input-group-addon" v-if="!muitos">{{l('artigo')}}<span class="verm" v-if="obrigatorio">*</span></b>
      <b class="input-group-addon" v-else>{{l('artigos')}}<span class="verm" v-if="obrigatorio">*</span></b>
      <input autocomplete="off" type="text" class="form-control foco" :value="item1" v-$model="item1" :placeholder="$t('pesquisar_artigo')" ref="itens" id="itens" @keyup.prevent.stop="pesqArtigosAntes($event)" @paste="pesqArtigos()" @focus="SET_SCROLL()">

      <a v-if="hideCriarArtigo && artigos.length === 0" id="ad_artigo1" href="javascript:void(0)" class="icoinput text-warning" @click="$emit('addArtigo', item1); item1 = ''; artigos = [];" v-tooltip.top-start="l('ad_artigo')"><i class="glyphicon glyphicon-plus-sign"></i></a>
      <a v-if="artigos[0]" href="javascript:void(0)" class="icoinput text-primary" @click="artigos = []; item1 = ''"><i class="glyphicon glyphicon-remove"></i></a>
    </div>
    <div v-show="item1.length > 2 || artigos[0]">
      <div class="clearfix"></div>
      <div id="divTable">
        <table id="tabelaFixa" class="table table-hover width-full table-condensed table-striped margin-0">
          <thead v-if="artigos[0]" id="tabelaFixaThead">
            <tr class="pesquisar">
              <th class="FundoHeaderPesquisar">
                <a href="javascript:void(0)" @click="formUrlArtigos(1, 'codigo')" class="text-left FundoHeaderPesquisar"><i v-if="pesq.pesquisa.order_by.codigo == 'ASC' || pesq.pesquisa.order_by.codigo == 'DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.pesquisa.order_by.codigo == 'ASC','glyphicon-sort-by-alphabet-alt': pesq.pesquisa.order_by.codigo == 'DESC', 'margin-right-10': 1}"></i>{{$t('codigo')}}</a>
                <div class="clearfix"></div>
                <a href="javascript:void(0)" @click="formUrlArtigos(1, 'descricao')" class="visible-xs visible-sm text-left FundoHeaderPesquisar"><i v-if="pesq.pesquisa.order_by.descricao == 'ASC' || pesq.pesquisa.order_by.descricao == 'DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.pesquisa.order_by.descricao == 'ASC', 'glyphicon-sort-by-alphabet-alt': pesq.pesquisa.order_by.descricao == 'DESC', 'margin-right-10': 1}"></i>{{$t('descricao')}}</a>
              </th>
              <th class="FundoHeaderPesquisar hidden-xs hidden-sm">
                <a href="javascript:void(0)" @click="formUrlArtigos(1, 'descricao')" class="text-left FundoHeaderPesquisar"><i v-if="pesq.pesquisa.order_by.descricao == 'ASC' || pesq.pesquisa.order_by.descricao == 'DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.pesquisa.order_by.descricao == 'ASC', 'glyphicon-sort-by-alphabet-alt': pesq.pesquisa.order_by.descricao == 'DESC', 'margin-right-10': 1}"></i>{{$t('descricao')}}</a>
              </th>
              <th v-if="!hidePreco && page2 !== 'compras' && page !== 'quebrastock'" class="FundoHeaderPesquisar hidden-xs hidden-sm text-right">{{$t('itens.preco_custo')}} ({{MOEDA}})</th>
              <th v-if="!hidePreco && page2 !== 'compras' && page !== 'quebrastock'" class="FundoHeaderPesquisar hidden-xs hidden-sm"></th>
              <th class="FundoHeaderPesquisar hidden-xs hidden-sm text-right width-100" v-if="!hidePreco">{{$t('preco')}} ({{MOEDA}})</th>
              <!--<th class="FundoHeaderPesquisar hidden-xs hidden-sm" v-if="!hidePreco && page2 !== 'compras' && page != 'quebrastock'"></th>-->
              <th class="text-right FundoHeaderPesquisar hidden-xs hidden-sm" v-if="stock">{{$t('stock')}}</th>
            </tr>
          </thead>
          <tbody id="listaArtigos">
            <tr :class="{'selArtigo': !index}" v-for="(dt, index) in artigos" :key="index" @click="selArtigoFN(dt)" @mouseenter="mouseEnterFN(index)" :style="dt.cor ? 'background-color:#' + dt.cor : ''">
              <td class="visible-xs visible-sm mao">
                <span v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true" class="pull-left" v-html="dt.codigo.toString().replace(RegExp(item1, 'gi'), '<b>$&</b>')"></span><br />
                <h4 v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true"><span v-if="dt.movimenta_stock && stock"><span :class="{'text-success': dt.stock > 0, 'text-danger': dt.stock < 0}">{{dt.stock ? dt.stock : 0}}</span> x </span><span v-html="dt.descricao.toString().replace(RegExp(item1, 'gi'), '<b>$&</b>')"></span></h4>
                <div class="clearfix"></div>
                <span class="pull-right" v-if="dt.preco_sem_iva > 0">
                  <span class="text-cinza" v-if="!hidePreco && page2 != 'compras' && page != 'quebrastock'">{{dt.nome}} </span>
                  <b class="semquebras" v-if="PrecoIVA">{{MOEDA}} {{dt.preco_com_iva | valor2($store.getters.CASASDECIMAIS)}}</b>
                  <b class="semquebras" v-else>{{MOEDA}} {{dt.preco_sem_iva | valor2($store.getters.CASASDECIMAIS)}}</b>
                </span>
              </td>

              <td class="text-left hidden-xs hidden-sm mao" v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true">
                <span v-html="dt.codigo.toString().replace(RegExp(item1, 'gi'), '<b>$&</b>')"></span>
              </td>
              <td class="text-left hidden-xs hidden-sm mao" v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true">
                <span v-html="dt.descricao.toString().replace(RegExp(item1, 'gi'), '<b>$&</b>')"></span>
              </td>
              <td v-else class="hidden-xs hidden-sm mao" :colspan="page2 !== 'compras' && page !== 'quebrastock' ? 3 : 2" style="border-top:none"></td>
              <td class="text-right hidden-xs hidden-sm" v-if="!hidePreco && page2 !== 'compras' && page !== 'quebrastock' && (index ? artigos[index].codigo !== artigos[index - 1].codigo : true)">
                <div v-if="dt.custo > 0">
                  <b v-if="PrecoIVA">{{dt.custo_iva | valor2($store.getters.CASASDECIMAIS)}}</b>
                  <b v-else>{{dt.custo | valor2($store.getters.CASASDECIMAIS)}}</b>
                </div>
              </td>
              <td v-if="!hidePreco && page2 !== 'compras' && page !== 'quebrastock'" class="text-right hidden-xs hidden-sm mao">{{dt.nome}}</td>
              <td class="text-right hidden-xs hidden-sm mao" v-if="!hidePreco">
                <div v-if="dt.preco_sem_iva > 0">
                  <b v-if="PrecoIVA">{{dt.preco_com_iva | valor2($store.getters.CASASDECIMAIS)}}</b>
                  <b v-else>{{dt.preco_sem_iva | valor2($store.getters.CASASDECIMAIS)}}</b>
                </div>
              </td>
              <!--<td :style="dt.border ? 'border-top:none' : ''" class="text-left hidden-xs hidden-sm" v-if="!hidePreco && page2 !== 'compras' && page !== 'quebrastock'"><span v-if="dt.preco_sem_iva > 0">{{dt.nome}}</span></td>-->
              <td :style="dt.border ? 'border-top:none' : ''" :class="{'text-success': dt.stock > 0, 'text-danger': dt.stock < 0, 'text-right hidden-xs hidden-sm mao': 1}" v-if="stock"><span v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true">{{dt.stock}}</span> <span v-if="index ? artigos[index].codigo !== artigos[index - 1].codigo : true">{{$store.state['unidades-medida-id'][dt.unidade_medida_id]}}</span></td>
            </tr>
          </tbody>
        </table>
        <button v-if="hideCriarArtigo" id="criarArtigo" class="btn btn-primary btn-block" @click="$emit('addArtigo', item1); item1 = ''; artigos = [];">{{$t('criar_artigo')}} <span v-if="item1.length" class="text-warning">({{item1}})</span></button>
      </div>
      <div class="clearfix"></div>
      <paginacao1-paginacao1 v-if="artigos[0]" :pag="pag" :lastpage="lastpage" @formUrl="formUrlArtigos"></paginacao1-paginacao1>
    </div>
  </div>
  </template>

<script>
import { ordernar, round10, Principal, gethora, getData } from '../../filters/index'
import { itens, stock } from '../../resources/fn'
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      pesq: {
        pesquisa: {
          texto: {},
          order_by: {},
          pesquisa_avancada: {}
        }
      },
      artigos: [],
      pag: 1,
      lastpage: 1,
      indexPesq: 0,
      item1: '',
      artigosNRepeat: 0,
      pesqArtigos: '',
      codigoBarras: 0,
      buscouArtigos: 0,
      enter: 0
    }
  },
  watch: {
    'item1' (a) {
      this.$emit('update:item', a)
    },
    'item' (a) {
      this.item1 = a
    },
    '$route' () {
      this.artigos = []
    },
    'processBarcode' (val) {
      if (val) {
        this.pesq.pesquisa.order_by = {}
        this.formUrlArtigos(1, null, val)
      }
    }
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1',
      page2: 'page2'
    }),
    MOEDA: function () {
      return this.$store.getters.MOEDA
    },
    impostos: function () {
      return this.$store.state.impostos
    },
    casasDecimais: function () {
      return this.$store.getters.CASASDECIMAIS * -1
    }
  },
  props: {
    hideSearch: { default: true, type: Boolean },
    hideCriarArtigo: { type: Boolean },
    hidePreco: { default: true, type: Boolean },
    PrecoIVA: { type: Boolean },
    stock: { type: Boolean },
    documentID: { type: String },
    show: { default: true, type: Boolean },
    data1: { type: String },
    listar_stock_movimenta: { default: true, type: Boolean },
    selArtigos: { type: Array },
    form: { type: Object },
    produtosCompostos: { default: true, type: Boolean },
    showTime1: { default: true, type: Boolean },
    time1: { type: String },
    muitos: { type: Boolean },
    obrigatorio: { type: Boolean },
    processBarcode: { type: String }
  },
  mounted () {
    this.pesqArtigos = this._.debounce(function () {
      this.pesq.pesquisa.order_by = {}
      this.formUrlArtigos()
    }, 550)
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_HELP',
      'SET_SCROLL'
    ]),
    round10 (a) {
      return round10(a, this.casasDecimais)
    },
    l (a) {
      return this.$t('documentos.' + a)
    },
    formUrlArtigos (pag, order, itemCodbarras) {
      this.buscouArtigos = 0
      try {
        if (!this.pesq.pesquisa.order_by) {
          this.pesq.pesquisa.order_by = {}
        }
        switch (order) {
          case 'codigo':
            this.pesq.pesquisa.order_by = { codigo: ordernar(this.pesq.pesquisa.order_by.codigo) }
            break
          case 'descricao':
            this.pesq.pesquisa.order_by = { descricao: ordernar(this.pesq.pesquisa.order_by.descricao) }
            break
        }
        if (this.item1.length > 2 || itemCodbarras) {
          this.SET_LOAD(true)
          let pesq = { pesquisa: { texto: { descricao: this.item1, codigo: itemCodbarras || this.item1, codigo_barras: itemCodbarras || this.item1 }, visivel: true, order_by: this.pesq.pesquisa.order_by } }
          if (this.page === 'stocks') {
            pesq.pesquisa.servico = false
            pesq.pesquisa.produto = true
            pesq.pesquisa.movimenta_stock = true
          }
          if (this.page === 'relatorios' && this.listar_stock_movimenta) {
            pesq.pesquisa.movimenta_stock = this.listar_stock_movimenta
          }
          if (!this.produtosCompostos) {
            pesq.pesquisa.produto_composto = false
          }

          itens(this.axios, pesq, pag, this.$store.state.modulo).then((res) => {
            this.codigoBarras = 0
            let u = this.selArtigos.length
            let i
            while (--u >= 0) {
              i = res.data.data.length
              while (--i >= 0) {
                if (this.selArtigos[u].id === res.data.data[i].id) {
                  res.data.data.splice(i, 1)
                  break
                }
              }
            }
            this.artigos = this.auxArtigos(res.data.data)
            // buscar stocks
            // if (this.stock) {
            if (this.artigos[0]) {
              this.artigos[0].search = this.item1
            }
            if ((this.$store.state.utilizador.plano.plano.valor_mensal > 10 || this.$store.state.utilizador.plano.plano.valor_anual > 100) && this.stock && this.page !== 'quebrastock') {
              i = res.data.data.length
              let ar = []
              if (i) {
                while (--i >= 0) {
                  if ((res.data.data[i].movimenta_stock && !res.data.data[i].servico) || res.data.data[i].produto_composto) {
                    res.data.data[i].composicao.map(a => {
                      if (a.item.movimenta_stock && !a.item.servico) {
                        ar.push(a.item_id)
                      }
                    })
                    ar.push(res.data.data[i].id)
                  }
                  res.data.data[i].stock = ';;'
                }
                if (ar[0]) {
                  let dd
                  if (this.data1) {
                    dd = new Date(this.data1)
                    dd = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
                  } else {
                    dd = getData()
                  }
                  let dd1
                  if (this.time1) {
                    dd1 = this.time1
                  } else {
                    dd1 = gethora(0, true)
                  }
                  stock(this.axios, { itens: ar, lojas: [this.$store.state.loja], data_stock: dd + ' ' + dd1 }, 1, this.$store.state.modulo).then((res) => {
                    this.artigos.find(a => {
                      if ((a.movimenta_stock && !a.servico) || a.produto_composto) {
                        if (a.produto_composto) {
                          let quantidade
                          a.composicao.map(b => {
                            if (b.item.movimenta_stock && !b.item.servico) {
                              let x = res.data.data.find(c => c.item_id === b.item_id)
                              if (x) {
                                quantidade = quantidade ? Math.min(x.stock / b.quantidade, quantidade) : x.stock / b.quantidade
                              } else {
                                quantidade = 0
                              }
                            }
                          })
                          a.stock = quantidade
                        } else {
                          let x = res.data.data.find(b => b.item_id === a.id)
                          if (x) {
                            a.stock = x.stock
                          } else {
                            a.stock = 0
                          }
                        }
                      }
                    })
                    if (this.codigoBarras && this.artigos[0] || itemCodbarras) {
                      if (this.artigos[0].precos === 1) {
                        this.$emit('selArtigo', this.artigos[0], 1)
                        this.item1 = ''
                        this.artigos = []
                      }
                    } else {
                      if (this.enter) {
                        this.pesqEnter()
                        return
                      }
                    }
                  }, () => {})
                } else {
                  if (this.codigoBarras && this.artigos[0] || itemCodbarras) {
                    if (this.artigos[0].precos === 1) {
                      this.$emit('selArtigo', this.artigos[0], 1, this.artigos[0].variavel)
                      this.item1 = ''
                      this.artigos = []
                    }
                  } else {
                    if (this.enter) {
                      this.pesqEnter()
                      return
                    }
                  }
                }
              }
            } else {
              if (this.codigoBarras && this.artigos[0] || itemCodbarras) {
                if (this.artigos[0].precos === 1) {
                  this.$emit('selArtigo', this.artigos[0], 1, this.artigos[0].variavel)
                  this.item1 = ''
                  this.artigos = []
                }
              } else {
                if (this.enter) {
                  this.pesqEnter()
                  return
                }
              }
            }
            // fim de buscar stock
            this.indexPesq = 0
            this.pag = res.data.current_page
            this.lastpage = res.data.last_page
            this.buscouArtigos = 1
            this.SET_LOAD()
          }, () => {
            this.SET_LOAD()
          })
        } else {
          this.artigos = []
        }
      } catch (e) {
        console.log(e)
      }
    },
    mouseEnterFN (index) {
      let b = document.getElementById('listaArtigos').getElementsByTagName('tr')
      let i = b.length
      while (--i >= 0) {
        b[i].classList.remove('selArtigo')
      }
      this.indexPesq = index
      b[this.indexPesq].setAttribute('class', 'selArtigo')
      // this.delyFoco('itens')
    },
    pesqEnter () {
      let self = this
      if (self.item1.length > 2 || self.buscouArtigos) {
        self.buscouArtigos = 0
        if ((self.codigoBarras || !self.codigoBarras) && self.artigos[self.indexPesq].precos === 1) {
          self.$emit('selArtigo', self.artigos[self.indexPesq], self.item1)
          self.item1 = ''
          self.artigos = []
          /*
            if (document.getElementById('listaArtigos')) {
              if ((document.getElementById('listaArtigos').getElementsByTagName('tr').length - 1) === self.indexPesq && self.hideCriarArtigo) {
                self.$emit('addArtigo', self.item1)
                self.item1 = ''
                self.artigos = []
              } else {
                self.$emit('selArtigo', self.artigos[self.indexPesq], self.item1)
                self.item1 = ''
                self.artigos = []
              }
            }
          */
        }
      }
      /* else {
        if (self.form) {
          if (self.form.produtos.length) {
            if (self.documentID !== 'ND' && self.documentID !== 'NC' && self.documentID !== 'VNC' && self.documentID !== 'VND') {
              if (document.getElementById('descontoTotal')) {
                self.delyFoco('descontoTotal')
                return
              }
              if (document.getElementById('arredondamento')) {
                self.delyFoco('arredondamento')
                return
              }
              self.$emit('submit', 1)
            }
          } else {
            self.$swal('', self.l('sem_artigos'), 'error').then(function () {
              self.delyFoco('itens')
            })
          }
        }
      }
      */
    },
    pesqArtigosAntes (ev) {
      this.enter = 0
      let i = 0
      let b = ''
      switch (ev.which) {
        case 9:
        case 20:
        case 16:
        case 93:
        case 18:
        case 37:
        case 39:
          return
        case 13:
          this.enter = 1
          this.pesqArtigos()
          // this.pesqEnter()
          return
        case 27:
          this.artigos = []
          this.item1 = ''
          return
        case 38:
          if (!document.getElementById('listaArtigos')) {
            return
          }
          b = document.getElementById('listaArtigos').getElementsByTagName('tr')
          i = b.length
          while (--i >= 0) {
            b[i].classList.remove('selArtigo')
          }
          --this.indexPesq
          if (this.indexPesq > 0) {
            window.scrollTo(0, document.documentElement.scrollTop - 31)
            if (b[this.indexPesq]) {
              b[this.indexPesq].setAttribute('class', 'selArtigo')
            }
          } else {
            this.indexPesq = 0
            if (b[this.indexPesq]) {
              b[0].setAttribute('class', 'selArtigo')
            }
          }
          return
        case 40:
          if (!document.getElementById('listaArtigos')) {
            return
          }
          b = document.getElementById('listaArtigos').getElementsByTagName('tr')
          i = b.length
          while (--i >= 0) {
            b[i].classList.remove('selArtigo')
          }
          ++this.indexPesq
          if (this.artigos.length > this.indexPesq) {
            window.scrollTo(0, 31 + document.documentElement.scrollTop)
            if (b[this.indexPesq]) {
              b[this.indexPesq].setAttribute('class', 'selArtigo')
            }
          } else {
            this.indexPesq = this.artigos.length - 1
            if (b[this.indexPesq]) {
              b[this.indexPesq].setAttribute('class', 'selArtigo')
            }
          }
          return
      }
      this.pesqArtigos()
    },
    auxArtigos (xp) {
      this.artigosNRepeat = xp.length
      let i = 0
      let ii = xp.length
      let ar = []
      let u = 0
      let uu = 0
      let tit = 1
      let cor = ''
      let k = 0
      let preco = 0
      let precoIva = 0
      let qp = 0
      let d_ = 0
      let d2_ = 0
      let d3_ = 0
      while (i < ii) {
        // se o produto for comoposto calcular o valor total com iva e sem iva, e retirar os descontos
        if (xp[i].produto_composto) {
          preco = 0
          precoIva = 0
          if (this.documentID !== 'VGR' && this.documentID !== 'VGT' && this.page2 !== 'guias') {
            k = xp[i].composicao.length
            while (--k >= 0) {
              qp = xp[i].composicao[k].preco_unitario * xp[i].composicao[k].quantidade
              d_ = qp * xp[i].composicao[k].desconto_1 / 100
              d2_ = (qp - d_) * xp[i].composicao[k].desconto_2 / 100
              d3_ = (qp - d_ - d2_) * xp[i].composicao[k].desconto_3 / 100
              preco += qp - d_ - d2_ - d3_
              precoIva += (qp - d_ - d2_ - d3_) * (Principal(this.impostos, xp[i].imposto_id).valor / 100 + 1)
            }
          }
        }
        if (this.page2 === 'compras' || this.page === 'quebrastock' && window.location.search.indexOf('ESTK') || this.page2 === 'guias' && window.location.search.indexOf('GMD') !== -1 || this.page === 'relatorios' || this.page === 'stocks') {
          // se tiver preço de compra e quiser tirar uma guia com o vlor a 0
          if (this.documentID === 'VGR1' || this.documentID === 'VGT1') {
            ar.push({ nome: '', descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: 0, preco_com_iva: 0, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].iva_compra, id: xp[i].id, servico: xp[i].servico, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', produto_composto: xp[i].produto_composto, composicao: xp[i].composicao, stock: '', variavel: xp[i].variavel })
          } else {
            if (xp[i].produto_composto) {
              ar.push({ nome: xp[i].descricao, descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: preco, preco_com_iva: precoIva, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: 0, id: xp[i].id, servico: 0, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', produto_composto: 1, composicao: xp[i].composicao, stock: '' })
            } else {
              ar.push({ nome: xp[i].descricao, descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: xp[i].preco_custo, preco_com_iva: xp[i].preco_custo * (Principal(this.impostos, xp[i].iva_compra).valor / 100 + 1), unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].iva_compra, id: xp[i].id, servico: xp[i].servico, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', produto_composto: 0, composicao: [], stock: '', desconto_compra: xp[i].desconto_compra, preco_custo: xp[i].preco_custo, variavel: xp[i].variavel })
            }
          }
        } else {
          if (xp[i].produto_composto) {
            ar.push({ nome: '', descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: preco, preco_com_iva: precoIva, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: 0, id: xp[i].id, servico: 0, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', numeros_serie_nao_usados: [], produto_composto: 1, composicao: xp[i].composicao, stock: '' })
          } else {
            tit = 1
            uu = xp[i].precos.length
            u = 0
            // se tiver uma tabela de preços e quiser tirar uma guia com o valor a 0 euros
            if (this.page2 === 'guias1') {
              ar.push({ nome: '', descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: 0, preco_com_iva: 0, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].imposto_id, id: xp[i].id, servico: xp[i].servico, principal: tit, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', numeros_serie_nao_usados: xp[i].numeros_serie_nao_usados, produto_composto: xp[i].produto_composto, composicao: xp[i].composicao, stock: '', variavel: xp[i].variavel, custo: xp[i].preco_custo, custo_iva: xp[i].preco_custo * (Principal(this.impostos, xp[i].iva_compra).valor / 100 + 1), precos: uu })
            } else {
              cor = i % 2 ? 'fff' : 'fbfbfb'
              while (u < uu) {
                if (parseFloat(xp[i].precos[u].preco_com_iva) === 0 && xp[i].precos[u].preco_sem_iva > 0) {
                  xp[i].precos[u].preco_com_iva = xp[i].precos[u].preco_sem_iva * (Principal(this.impostos, xp[i].imposto_id).valor / 100 + 1)
                } 
                if (parseFloat(xp[i].precos[u].preco_sem_iva) > 0) {
                  ar.push({ nome: xp[i].precos[u].preco_nome, descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: xp[i].precos[u].preco_sem_iva, preco_com_iva: xp[i].precos[u].preco_com_iva, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].imposto_id, id: xp[i].id, servico: xp[i].servico, principal: tit, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', numeros_serie_nao_usados: xp[i].numeros_serie_nao_usados, cor: cor, border: (u ? 1 : 0), stock: '', variavel: xp[i].variavel, custo: xp[i].preco_custo, custo_iva: xp[i].preco_custo * (Principal(this.impostos, xp[i].iva_compra).valor / 100 + 1), precos: uu })
                  tit = 0
                }
                if (u === (uu - 1) && tit === 1) {
                  ar.push({ nome: xp[i].precos[u].preco_nome, descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: 0, preco_com_iva: 0, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].imposto_id, id: xp[i].id, servico: xp[i].servico, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', numeros_serie_nao_usados: xp[i].numeros_serie_nao_usados, cor: cor, stock: '', variavel: xp[i].variavel, custo: xp[i].preco_custo, custo_iva: xp[i].preco_custo * (Principal(this.impostos, xp[i].iva_compra).valor / 100 + 1), precos: uu })
                }
                ++u
              }
              if (uu === 0) {
                ar.push({ nome: xp[i].descricao, descricao: xp[i].descricao, codigo: xp[i].codigo, preco_sem_iva: 0, preco_com_iva: 0, unidade_medida_id: xp[i].unidade_medida_id, imposto_id: xp[i].imposto_id, id: xp[i].id, servico: xp[i].servico, principal: 1, movimenta_stock: xp[i].movimenta_stock, preco_unitario: '', numeros_serie_nao_usados: xp[i].numeros_serie_nao_usados, cor: cor, produto_composto: xp[i].produto_composto, composicao: xp[i].composicao, stock: '', variavel: xp[i].variavel, custo: xp[i].preco_custo, custo_iva: xp[i].preco_custo * (Principal(this.impostos, xp[i].iva_compra).valor / 100 + 1), precos: uu })
              }
            }
          }
        }
        if (xp[i].codigo_barras.split('##').find(a => a.toString() === this.item1.toString()) && this.item1.length >= 6) {
          this.codigoBarras = 1
          if (xp[i].precos.length <= 1) {
            return [ar.pop()]
          } else {
            this.enter = 0
          }
          /*
          if (this.page2 === 'guias' || this.page2 === 'compras' || this.page === 'quebrastock' || this.page === 'relatorios' || this.page === 'stocks' || ((xp[i].precos.length <= 1 || xp[i].variavel) && this.page === 'documentos') || this.page !== 'documentos') {
            this.codigoBarras = 1
            return [ar.pop()]
          } else {
            this.codigoBarras = 2
          }
          */
        }
        ++i
      }
      return ar
    },
    selArtigoFN (dt) {
      this.$emit('selArtigo', dt, 0, this.item1)
      this.artigos = []
      this.item1 = ''
    },
    delyFoco (campo) {
      this._.delay(function (campo) {
        if (document.getElementById(campo)) {
          document.getElementById(campo).focus()
        }
      }, 100, campo)
    }
  }
}
</script>
