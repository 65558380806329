import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VTooltip from 'v-tooltip'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueSweetalert2 from 'vue-sweetalert2'
import lightbox from 'vlightbox'
import draggable from 'vuedraggable'
import DatePicker from 'vue2-datepicker'
import VueTouchKeyboard from 'vue-touch-keyboard'

import auth from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

import App from './components/App.vue'

import DoumentosCriar from './components/documentos/DoumentosCriar.vue'

// loja
import ContactsCriar from './components/loja/ContactsCriar.vue'
import ItensCriar from './components/loja/ItensCriar.vue'
import MetaDados from './components/loja/MetaDados.vue'

import Stocks from './components/loja/Stock.vue'

import ConfigUsersCriar from './components/configuracoes/ConfigUsersCriar.vue'

// acompanhamento de documentos
import comentarios from './components/discussao/comentarios.vue'

import { store } from './vuex/store'

import Treeselect from '@riophae/vue-treeselect'
import tabs from './components/tpl/tabs'
import paginacao from './components/tpl/paginacao' // com link
import paginacao1 from './components/tpl/paginacao_function' // com funcao
import msgPermissoes from './components/tpl/msgPermissoes'
import botForm from './components/tpl/botForm' // botao de formulario com enviar e cancelar

require('./directives/directives.js')
require('./filters/filters.js')

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(VueAxios, axios)
Vue.use(Router)
Vue.use(VTooltip)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueSweetalert2)
Vue.use(lightbox)
Vue.use(DatePicker)
Vue.use(VueTouchKeyboard)
Vue.component('TreeselectTreeselect', Treeselect)
Vue.component('tabsTabs', tabs)
Vue.component('paginacaoPaginacao', paginacao)
Vue.component('paginacao1Paginacao1', paginacao1)
Vue.component('msgPermissoes', msgPermissoes)
Vue.component('botForm', botForm)
Vue.component('draggableDraggable', draggable)

if (window.location.hostname.indexOf('localhost') !== -1) {
  Vue.axios.defaults.baseURL = 'https://dev.bill.pt/'
} else {
  Vue.axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/'
}

Vue.router = new Router({
  routes: [
    {
      path: '/documentos/meta',
      name: 'documentosMetaDados',
      component: () => import('./components/loja/MetaDados.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/documentos/importados/:id',
      name: 'docsImportados',
      component: () => import('./components/documentos/Importados.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/documentos/(faturas|orcamentos|guias|encomendas|compras|importados)?/:id',
      name: 'documentosListar',
      component: () => import('./components/documentos/DocumentsListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    /*
    {
      path: '/documentos/editar/despesas',
      redirect: '/documentos/despesas/1'
    },
    {
      path: '/documentos/despesas/:id',
      name: 'DespesasListar',
      component: () => import('./components/documentos/DespesasListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    */
    /*
    {
      path: '/documentos/editar/despesas/:id',
      name: 'DespesasEditar',
      component: DespesasCriar,
      meta: { auth: true, redirect: '/login' }
    },
    */
    {
      path: '/documentos/criar/(faturas|orcamentos|guias|encomendas|compras)?',
      name: 'documentosCriar',
      component: DoumentosCriar,
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: 'contactos', component: ContactsCriar },
        { path: 'itens', component: ItensCriar }
      ]
    },
    {
      path: '/documentos/editar/(faturas|orcamentos|guias|encomendas|compras)?/:id/',
      name: 'documentosEditar',
      component: DoumentosCriar,
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: 'contactos', component: ContactsCriar },
        { path: 'itens', component: ItensCriar }
      ]
    },
/*
    {
      path: '/documentos',
      redirect: '/documentos/faturas/1'
    },
    {
      path: '/documentos/faturas',
      redirect: '/documentos/faturas/1'
    },
    {
      path: '/documentos/orcamentos',
      redirect: '/documentos/orcamentos/1'
    },
    {
      path: '/documentos/guias',
      redirect: '/documentos/guias/1'
    },
    {
      path: '/documentos/encomendas',
      redirect: '/documentos/encomendas/1'
    },
    {
      path: '/documentos/compras',
      redirect: '/documentos/compras/1'
    },
    {
      path: '/documentos/criar',
      redirect: '/documentos/criar/faturas'
    },
    {
      path: '/documentos/despesas',
      redirect: '/documentos/despesas/1'
    },
    {
      path: '/documentos/agendamentos',
      redirect: '/documentos/agendamentos/1'
    },
    {
      path: '/documentos/agendamentos/ver',
      redirect: '/documentos/agendamentos/1'
    },
    {
      path: '/documentos/editar',
      redirect: '/documentos/faturas/1'
    },
    */

    //cloudbeds
    {
      path: '/cloudbeds/criar/:id/:id1/:id2',
      name: 'cloudbedsCriar',
      component: () => import('./components/cloudbeds/Criar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/cloudbeds/criar',
      redirect: '/cloudbeds/1'
    },

    {
      path: '/cloudbeds/editar',
      redirect: '/cloudbeds/1'
    },
    {
      path: '/cloudbeds/editar/:id',
      name: 'cloudbedsEditar',
      component: () => import('./components/cloudbeds/Criar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/cloudbeds/:id',
      name: 'cloudbedsListar',
      component: () => import('./components/cloudbeds/Listar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/cloudbeds',
      redirect: '/cloudbeds/1'
    },

    // PROJETOS
    {
      path: '/projetos/criar',
      name: 'ProjetosCriar',
      component: () => import('./components/projetos/Criar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/projetos/editar/:id',
      name: 'ProjetosEditar',
      component: () => import('./components/projetos/Criar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/projetos/:id',
      name: 'ProjetosListar',
      component: () => import('./components/projetos/Listar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/projetos',
      redirect: '/projetos/1'
    },

    {
      path: '/discussao/email/:id/n/:token',
      name: 'downloadDocument',
      component: () => import('./components/discussao/downloadDocument.vue')
    },
    {
      path: '/discussao/email/:id/:token',
      name: 'comentariosDiscussao',
      component: comentarios
    },
    {
      path: '/discussao/email/:id/:token/:discussao',
      name: 'comentariosDiscussao1',
      component: comentarios
    },

    {
      path: '/pos',
      name: 'pos',
      component: () => import('./components/POS.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/conta',
      name: 'conta',
      component: () => import('./components/planos/Conta.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/planos',
      name: 'planos',
      component: () => import('./components/planos/Planos.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/comprar',
      name: 'comprar',
      component: () => import('./components/planos/Comprar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/historico',
      redirect: '/historico/1'
    },
    {
      path: '/historico/:id',
      name: 'historico',
      component: () => import('./components/planos/historico.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/documentos/agendamentos/ver/:id',
      name: 'AgendamentoVer',
      component: () => import('./components/documentos/AgendamentoVer.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/documentos/agendamentos/:id',
      name: 'Agendamentos',
      component: () => import('./components/documentos/Agendamentos.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    /*
    {
      path: '/documentos/criar/despesas',
      name: 'DespesasCriar',
      component: DespesasCriar,
      meta: { auth: true, redirect: '/login' }
    },
    */
    {
      path: '/triplicados',
      name: 'Triplicados',
      component: () => import('./components/documentos/Triplicados.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios',
      name: 'relatorios',
      component: () => import('./components/relatorios/relatorios.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios/stock',
      name: 'relatoriosStock',
      component: () => import('./components/relatorios/movimentosStock.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios/mais-vendidos-mes',
      name: 'maisVendidosMes',
      component: () => import('./components/relatorios/maisVendidosMes.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios/total-compras-vendas-por-produto',
      name: 'totalComprasVendasPorProduto',
      component: () => import('./components/relatorios/totalComprasVendasPorProduto.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios/conta-vencidos',
      name: 'contaVencidos',
      component: () => import('./components/relatorios/contaVencidos.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/conta-em-aberto',
      name: 'contaEmAberto',
      component: () => import('./components/relatorios/contaEmAberto.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/conta-corrente',
      name: 'contaCorrente',
      component: () => import('./components/relatorios/contaCorrente.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/movimentos-produto',
      name: 'movimentosArtigos',
      component: () => import('./components/relatorios/movimentosArtigos.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' },
        { path: ':id/:op' }
      ]
    },
    {
      path: '/relatorios/totais-por-mes',
      name: 'totaisMes',
      component: () => import('./components/relatorios/totaisMes.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/total-faturado',
      name: 'totalFaturado',
      component: () => import('./components/relatorios/totalFaturado.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/relatorios/extracto-documentos',
      name: 'extractoDocumentos',
      component: () => import('./components/relatorios/ExtractoDocumentos.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/extracto-pagamentos',
      name: 'extractosPagamentos',
      component: () => import('./components/relatorios/extractosPagamentos.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/regime-margem-lucro',
      name: 'margemLucro',
      component: () => import('./components/relatorios/margemLucro.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: ':id' }
      ]
    },
    {
      path: '/relatorios/mapa-impostos',
      name: 'mapaImpostos',
      component: () => import('./components/relatorios/mapaImpostos.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    // cliente alequine validade
    {
      path: '/relatorios/validades',
      name: 'validades',
      component: () => import('./components/relatorios/validades.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/contactos/meta',
      name: 'contactosMetaDados',
      component: MetaDados,
      meta: { auth: true, redirect: '/login' }
    },
    /*
    {
      path: '/contactos',
      redirect: '/contactos/1'
    },
    */
    {
      path: '/contactos/criar',
      name: 'contactosCriar',
      component: ContactsCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos/editar/:id',
      name: 'contactosEditar',
      component: ContactsCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos/:id',
      name: 'contactosListar',
      component: () => import('./components/loja/ContactsListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/contactos',
      redirect: '/contactos/1'
    },

    {
      path: '/itens/meta',
      name: 'itensMetaDados',
      component: MetaDados,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/itens/impostos',
      name: 'itensImpostos',
      component: () => import('./components/loja/updateIvas.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/itens/criar',
      name: 'itensCriar',
      component: ItensCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/itens/editar/:id',
      name: 'itensEditar',
      component: ItensCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/itens/:id',
      name: 'itensListar',
      component: () => import('./components/loja/ItensListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/itens',
      redirect: '/itens/1'
    },


    {
      path: '/stocks',
      redirect: '/stocks/1'
    },
    {
      path: '/stocks/(falta|excesso)?',
      name: 'StocksFaltaExcesso',
      component: () => import('./components/loja/StockEstado.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/stocks/:id',
      name: 'stocks',
      component: Stocks,
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/quebrastock',
      redirect: '/quebrastock/1'
    },
    {
      path: '/quebrastock/criar',
      name: 'QuebraStocksCriar',
      component: DoumentosCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/quebrastock/editar',
      redirect: '/quebrastock/criar'
    },
    {
      path: '/quebrastock/editar/:id',
      name: 'QuebraStocksEditar',
      component: DoumentosCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/quebrastock/:id',
      name: 'QuebraStocksListar',
      component: () => import('./components/loja/QuebraStocksListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('./components/auth/Login.vue'),
      meta: { auth: false, redirect: '/' }
    },

    {
      path: '/condicoes',
      name: 'condicoes',
      component: () => import('./components/auth/Condicoes.vue'),
      meta: { auth: false, redirect: '/' }
    },
    {
      path: '/registar',
      name: 'registar',
      component: () => import('./components/auth/Register.vue'),
      meta: { auth: false, redirect: '/' }
    },
    {
      path: '/registar-tour',
      name: 'registarTour',
      component: () => import('./components/auth/tourRegister.vue'),
      meta: { auth: true, redirect: '/' }
    },
    {
      path: '/esqueceu-password',
      name: 'esqueceuPassword',
      component: () => import('./components/auth/ForgotPassword.vue'),
      meta: { auth: false, redirect: '/' }
    },
    {
      path: '/atualizar-senha/:token',
      name: 'atualizarPassword',
      component: () => import('./components/auth/UpdatePassword.vue')
    },
    {
      path: '/ativar-conta/:id1/:token1',
      name: 'ativarConta',
      component: () => import('./components/auth/ActiveAccount.vue')
    },
    {
      path: '/configuracoes',
      redirect: '/configuracoes/empresa'
    },
    {
      path: '/configuracoes/empresa',
      name: 'configEmpresa',
      component: () => import('./components/configuracoes/ConfigEmpresa.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/at',
      redirect: '/configuracoes/at/1'
    },
    {
      path: '/configuracoes/at/:id',
      name: 'configAt',
      component: () => import('./components/configuracoes/ConfigAT.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/impressao',
      name: 'ConfigImpressao',
      component: () => import('./components/configuracoes/ConfigImpressao.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      // impostos
      path: '/configuracoes/(series|estados|viaturas|tipos|lojas|categorias|pagamento|expedicao|unidades)',
      name: 'configuracoes',
      component: () => import('./components/configuracoes/ConfigForm.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/configuracoes/modulos',
      name: 'modulos',
      component: () => import('./components/configuracoes/modulos.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/configuracoes/pos',
      name: 'configPOS',
      component: () => import('./components/configuracoes/ConfigPOS.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/pos/criar',
      name: 'configPOSCriar',
      component: () => import('./components/configuracoes/ConfigPosCriar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/pos/editar',
      redirect: '/configuracoes/pos'
    },
    {
      path: '/configuracoes/pos/editar/:id',
      name: 'ConfigPOSEditar',
      component: () => import('./components/configuracoes/ConfigPosCriar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/utilizadores',
      name: 'configUsersListar',
      component: () => import('./components/configuracoes/ConfigUsersListar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/utilizadores/criar',
      name: 'configUsersCriar',
      component: ConfigUsersCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/utilizadores/editar/:id',
      name: 'configUsersEditar',
      component: ConfigUsersCriar,
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/utilizadores/editar',
      redirect: '/configuracoes/utilizadores'
    },
    {
      path: '/configuracoes/importar',
      redirect: '/configuracoes/importar/contactos/1'
    },
    {
      path: '/configuracoes/importar/contactos',
      redirect: '/configuracoes/importar/contactos/1'
    },
    {
      path: '/configuracoes/importar/itens',
      redirect: '/configuracoes/importar/itens/1'
    },
    {
      path: '/configuracoes/importar',
      name: 'configImportar',
      component: () => import('./components/configuracoes/ConfigImportar.vue'),
      meta: { auth: true, redirect: '/login' },
      children: [
        { path: 'contactos/:id', component: () => import('./components/configuracoes/ConfigImportarContactos.vue'), name: 'configImportarContactos' },
        { path: 'itens/:id', component: () => import('./components/configuracoes/ConfigImportarItens.vue'), name: 'configImportarItens' },
        { path: 'documentos', component: () => import('./components/configuracoes/ConfigImportarDocumentos.vue'), name: 'ConfigImportarDocumentos' }
      ]
    },
    {
      path: '/configuracoes/exportar',
      name: 'exportarSaft',
      component: () => import('./components/configuracoes/ConfigExportar.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/configuracoes/emails',
      name: 'emails',
      component: () => import('./components/configuracoes/ConfigEmails.vue'),
      meta: { auth: true, redirect: '/login' }
    },

    {
      path: '/',
      name: 'home',
      component: () => import('./components/Home.vue'),
      meta: { auth: true, redirect: '/login' }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: () => import('./components/NotFound.vue')
    },
    {
      path: '/lognow',
      name: 'lognow',
      component: () => import('./components/LogNow.vue')
    },
    {
      path: '*',
      redirect: '/notfound'
    }
  ],
  mode: 'history',
  hashbang: false,
  base: __dirname,
  transitionOnLoad: true,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: 'type',
    refreshData: { url: './auth/refresh', method: 'GET', enabled: false, interval: 3600000, fetchUser: false, makeRequest: true },
    fetchData: { enabled: false },
    authRedirect: { path: './login' }
  }
})

const router = Vue.router
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
