export function subCategorias (pai, dta) {
  let i = dta.length
  let ar = []
  while (--i >= 0) {
    if (pai === dta[i].categoria_pai_id) {
      ar.push(dta[i])
      ar = ar.concat(subCategorias(dta[i].id, dta))
    }
  }
  return ar
}
export function subCategoriasX (dta, pai) {
  let u = dta.length
  let i = 0
  let ar = []
  let x
  while (i < u) {
    if (pai === dta[i].categoria_pai_id && dta[i].visivel) {
      ar.push({ id: dta[i].id, label: dta[i].nome.toString() })
      x = subCategoriasX(dta.slice(1), dta[i].id)
      if (x.length) {
        ar[ar.length - 1].children = x
      }
    }
    ++i
  }
  return ar
}
export function fnMobile () {
  let agent = window.navigator.userAgent
  if ((agent.match(/Mobile/i) || agent.match(/iPhone/i) || agent.match(/iPod/i) || agent.match(/IEMobile/i) || agent.match(/Windows Phone/i) || agent.match(/Android/i) || agent.match(/BlackBerry/i) || agent.match(/webOS/i) || agent.match(/Tablet/i) || agent.match(/iPad/i) || agent.match(/Nexus 7/i) || agent.match(/Nexus 10/i) || agent.match(/KFAPWI/i))) {
    return true
  } else {
    return false
  }
}

export function moveScroll () {
  if (window.document.getElementById('tbodyFixa')) {
    window.scrollTo(0, window.document.getElementById('divTable').getBoundingClientRect().top + (document.body.clientWidth >= 850 ? -50 : 0))
  } else {
    window.setTimeout(function () {
      moveScroll()
    }, 150)
  }
}
