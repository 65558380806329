<template>
  <div class="modal-mask" v-if="$store.state.modalSuporte && $store.state.utilizador1" transition="modal" id="suporteAjudaModal">
    <div class="modalScrollMobile">
      <div class="modal-wrapper">
        <div class="modal-fundo"></div>
        <div class="modal-container padding-0 modal-800">
          <div class="modal-header">
            <h3 class="modal-title">{{l('titulo')}}<button @click="SET_SUPORTE_MODAL('')" type="button" class="btn btn-default btn-pure btn-lg modal-fechar hidden-xs"><i class="glyphicon glyphicon-remove"></i></button></h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15">
            <form method="post" :action="'https://suporte.bill.pt/submit.php' + ($store.state.utilizador.empresa.afiliado === 'soos' ? '?afiliado=soos' : '')" target="_blank" id="formSuporte">
              <div class="form-group">
                <select class="form-control foco" name="assunto" id="assuntorfsfds" required @keydown.enter.prevent.stop="FOCO" v-model="assunto">
                  <option value="" disabled>{{l('assunto')}}</option>
                  <option value="#ErroFicheiroSAFT Erro no Ficheiro SAF-T PT">{{l('assunto1')}}</option>
                  <option value="#SuporteTecnico  Suporte Tecnico - (Erros Website e API)">{{l('assunto2')}}</option>
                  <option value="#DepartamentoFinanceiro  Departamento Financeiro - (Renovacoes e Faturas )">{{l('assunto3')}}</option>
                  <option value="#DuvidaUtilizacao Duvida de Utilizacao">{{l('assunto4')}}</option>
                  <option value="#AjudaFuturoCliente Ajuda para se tornar Cliente Bill">{{l('assunto5')}}</option>
                  <option value="#sem_etiqueta Outro Assunto">{{l('assunto6')}}</option>
                </select>
              </div>
              <div class="form-group">
                <textarea rows="7" autocomplete="off" required class="form-control foco" v-model="suporte" maxlength="800" id="suporte" name="mensagem" :placeholder="l('desc')" v-texto></textarea>
                <input type="hidden" name="cliente" value="Sim" />
                <input type="hidden" name="submit" value="Submit" />
                <input type="hidden" name="nome" :value="$store.state.utilizador.nome" />
                <input type="hidden" name="email" :value="$store.state.utilizador.email" />
              </div>
              <div class="clearfix"></div>
              <p v-if="$store.state.utilizador.empresa.afiliado === 'soos'" class="ajuda margin-top-5 text-justify">Pode enviar as suas dúvidas também para bill@soos.info.</p>
              <p v-else class="ajuda margin-top-5 text-justify" v-html="l('suporte')"></p>
              <div class="clearfix"></div>
              <button class="btn btn-default btn-pure pull-left" type="button" @click="SET_SUPORTE_MODAL()">{{$t('cancelar')}}</button>
              <button class="btn btn-primary pull-right" type="submit" @click="enviarSuporte">{{$t('confirmar')}}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'modalSuporte',
  data () {
    return {
      assunto: '',
      suporte: ''
    }
  },
  watch: {
    '$store.state.modalSuporte' () {
      if (this.$store.state.modalSuporte) {
        this.suporte = ''
        this.assunto = ''
        this.delyFoco('assuntorfsfds')
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_SUPORTE_MODAL',
      'SET_TOAST'
    ]),
    l (a) {
      return this.$t('suporteModal.' + a)
    },
    enviarSuporte () {
      if (this.assunto && this.suporte) {
        this.SET_TOAST({ msg: this.$t('suporte_sucesso') })
        this._.delay(function (a) {
          a.SET_SUPORTE_MODAL()
        }, 300, this)
      } else {
        this.$swal(this.$t('suporte'), this.$t('suporte_erro'), 'info')
      }
    },
    delyFoco (campo) {
      this._.delay(function (campo) {
        if (document.getElementById(campo)) {
          document.getElementById(campo).focus()
        }
      }, 100, campo)
    }
  }
}
</script>
