<template>
  <div class="pr" v-if="$store.state.utilizador1 && $store.state.lojas1">
    <div class="panel margin-bottom-15">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('dados_utilizador')}}</h3>
      </div>
      <div class="panel-body pr padding-bottom-15 padding-0">
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('nome')}}<span class="verm">*</span>
            </span>
            <input ref="nome" autocomplete="off" type="text" class="form-control foco" v-model="form.nome" required maxlength="200" id="nome" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('email')}}<span class="verm">*</span>
            </span>
            <input autocomplete="off" type="email" class="form-control foco" v-model="form.email" required maxlength="200" :disabled="form.id" id="email" @keydown.enter.prevent="FOCO" v-mail>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('telefone')}}
            </span>
            <input autocomplete="off" type="text" class="form-control foco" v-model="form.telefone" maxlength="20" id="telefone" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.id === $store.state.utilizador.id">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('senha')}}
            </span>
            <a @click="utilizadorSenha" class="btn btn-primary btn-sm" style="margin-left: 5%">{{l('alterar_senha')}}</a>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-12">
          <div>
              <input class="foco" type="checkbox" v-model="form.newsletter" id="newsletter" @keydown.enter.prevent="FOCO">
              <label for="newsletter">{{l('newsletter')}}</label>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <div class="panel margin-bottom-15">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('permissoes')}}</h3>
      </div>
      <div class="panel-body pr padding-0">

        <div class="col-md-12 form-group form-material" id="lojas" v-if="$store.state.lojastotal > 1 && (!form.principal || !form.id) && form.id != $store.state.utilizador.id">
          <div class="input-group pr foco" id="lojas1" name="lojas1" @keydown.enter.prevent="FOCO">
            <span class="input-group-addon">
              {{l('titulolojas')}}
            </span>

            <!--<multiselect v-model="lojas_" :options="lojas" :multiple="true" label="nome" track-by="id" placeholder="" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="true" :searchable="true" :allow-empty="true" :hide-selected="true"></multiselect>-->
            <treeselect-treeselect :clearable="true" :searchable="true" :multiple="true" :options="lojas" placeholder="" v-model="form.lojas" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" no-results-text="" no-options-text="" :default-expand-level="1" />
          </div>
          <p class="ajuda margin-top-5 text-justify margin-horizontal-10" v-html="l('ajuda_lojas')"></p>
        </div>
        <div class="clearfix"></div>

        <div class="col-md-12">
          <h4 class="padding-top-10 margin-top-15">{{$t('api')}}</h4>
          <div v-if="PERMISSOES.configuracoes_empresa_gerir_utilizadores">
            <input class="foco" type="checkbox" id="api" @change="form.permissoes.api ? SET_SCROLLBOTTOM(true) : ''" v-model="form.permissoes.api" @keydown.enter.prevent="FOCO">
            <label for="api">{{l('activar_API')}}</label>
          </div>
          <div class="clearfix"></div>
          <div class="form-group form-material" v-if="apiToken && form.id == $store.state.utilizador.id">
            <div class="input-group">
              <span class="input-group-addon">
                {{$t('token')}}
              </span>
              <textarea style="height:50px;" autocomplete="off" class="form-control foco" readonly="true" :value="apiToken" id="token"></textarea>
              <a class="btn btn-sm btn-icon btn-pure btn-default input-group-addon hidden-xs" @click="copiar()" v-tooltip.top-start="l('copiar_token')"><i class="glyphicon glyphicon-copyright-mark"></i></a>
              <a class="btn btn-sm btn-icon btn-pure btn-default input-group-addon hidden-xs" @click="renovarApi()" v-tooltip.top-start="l('renovar_token')" v-if="form.permissoes.api && PERMISSOES.configuracoes_empresa_gerir_utilizadores"><i class="glyphicon glyphicon-repeat"></i></a>
            </div>
            <div class="clearfix"></div>
            <a class="btn btn-sm btn-pure btn-default padding-top-0 visible-xs pull-left" @click="renovarApi()" v-if="form.permissoes.api && PERMISSOES.configuracoes_empresa_gerir_utilizadores"><i class="glyphicon glyphicon-repeat"></i> {{l('renovar')}}</a>
            <a class="btn btn-sm btn-pure btn-default padding-top-0 visible-xs pull-right" @click="copiar()" v-if="form.permissoes.api && form.id == $store.state.utilizador.id && PERMISSOES.configuracoes_empresa_gerir_utilizadores"><i class="glyphicon glyphicon-copyright-mark"></i> {{l('copiar')}}</a>
          </div>
          <p class="ajuda margin-top-5 text-justify margin-horizontal-10" v-html="l('ajuda_api')"></p>
        </div>

        <div v-if="(!form.principal || !form.id) && form.id != $store.state.utilizador.id">
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_gerais')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="armazens_gerir" v-model="form.permissoes.armazens_gerir" @keydown.enter.prevent="FOCO">
              <label for="armazens_gerir">{{l('lojas_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="series_gerir" v-model="form.permissoes.series_gerir" @keydown.enter.prevent="FOCO">
              <label for="series_gerir">{{l('series_gerir')}}</label>
            </div><div class="clearfix"></div>
            <!--<div>
              <input class="foco" type="checkbox" id="impostos_gerir" v-model="form.permissoes.impostos_gerir" @keydown.enter.prevent="FOCO">
              <label for="impostos_gerir">{{l('impostos_gerir')}}</label>
            </div><div class="clearfix"></div>-->
            <div>
              <input class="foco" type="checkbox" id="composicao_gerir" v-model="form.permissoes.composicao_gerir" @keydown.enter.prevent="FOCO">
              <label for="composicao_gerir">{{l('composicao_gerir')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_contatos')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="contatos_apenas_proprios" v-model="form.permissoes.contatos_apenas_proprios" @keydown.enter.prevent="FOCO">
              <label for="contatos_apenas_proprios">{{l('contatos_apenas_proprios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="contatos_importar_exportar" v-model="form.permissoes.contatos_importar_exportar" @keydown.enter.prevent="FOCO">
              <label for="contatos_importar_exportar">{{l('contatos_importar_exportar')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="contatos_gerir" v-model="form.permissoes.contatos_gerir" @keydown.enter.prevent="FOCO">
              <label for="contatos_gerir">{{l('contatos_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="contatos_gerir_tipos" v-model="form.permissoes.contatos_gerir_tipos" @keydown.enter.prevent="FOCO">
              <label for="contatos_gerir_tipos">{{l('contatos_gerir_tipos')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_artigos')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="itens_apenas_proprios" v-model="form.permissoes.itens_apenas_proprios" @keydown.enter.prevent="FOCO">
              <label for="itens_apenas_proprios">{{l('itens_apenas_proprios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="itens_importar_exportar" v-model="form.permissoes.itens_importar_exportar" @keydown.enter.prevent="FOCO">
              <label for="itens_importar_exportar">{{l('itens_importar_exportar')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="itens_gerir" v-model="form.permissoes.itens_gerir" @keydown.enter.prevent="FOCO">
              <label for="itens_gerir">{{l('itens_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="itens_ver_preco_custo" v-model="form.permissoes.itens_ver_preco_custo" @keydown.enter.prevent="FOCO">
              <label for="itens_ver_preco_custo">{{l('itens_ver_preco_custo')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="itens_gerir_categorias" v-model="form.permissoes.itens_gerir_categorias" @keydown.enter.prevent="FOCO">
              <label for="itens_gerir_categorias">{{l('itens_gerir_categorias')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_vendas')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="vendas" v-model="form.permissoes.vendas" @keydown.enter.prevent="FOCO">
              <label for="vendas">{{l('vendas')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_apenas_documentos_proprios" v-model="form.permissoes.vendas_apenas_documentos_proprios" @keydown.enter.prevent="FOCO">
              <label for="vendas_apenas_documentos_proprios">{{l('vendas_apenas_documentos_proprios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_pode_alterar_precos" v-model="form.permissoes.vendas_pode_alterar_precos" @keydown.enter.prevent="FOCO">
              <label for="vendas_pode_alterar_precos">{{l('vendas_pode_alterar_precos')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_pode_fazer_descontos" v-model="form.permissoes.vendas_pode_fazer_descontos" @keydown.enter.prevent="FOCO">
              <label for="vendas_pode_fazer_descontos">{{l('vendas_pode_fazer_descontos')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_converter_documentos" v-model="form.permissoes.vendas_converter_documentos" @keydown.enter.prevent="FOCO">
              <label for="vendas_converter_documentos">{{l('vendas_converter_documentos')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_gerir" v-model="form.permissoes.vendas_gerir" @keydown.enter.prevent="FOCO">
              <label for="vendas_gerir">{{l('vendas_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="vendas_anular" v-model="form.permissoes.vendas_anular" @keydown.enter.prevent="FOCO">
              <label for="vendas_anular">{{l('vendas_anular')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_compras')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="compras" v-model="form.permissoes.compras" @keydown.enter.prevent="FOCO">
              <label for="compras">{{l('compras')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="compras_apenas_documentos_proprios" v-model="form.permissoes.compras_apenas_documentos_proprios" @keydown.enter.prevent="FOCO">
              <label for="compras_apenas_documentos_proprios">{{l('compras_apenas_documentos_proprios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="compras_converter_documentos" v-model="form.permissoes.compras_converter_documentos" @keydown.enter.prevent="FOCO">
              <label for="compras_converter_documentos">{{l('compras_converter_documentos')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="compras_gerir" v-model="form.permissoes.compras_gerir" @keydown.enter.prevent="FOCO">
              <label for="compras_gerir">{{l('compras_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="compras_anular" v-model="form.permissoes.compras_anular" @keydown.enter.prevent="FOCO">
              <label for="compras_anular">{{l('compras_anular')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_recibos')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="recibos_gerir" v-model="form.permissoes.recibos_gerir" @keydown.enter.prevent="FOCO">
              <label for="recibos_gerir">{{l('recibos_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="pagamentos_gerir" v-model="form.permissoes.pagamentos_gerir" @keydown.enter.prevent="FOCO">
              <label for="pagamentos_gerir">{{l('pagamentos_gerir')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_AT')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="configuracoes_empresa_editar_dados_at" v-model="form.permissoes.configuracoes_empresa_editar_dados_at" @keydown.enter.prevent="FOCO">
              <label for="configuracoes_empresa_editar_dados_at">{{l('configuracoes_empresa_editar_dados_at')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="comunicar_documentos_at" v-model="form.permissoes.comunicar_documentos_at" @keydown.enter.prevent="FOCO">
              <label for="comunicar_documentos_at">{{l('comunicar_documentos_at')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="exportar_saft" v-model="form.permissoes.exportar_saft" @keydown.enter.prevent="FOCO">
              <label for="exportar_saft">{{l('exportar_saft')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_STOCK')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="stock_importar_exportar" v-model="form.permissoes.stock_importar_exportar" @keydown.enter.prevent="FOCO">
              <label for="stock_importar_exportar">{{l('stock_importar_exportar')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="stock_ver" v-model="form.permissoes.stock_ver" @keydown.enter.prevent="FOCO">
              <label for="stock_ver">{{l('stock_ver')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="stock_gerir" v-model="form.permissoes.stock_gerir" @keydown.enter.prevent="FOCO">
              <label for="stock_gerir">{{l('stock_gerir')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('POS')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="pos_gerir" v-model="form.permissoes.pos_gerir" @keydown.enter.prevent="FOCO">
              <label for="pos_gerir">{{l('pos_gerir')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="apenas_pos" v-model="form.permissoes.apenas_pos" @keydown.enter.prevent="FOCO">
              <label for="apenas_pos">{{l('apenas_pos')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_empresa')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="configuracoes_empresa" v-model="form.permissoes.configuracoes_empresa" @keydown.enter.prevent="FOCO">
              <label for="configuracoes_empresa">{{l('configuracoes_empresa')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="configuracoes_empresa_editar_dados_bancarios" v-model="form.permissoes.configuracoes_empresa_editar_dados_bancarios" @keydown.enter.prevent="FOCO">
              <label for="configuracoes_empresa_editar_dados_bancarios">{{l('configuracoes_empresa_editar_dados_bancarios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="configuracoes_empresa_gerir_utilizadores" v-model="form.permissoes.configuracoes_empresa_gerir_utilizadores" @keydown.enter.prevent="FOCO">
              <label for="configuracoes_empresa_gerir_utilizadores">{{l('configuracoes_empresa_gerir_utilizadores')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="configuracoes_empresa_gerir_plano" v-model="form.permissoes.configuracoes_empresa_gerir_plano" @keydown.enter.prevent="FOCO">
              <label for="configuracoes_empresa_gerir_plano">{{l('configuracoes_empresa_gerir_plano')}}</label>
            </div><div class="clearfix"></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12 form-group form-material">
            <h4 class="padding-top-10 margin-top-15">{{l('tit_relatorios')}}</h4>
            <div>
              <input class="foco" type="checkbox" id="relatorios" v-model="form.permissoes.relatorios" @keydown.enter.prevent="FOCO">
              <label for="relatorios">{{l('relatorios')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="relatorio_facturacao_por_cliente" v-model="form.permissoes.relatorio_facturacao_por_cliente" @keydown.enter.prevent="FOCO">
              <label for="relatorio_facturacao_por_cliente">{{l('relatorio_facturacao_por_cliente')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="relatorio_saldo_por_cliente" v-model="form.permissoes.relatorio_saldo_por_cliente" @keydown.enter.prevent="FOCO">
              <label for="relatorio_saldo_por_cliente">{{l('relatorio_saldo_por_cliente')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="relatorio_extracto_pagamentos" v-model="form.permissoes.relatorio_extracto_pagamentos" @keydown.enter.prevent="FOCO">
              <label for="relatorio_extracto_pagamentos">{{l('relatorio_extracto_pagamentos')}}</label>
            </div><div class="clearfix"></div>
            <div>
              <input class="foco" type="checkbox" id="relatorio_pagamentos_em_falta" v-model="form.permissoes.relatorio_pagamentos_em_falta" @keydown.enter.prevent="FOCO">
              <label for="relatorio_pagamentos_em_falta">{{l('relatorio_pagamentos_em_falta')}}</label>
            </div><div class="clearfix"></div>

            <div>
              <input class="foco" type="checkbox" id="relatorio_mapa_de_impostos" v-model="form.permissoes.relatorio_mapa_de_impostos" @keydown.enter.prevent="FOCO">
              <label for="relatorio_mapa_de_impostos">{{l('relatorio_mapa_de_impostos')}}</label>
            </div><div class="clearfix"></div>

            <div>
              <input class="foco" type="checkbox" id="relatorio_facturacao_por_item" v-model="form.permissoes.relatorio_facturacao_por_item" @keydown.enter.prevent="FOCO">
              <label for="relatorio_facturacao_por_item">{{l('relatorio_facturacao_por_item')}}</label>
            </div>
          </div>

          <div class="clearfix margin-bottom-15"></div>
          <div class="col-md-4 col-sm-6 form-group form-material">
            <div class="input-group">
              <span class="input-group-addon">
                {{l('hora_inicio')}}
              </span>
              <!--<input autocomplete="off" type="time" class="form-control foco" v-model="form.permissoes.hora_inicio" maxlength="10" :placeholder="$t('hora_defeito')" id="hora_inicio" @keydown.enter.prevent="FOCO" v-hora>-->
              <date-picker :editable="false" type="time" format="HH:mm" :lang="$store.state.langData" class="foco" v-model="form.permissoes.hora_inicio" :placeholder="$t('hora')" id="hora_inicio" :open.sync="openTime" @change="handleChangeTime"></date-picker>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 form-group form-material">
            <div class="input-group">
              <span class="input-group-addon">
                {{l('hora_fim')}}
              </span>
              <!--<input autocomplete="off" type="time" class="form-control foco" v-model="form.permissoes.hora_fim" maxlength="10" :placeholder="$t('hora')" id="hora_fim" @keydown.enter.prevent="FOCO" v-hora>-->
              <date-picker :editable="false" type="time" format="HH:mm" :lang="$store.state.langData" class="foco" v-model="form.permissoes.hora_fim" :placeholder="$t('hora')" :disabled="form.terminado > 0" id="hora_fim" :open.sync="openTime1" @change="handleChangeTime"></date-picker>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 form-group form-material">
            <div class="input-group">
              <span class="input-group-addon">
                {{l('ip_login')}}
              </span>
              <input autocomplete="off" type="text" class="form-control foco" v-model="form.permissoes.ip_login" maxlength="40" id="ip_login" @keydown.enter.prevent="FOCO" v-ip>
              <span class="input-group-btn hidden-xs">
                <button class="btn btn-sm btn-pure" @click="ip1()" v-tooltip.top-start="l('ip_buscar')">IP</button>
              </span>
              <span class="input-group-btn visible-xs menos30">
                <button class="btn btn-sm btn-pure" @click="ip1()" v-tooltip.top-start="l('ip_buscar')">IP</button>
              </span>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p class="col-md-12 text-right"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
        <div class="clearfix"></div>
      </div>
    </div>

    <bot-form link="/configuracoes/utilizadores" :botSubmit="true" :botText="form.id ? 'atualizar' : 'guardar'" @submit="submit"></bot-form>
  </div>
</template>

<script>
import { empresaUsersId, utilizadorUp, utilizadorCriar, permissoesUp, ip, utilizador, senhaUpdate } from '../../resources/fn'
import { Principal, selectIds } from '../../filters/index'
import { mapMutations } from 'vuex'

export default {
  name: 'pageConUsersCriar',
  data () {
    return {
      form: {
        nome: '',
        email: '',
        telefone: '',
        newsletter: true,
        permissoes: {
          ip_login: ''
        },
        lojas: []
      },
      apiToken: '',
      lojas: [],
      openTime: false, // horario de funcionamentod a empresa para fechar ao escolher ops segundos
      openTime1: false
    }
  },
  computed: {
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    }
  },
  watch: {
    '$route' () {
      let a = window.location.pathname.split('/')
      if (a[2] === 'utilizadores' && (a[3] === 'editar' || a[3] === 'criar')) {
        this.information()
      }
    }
  },
  mounted () {
    this.information()
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_TITULO',
      'SET_LOAD',
      'SET_ERROR',
      'SET_AVISO',
      'GET_INFO',
      'SET_INFO',
      'FOCO',
      'SET_SCROLLBOTTOM',
      'SET_INFO_RESET'
    ]),
    handleChangeTime (value, type) {
      if (type === 'minute') {
        this.openTime = false
        this.openTime1 = false
      }
    },
    l (a) {
      return this.$t('configuracoes.utilizadorescriar.' + a)
    },
    information () {
      if (this.$store.state.utilizador1 && this.$store.state.lojas1) {
        this.SET_LOAD(true)
        this.apiToken = this.$store.state.utilizador.api_token
        if (this.$store.state.lojas.length > 1) {
          this.lojas = this.$store.state.lojas
        }
        let id = window.location.pathname.split('/')[4]
        if (id) {
          empresaUsersId(this.axios, id).then((res) => {
            this.SET_TITULO(res.data.nome + '<sup class="' + (res.data.activo ? 'text-success' : 'text-danger') + '">' + this.$t('editar') + '</sup>')
            this.SET_LOAD()
            if (!res.data.permissoes) {
              res.data.permissoes = {}
            }
            if (res.data.permissoes.hora_inicio) {
              res.data.permissoes.hora_inicio = new Date('1900/01/01 ' + res.data.permissoes.hora_inicio + ':00')
            }
            if (res.data.permissoes.hora_fim) {
              res.data.permissoes.hora_fim = new Date('1900/01/01 ' + res.data.permissoes.hora_fim + ':00')
            }
            this.form = res.data
            this.form.lojas = selectIds(res.data.originario)
          }, () => {
            this.SET_ERROR(true)
          })
        } else {
          this.SET_TITULO(this.$t('configuracoes.utilizadores.criar.titulo'))
          this.form = {
            permissoes: {
              ip_login: ''
            },
            lojas: [Principal(this.$store.state.lojas, this.$store.state.loja).id]
          }
          this.SET_LOAD()
        }
      } else {
        this._.delay(this.information, 150)
      }
    },
    submit () {
      if (this.$store.state.utilizador.plano.plano.limite_funcionarios > this.$store.state.utilizador.estatisticas.total_utilizadores || this.form.id > 0) {
        if (this.$store.state.Demo) {
          this.$swal('', this.$t('conta_demo'), 'info')
        } else {
          if (this.form) {
            this.SET_LOAD(true)
            if (this.form.id) {
              utilizadorUp(this.axios, this.form).then(() => {
                if (this.PERMISSOES.configuracoes_empresa_gerir_utilizadores) {
                  this.form.permissoes.user_id = this.form.id
                  let dta = Object.assign({}, this.form.permissoes)
                  let dd
                  if (this.form.permissoes.hora_inicio) {
                    dd = new Date(this.form.permissoes.hora_inicio)
                    dta.hora_inicio = ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
                  }
                  if (this.form.permissoes.hora_fim) {
                    dd = new Date(this.form.permissoes.hora_fim)
                    dta.hora_fim = ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
                  }
                  permissoesUp(this.axios, dta).then(() => {
                    this.SET_TOAST({ msg: this.l('sucessoEditar') })
                    this.SET_INFO_RESET('utilizadores')
                    this.GET_INFO('utilizadores')
                    utilizador(this.axios).then((res) => {
                      this.delyFoco('nome')
                      this.apiToken = res.data.api_token
                      this.SET_INFO({ url: 'apiToken', res: this.apiToken })
                    }, () => {})
                  }, (er) => {
                    this.SET_AVISO(er.response)
                  })
                } else {
                  this.SET_TOAST({ msg: this.l('sucessoEditar') })
                  this.SET_INFO_RESET('utilizadores')
                  this.GET_INFO('utilizadores')
                  this.delyFoco('nome')
                }
              }, (er) => {
                this.SET_AVISO(er.response)
              })
            } else {
              utilizadorCriar(this.axios, this.form).then((res) => {
                // this.SET_INFO({ url: 'utilizadores', res: this.$store.state.pos.concat(this.res) })
                this.SET_INFO_RESET('utilizadores')
                this.GET_INFO('utilizadores')
                this.form.permissoes.user_id = res.data.id
                let dta = Object.assign({}, this.form.permissoes)
                let dd
                if (this.form.permissoes.hora_inicio) {
                  dd = new Date(this.form.permissoes.hora_inicio)
                  dta.hora_inicio = ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
                }
                if (this.form.permissoes.hora_fim) {
                  dd = new Date(this.form.permissoes.hora_fim)
                  dta.hora_fim = ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
                }
                permissoesUp(this.axios, dta).then(() => {
                  this.$router.push('/configuracoes/utilizadores/editar/' + this.form.permissoes.user_id, () => {})
                  this.SET_TOAST({ msg: this.l('sucesso') })
                  this.delyFoco('nome')
                }, (er) => {
                  this.SET_AVISO(er.response)
                })
              }, (er) => {
                this.SET_AVISO(er.response)
              })
            }
          }
        }
      } else {
        this.$swal('', this.$t('limite_utilizadores'), 'warning')
      }
    },
    ip1 () {
      try {
        this.SET_LOAD(true)
        ip(this.axios).then((res) => {
          this.SET_LOAD()
          this.form.permissoes.ip_login = res.data.ip
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } catch (e) {
        console.log(e)
      }
    },
    utilizadorSenha () {
      try {
        let self = this
        if (self.$store.state.Demo) {
          this.$swal('', self.$t('conta_demo'), 'info')
        } else {
          this.$swal({
            title: self.l('password'),
            input: 'password',
            showCancelButton: true,
            confirmButtonText: this.$t('confirmar'),
            cancelButtonText: this.$t('cancelar'),
            inputAttributes: {
              'maxlength': 25,
              'minlength': 6,
              'autocapitalize': 'off',
              'autocorrect': 'off'
            }
          }).then((pass) => {
            if (!pass.dismiss) {
              if (pass.value.length > 5) {
                self.SET_LOAD(true)
                senhaUpdate(self.axios, { password: pass.value }).then(() => {
                  this.SET_TOAST({ msg: this.l('sucessoNovaSenha') })
                  self.SET_LOAD()
                  window.scrollTo(0, 0)
                }, (er) => {
                  self.SET_AVISO(er.response)
                })
              } else {
                this.$swal('', self.l('erroNovaSenha'), 'error').then(() => {
                  self.utilizadorSenha()
                }, () => {
                  self.utilizadorSenha()
                })
              }
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    renovarApi () {
      try {
        this.SET_LOAD()
        permissoesUp(this.axios, { user_id: this.form.id, renovar_token: true, api: true }).then(() => {
          utilizador(this.axios).then((res) => {
            this.apiToken = res.data.api_token
            this.SET_INFO({ url: 'apiToken', res: this.apiToken })
            this.SET_TOAST({ msg: this.l('sucessoToken') })
          }, (er) => {
            this.SET_AVISO(er.response)
          })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } catch (e) {
        console.log(e)
      }
    },
    copiar () {
      document.getElementById('token').select()
      document.execCommand('copy')
    },
    delyFoco (campo) {
      this._.delay(function (a, campo) {
        a.$refs[campo].focus()
      }, 20, this, campo)
    }
  }
}
</script>
