<template>
 <div style="z-index:1;" class="clearfix pr" v-if="lastpage > 1">
  <ul class="pagination pagination-sm margin-bottom-0 pull-right margin-right-0">
    <li v-if="pag > 3"><a href="javascript:void(0)" @click="$emit('formUrl', 1, 1)">1</a></li>
    <li class="disabled" v-if="pag > 4"><a>...</a></li>

    <li v-if="(pag - 2) > 0"><a href="javascript:void(0)" @click="$emit('formUrl', pag - 2, 1)">{{pag - 2}}</a></li>
    <li v-if="(pag - 1) > 0"><a href="javascript:void(0)" @click="$emit('formUrl', pag - 1, 1)">{{pag - 1}}</a></li>

    <li class="active"><a>{{pag}}</a></li>

    <li v-if="pag < lastpage"><a href="javascript:void(0)" @click="$emit('formUrl', pag + 1, 1)">{{pag + 1}}</a></li>
    <li v-if="(pag + 1) < lastpage"><a href="javascript:void(0)" @click="$emit('formUrl', pag + 2, 1)">{{pag + 2}}</a></li>

    <li class="disabled" v-if="pag < (lastpage - 3)"><a>...</a></li>
    <li v-if="pag < (lastpage - 2)"><a href="javascript:void(0)" @click="$emit('formUrl', lastpage, 1)">{{lastpage}}</a></li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    pag: { type: Number },
    lastpage: { type: Number }
  }
}
</script>
