import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'
import en from './../langs/en/translations.json'
import pt from './../langs/pt/translations.json'

import auth from './auth.js';

import { state } from './../vuex/state.js'
import { getters } from './../vuex/getters.js'
import { mutations } from './../vuex/mutations.js'
Vue.use(Vuex)

export const store = new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  modules: auth,
  strict: process.env.NODE_ENV !== 'production'
})

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', en)
Vue.i18n.add('pt', pt)
Vue.i18n.set('pt')
