<template>
  <div v-if="$store.state.utilizador1 && pesq.loja1" id="fullscreen1">
    <div v-if="PERMISSOES.stock_ver">
      <div v-if="$store.state.utilizador.plano.plano.modulo_stock">
        <div v-if="entrada.length == 0 && saida.length == 0">
          <div v-if="stocks.length == 0" class="pr">
            <div class="btn-exportar">
              <btn-csv v-if="PERMISSOES.stock_gerir && PERMISSOES.stock_importar_exportar" @exportar="exportar"></btn-csv>
            </div>
            <!--<button v-if="PERMISSOES.stock_gerir && PERMISSOES.stock_importar_exportar" class="btn btn-primary btn-exportar margin-right-10" @click="exportar">{{$t('exportar_csv')}}</button>-->

            <div class="col-sm-6 col-xs-6 form-material visible-sm padding-left-0 margin-left-10">
              <select class="form-control foco" v-model="pesq.ordenar" id="ordenar1" name="ordenar1" @change="formVer(1)">
                <option disabled>{{$t('ordenar')}}</option>
                <option value="codigo-ASC">{{l('codigo')}} {{$t('asc')}}</option>
                <option value="codigo-DESC">{{l('codigo')}} {{$t('desc')}}</option>
                <option value="descricao-ASC">{{l('descricao')}} {{$t('asc')}}</option>
                <option value="descricao-DESC">{{l('descricao')}} {{$t('desc')}}</option>
              </select>
            </div>
            <div class="col-sm-6 col-xs-6 form-material visible-xs padding-left-0">
              <select class="form-control foco" v-model="pesq.ordenar" id="ordenar1" name="ordenar1" @change="formVer(1)">
                <option disabled>{{$t('ordenar')}}</option>
                <option value="codigo-ASC">{{l('codigo')}} {{$t('asc')}}</option>
                <option value="codigo-DESC">{{l('codigo')}} {{$t('desc')}}</option>
                <option value="descricao-ASC">{{l('descricao')}} {{$t('asc')}}</option>
                <option value="descricao-DESC">{{l('descricao')}} {{$t('desc')}}</option>
              </select>
            </div>
            <button class="btn btn-default pull-right visible-xs visible-sm margin-right-10 padding-horizontal-10" @click="filtrar = !filtrar"><i :class="{glyphicon: 1, 'glyphicon-plus-sign': filtrar, 'glyphicon glyphicon-minus': !filtrar}"></i> {{$t('filtros')}}</button>

            <div class="visible-xs visible-sm margin-top-10 col-xs-12 col-sm-12"></div>
            <div class="clearfix"></div>

            <div class="" style="z-index:7">
              <div :class="{'padding-bottom-15': 1, 'hidden-xs hidden-sm': filtrar}">
                <div class="col-md-12 form-group form-material padding-0">
                  <div class="form-group form-material padding-left-0 col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <span class="input-group-addon">{{l('descricao')}}</span>
                      <input autocomplete="off" type="text" class="form-control foco" :placeholder="$t('pesquisar_artigo')" v-model="pesq.texto" id="descricao1" name="descricao1" @keydown.enter.prevent="FOCO" v-texto @keyup="formSearch()" />
                    </div>
                  </div>
                  <!--
                  <div class="form-group form-material padding-left-0 col-md-6 col-lg-4 col-sm-6 col-xs-12" id="lojasX" v-if="$store.state.lojastotal > 1">
                    <div class="input-group foco" id="lojasX1" name="lojasX1" @keydown.enter.prevent="FOCO">
                      <span class="input-group-addon">{{$t('loja_')}}</span>
                      <treeselect-treeselect :clearable="false" :searchable="false" :multiple="false" :options="$store.state.lojas" placeholder="" v-model="pesq.loja1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" @input="formVer(1)" />
                    </div>
                  </div>-->
                  <div class="form-group form-material padding-left-0 col-md-6 col-lg-4 col-sm-6 col-xs-12" id="categoriasX" v-if="$store.state.categoriasX.length && $store.state.utilizador.empresa.categorias_artigo">
                    <div class="input-group foco" id="categoriasX1" name="categoriasX1" @keydown.enter.prevent="FOCO">
                      <span class="input-group-addon">{{l('categoria')}}</span>

                      <treeselect-treeselect :clearable="true" :searchable="true" :multiple="true" :options="$store.state.categoriasX" placeholder="" v-model="pesq.categoria" :flat="true" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" @input="formVer(1)" />
                    </div>
                  </div>
                  <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                    <div class="input-group">
                      <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                      <!--<input maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco coluna_datas" v-model="data_stock" :placeholder="$t('data_defeito')" @keydown.enter.prevent="FOCO" id="data_stock">-->
                      <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data_stock" :placeholder="$t('data_defeito')" id="data_stock" @change="formVer(1)"></date-picker>
                    </div>
                  </div>
                  <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                    <div class="input-group">
                      <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                      <!--<input pattern="[0-9]{2}:[0-9]{2}" type="time" class="form-control foco coluna_datas" v-model="hora_stock" :placeholder="$t('hora_defeito')" @keydown.enter.prevent="FOCO" id="hora_stock">-->
                      <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="hora_stock" :placeholder="$t('hora_defeito')" id="hora_stock" required :open.sync="openTime" @change="handleChangeTime"></date-picker>
                    </div>
                  </div>
                  <div class="padding-left-0 col-sm-6 col-xs-12 visible-xs visible-sm pull-right margin-top-10">
                    <button @click="formVer(1, 1)" class="btn btn-primary btn-block"><i class="glyphicon glyphicon-search margin-right-5"></i>{{$t('pesquisarBotao')}}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
            <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
              <button class="btn btn-warning btn-block margin-bottom-10" @click="acertarStock()">{{l('acertar')}}</button>
            </div>
            <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
              <button class="btn btn-warning btn-block margin-bottom-10" @click="adicionarStock()">{{l('adicionar')}}</button>
            </div>
            <div class="clearfix"></div>
            <div id="divTable">
              <table id="tabelaFixa" class="table table-hover tabelaVerde dataTable width-full table-striped table-condensed">
                <thead class="hidden-xs hidden-sm" id="tabelaFixaThead">
                  <tr>
                    <th class="width-150"><a href="javascript:void(0)" @click="formUrl1(1, 'codigo')" class="text-left"><i v-show="pesq.ordenar == 'codigo-ASC' || pesq.ordenar == 'codigo-DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-order': pesq.ordenar == 'codigo-ASC','glyphicon-sort-by-order-alt': pesq.ordenar == 'codigo-DESC', 'margin-right-10': 1}"></i>{{l('codigo')}}</a></th>
                    <!--<th class="text-left">{{l('tipo')}}</th>-->
                    <th><a href="javascript:void(0)" @click="formUrl1(1, 'descricao')" class="text-left"><i v-show="pesq.ordenar == 'descricao-ASC' || pesq.ordenar == 'descricao-DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.ordenar == 'descricao-ASC','glyphicon-sort-by-alphabet-alt': pesq.ordenar == 'descricao-DESC', 'margin-right-10': 1}"></i>{{l('descricao')}}</a></th>
                    <th class="text-right width-56">{{$t('stock')}}</th>
                    <th class="width-80"><button class="btn btn-warning btn-block" @click="adicionarStock()">{{l('adicionar')}}</button></th>
                    <th class="width-80"><button class="btn btn-warning btn-block" @click="acertarStock()">{{l('acertar')}}</button></th>
                  </tr>
                  <!--<tr>
                    <td colspan="2" class="form-group">
                      <input autocomplete="off" type="text" class="form-control foco" v-model="pesq.texto" :placeholder="$t('pesquisar_artigo')" id="texto" @keyup="formSearch()" @keydown.enter.prevent="FOCO" v-texto />
                    </td>
                    <td class="form-group">
                      <input ref="codigo" autocomplete="off" type="text" class="form-control foco" v-model="pesq.codigo" :placeholder="l('codigo')" id="codigo" @keyup="formUrl(1, 'codigo')" @keydown.enter.prevent="FOCO" v-texto />
                    </td>
                    <td class="form-group">
                      <input autocomplete="off" type="text" class="form-control foco" v-model="pesq.descricao" :placeholder="l('descricao')" id="descricao" @keyup="formUrl(1, 'descricao')" @keydown.enter.prevent="formVer(1)" v-texto />
                    </td>-->
                    <!--<td><button v-if="PERMISSOES.stock_gerir && PERMISSOES.stock_importar_exportar" class="btn btn-primary" @click="exportar">{{$t('exportar_csv')}}</button></td>
                    <td><button class="btn btn-warning btn-block" @click="adicionarStock()">{{l('adicionar')}}</button></td>
                    <td><button class="btn btn-warning btn-block" @click="acertarStock()">{{l('acertar')}}</button></td>
                  </tr>-->
                </thead>
                <tbody id="tbodyFixa" v-if="resultado">
                  <tr :id="'res' + index" v-for="(dt, index) in res" :key="index">
                    <td class="visible-xs visible-sm">
                      <router-link class="pull-left" target="_blank" :to="'/itens/editar/' + dt.id">
                        <b>{{dt.codigo}}</b><br />{{dt.descricao}}
                      </router-link>
                      <span :class="{'pull-right font-23': 1, 'text-success': dt.stock > dt.stock_minimo && dt.stock_minimo > 0 && dt.stock < dt.stock_maximo && dt.stock_maximo > 0 || dt.stock > 0, 'text-danger': dt.stock < 0, 'text-warning': dt.stock <= dt.stock_minimo || dt.stock > dt.stock_maximo && dt.stock_maximo > 0}" v-if="dt.movimenta_stock && stockResult">{{dt.stock}}</span>
                        <div class="clearfix"></div>
                        <input autocomplete="off" class="col-xs-6 width-100 pull-right form-control text-center margin-top-10" type="text" v-model="dt.quantidade" :id="'wqqx' + index" v-decimal00 :placeholder="$t('quantidade')" />
                      </td>

                      <td class="hidden-xs hidden-sm text-left"><router-link target="_blank" class="block" :to="'/itens/editar/' + dt.id">{{dt.codigo}}</router-link></td>
                      <!--<td class="text-left">{{}}</td>-->
                      <td class="hidden-xs hidden-sm text-left"><router-link target="_blank" class="block" :to="'/itens/editar/' + dt.id">{{dt.descricao}}</router-link></td>
                      <td class="hidden-xs hidden-sm text-right">
                        <span :class="{'text-success': dt.stock > dt.stock_minimo && dt.stock_minimo > 0 && dt.stock < dt.stock_maximo && dt.stock_maximo > 0 || dt.stock > 0, 'text-danger': dt.stock < 0, 'text-warning': dt.stock <= dt.stock_minimo || dt.stock > dt.stock_maximo && dt.stock_maximo > 0}" v-if="dt.movimenta_stock && stockResult">{{dt.stock || 0}}</span>
                        </td>
                        <td  colspan="2" class="hidden-xs hidden-sm text-right text-center">
                          <router-link v-if="dt.variavel" target="_blank" class="block" :to="'/itens/editar/' + dt.id"><i class="glyphicon glyphicon-search"></i></router-link>
                          <input autocomplete="off" class="form-control text-center" type="text" v-model="dt.quantidade" :id="'qq' + index" v-decimal00 :placeholder="$t('quantidade')" v-else />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
                  <button class="btn btn-warning btn-block margin-bottom-10" @click="acertarStock()">{{l('acertar')}}</button>
                </div>
                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
                  <button class="btn btn-warning btn-block margin-bottom-10" @click="adicionarStock()">{{l('adicionar')}}</button>
                </div>
                <paginacao-paginacao :pag="pag" :lastpage="lastpage"></paginacao-paginacao>

                <div class="clearfix"></div>
                <div v-if="PERMISSOES.stock_importar_exportar && !$store.state.modulo">
                  <h3 class="text-uppercase margin-top-50">{{l('importar')}}</h3>
                  <div>
                    <!--
                    <div class="form-group form-material padding-left-0 col-md-4 col-lg-3 col-sm-6 col-xs-12" id="lojasX" v-if="$store.state.lojastotal > 1">
                      <div class="input-group foco" id="lojasX1" name="lojasX1" @keydown.enter.prevent="FOCO">
                        <span class="input-group-addon">{{$t('loja_')}}</span>
                        <treeselect-treeselect :clearable="false" :searchable="false" :multiple="false" :options="$store.state.lojas" placeholder="" v-model="lojaSel" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" />
                      </div>
                    </div>
                    <div class="padding-left-0 col-md-4 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                        <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data_stock1" :placeholder="$t('data_defeito')" id="data_stock"></date-picker>
                      </div>
                    </div>
                    <div class="padding-left-0 col-md-4 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                        <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="hora_stock1" :placeholder="$t('hora_defeito')" id="hora_stock" required :open.sync="openTime1" @change="handleChangeTime1"></date-picker>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    -->
                    <div class="text-center hidden-xs hidden-sm">
                      <a class="btn btn-info margin-horizontal-10 margin-top-5 btn-block-mobile" href="/exemplo-stock.csv" target="_blank" download><i class="glyphicon glyphicon-download-alt"></i> {{l('file_exemplo')}}</a>
                      <span class="btn btn-warning fileinput-button margin-horizontal-10 margin-top-5 margin-0 btn-block-mobile">
                        <i class="glyphicon glyphicon-plus"></i>
                        <span> {{l('stock_ficheiro')}}</span>
                        <input type="file" :value="f1" @change="onFileChange($event)">
                      </span>
                    </div>
                    <div class="padding-left-0 col-sm-6 col-xs-12 visible-xs visible-sm">
                      <span class="btn btn-warning fileinput-button">
                        <i class="glyphicon glyphicon-plus"></i>
                        <span> {{l('stock_ficheiro')}}</span>
                        <input type="file" :value="f1" @change="onFileChange($event)">
                      </span>
                    </div>
                    <div class="padding-left-0 col-sm-6 col-xs-12 visible-xs visible-sm">
                      <a class="btn btn-info" href="/exemplo-stock.csv" target="_blank" download><i class="glyphicon glyphicon-download-alt"></i> {{l('file_exemplo')}}</a>
                    </div>
                    <div class="clearfix"></div>
                    <div class="ajuda alert alert-info margin-left-0 margin-top-10 margin-right-10 margin-bottom-10 hidden-xs" v-text="l('stock_limite')"></div>
                    <div class="ajuda alert alert-info margin-10 visible-xs" v-text="l('stock_limite')"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div id="divTable">
                  <div class="form-group form-material padding-left-0 col-md-6 col-lg-4 col-sm-6 col-xs-12" id="lojasX" v-if="$store.state.lojastotal > 1">
                    <div class="input-group foco" id="lojasX1" name="lojasX1" @keydown.enter.prevent="FOCO">
                      <span class="input-group-addon">{{$t('loja_')}}</span>
                      <treeselect-treeselect :clearable="false" :searchable="false" :multiple="false" :options="$store.state.lojas" placeholder="" v-model="pesq.loja1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" />
                    </div>
                  </div>
                  <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                    <div class="input-group">
                      <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                      <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data_stock" :placeholder="$t('data_defeito')" id="data_stock12"></date-picker>
                    </div>
                  </div>
                  <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                    <div class="input-group">
                      <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                      <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="hora_stock" :placeholder="$t('hora_defeito')" id="hora_stock12" required :open.sync="openTime"></date-picker>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="ajuda alert alert-info margin-left-0 margin-top-10 margin-right-10 margin-bottom-10 hidden-xs" v-text="l('ajuda_data')"></div>
                  <div class="ajuda alert alert-info margin-10 visible-xs" v-text="l('ajuda_data')"></div>
                  <div class="clearfix"></div>

                  <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
                    <button class="btn btn-warning btn-block margin-bottom-10" @click="acertarStockFile()">{{l('acertar')}}</button>
                  </div>
                  <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
                    <button class="btn btn-default btn-block margin-bottom-10" @click="stocks = []; formVer(1); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                  </div>
                  <div class="clearfix"></div>
                  <table id="tabelaFixa" class="table table-hover dataTable width-full table-striped table-condensed">
                    <thead id="tabelaFixaThead" class="hidden-xs">
                      <tr>
                        <th class="text-left">{{l('codigo1')}}</th>
                        <th class="text-left">{{l('descricao')}}</th>
                        <th class="width-110 text-right">{{$t('stock')}}</th>
                        <th class="text-right width-100">{{$t('quantidade')}}</th>
                      </tr>
                      <tr>
                        <td colspan="100%">
                          <button class="pull-right btn btn-warning" @click="acertarStockFile()">{{l('acertar')}}</button>
                          <button class="pull-right btn btn-default margin-right-10" @click="stocks = []; formVer(1); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                        </td>
                      </tr>
                    </thead>
                    <tbody :id="'res' + index" v-for="(dt, index) in stocks" :key="index">
                      <tr>
                        <td class="visible-xs">
                          <span class="pull-left">
                            <b>{{dt.ProductCode}}</b><br />{{dt.ProductDescription}}
                          </span>
                          <span class="pull-right font-23">{{dt.stock}}</span>
                          <div class="clearfix"></div>
                          <div class="col-xs-6 col-sm-4 pull-right padding-0">
                            <input autocomplete="off" class="form-control text-center" type="text" v-model="dt.quantidade" :id="'rwq' + index" v-decimal :placeholder="$t('quantidade')" />
                          </div>
                        </td>
                        <td class="text-left hidden-xs">{{dt.ProductCode}}</td>
                        <td class="text-left hidden-xs">{{dt.ProductDescription}}</td>
                        <th class="text-right hidden-xs">{{dt.stock}}</th>
                        <td class="hidden-xs">
                          <input autocomplete="off" class="form-control foco text-center" type="text" v-model="dt.quantidade" :id="'qwwq' + index" @keypress.enter.stop="stocks.length == (index+1) ? acertarStockFile() : FOCO($event)" v-decimal :placeholder="$t('quantidade')" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="stocks.length > 9">
                    <button class="btn btn-default btn-block margin-bottom-10 visible-xs visible-sm" @click="stocks = []; formVer(1); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                    <button class="btn btn-warning btn-block margin-bottom-10 visible-xs visible-sm" @click="acertarStockFile()">{{l('acertar')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="adicionar">
                <!--<div class="form-group form-material padding-left-0 col-md-6 col-lg-4 col-sm-6 col-xs-12" id="lojasX" v-if="$store.state.lojastotal > 1">
                  <div class="input-group foco" id="lojasX1" name="lojasX1" @keydown.enter.prevent="FOCO">
                    <span class="input-group-addon">{{$t('loja_')}}</span>
                    <treeselect-treeselect :clearable="false" :searchable="false" :multiple="false" :options="$store.state.lojas" placeholder="" v-model="pesq.loja1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" />
                  </div>
                </div>-->
                <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                  <div class="input-group">
                    <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                    <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data_stock" :placeholder="$t('data_defeito')" id="data_stock1"></date-picker>
                  </div>
                </div>
                <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
                  <div class="input-group">
                    <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                    <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="hora_stock" :placeholder="$t('hora_defeito')" id="hora_stock1" required :open.sync="openTime"></date-picker>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="ajuda alert alert-info margin-left-0 margin-top-10 margin-right-10 margin-bottom-10 hidden-xs" v-text="l('ajuda_data')"></div>
                <div class="ajuda alert alert-info margin-10 visible-xs" v-text="l('ajuda_data')"></div>
                <div class="clearfix"></div>

                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
                  <button class="btn btn-warning btn-block visible-xs visible-sm margin-bottom-10" @click="adicionarStock1()">{{l('adicionar')}}</button>
                </div>
                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
                  <button class="btn btn-default btn-block visible-xs visible-sm margin-bottom-10" @click="entrada = []; SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                </div>
                <div class="clearfix"></div>
                <div id="divTable">
                  <table id="tabelaFixa" class="table tabelaVerde table-hover dataTable width-full table-striped table-condensed">
                    <thead id="tabelaFixaThead hidden-xs">
                      <tr>
                        <th class="text-left">{{l('codigo1')}}</th>
                        <th class="text-left">{{l('descricao')}}</th>
                        <th class="text-right">{{l('stock_anterior')}}</th>
                        <th class="text-right">{{l('entrada')}}</th>
                        <th class="text-right">{{l('novo_stock')}}</th>
                      </tr>
                      <tr>
                        <td colspan="100%">
                          <button class="pull-right btn btn-warning" @click="adicionarStock1()">{{l('adicionar')}}</button>
                          <button class="pull-right btn btn-default margin-right-10" @click="entrada = [];SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                        </td>
                      </tr>
                    </thead>
                    <tbody v-for="(dt, i) in entrada" :key="'e' + i">
                      <tr>
                        <td class="visible-xs">
                          <span class="pull-left">
                            <b>{{dt.codigo}}</b><br />{{dt.nome}}
                          </span>
                          <span class="pull-right font-23">{{dt.stockatual}} + {{dt.quantidade}} = {{dt.stockatual * 1 + dt.quantidade * 1}}</span>
                      <!--<div class="clearfix"></div>
                      <span class="pull-left">{{l('stock_anterior')}}:</span><b class="pull-right">{{dt.stockatual}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('entrada')}}:</span><b class="pull-right">{{dt.quantidade}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('novo_stock')}}:</span><b class="pull-right">{{dt.stockatual * 1 + dt.quantidade * 1}}</b>-->
                    </td>

                    <td class="hidden-xs text-left">{{dt.codigo}}</td>
                    <td class="hidden-xs text-left">{{dt.nome}}</td>
                    <td class="hidden-xs text-right">{{dt.stockatual}}</td>
                    <td class="hidden-xs text-right">{{dt.quantidade}}</td>
                    <td class="hidden-xs text-right">{{dt.stockatual * 1 + dt.quantidade * 1}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="entrada.length > 9">
              <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
                <button class="btn btn-warning btn-block visible-xs visible-sm margin-bottom-10" @click="adicionarStock1()">{{l('adicionar')}}</button>
              </div>
              <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
                <button class="btn btn-default btn-block visible-xs visible-sm margin-bottom-10" @click="entrada = []; SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
              </div>
            </div>
          </div>
          <div v-else>
            <!--<div class="form-group form-material padding-left-0 col-md-6 col-lg-4 col-sm-6 col-xs-12" id="lojasX" v-if="$store.state.lojastotal > 1">
              <div class="input-group foco" id="lojasX1" name="lojasX1" @keydown.enter.prevent="FOCO">
                <span class="input-group-addon">{{$t('loja_')}}</span>
                <treeselect-treeselect :clearable="false" :searchable="false" :multiple="false" :options="$store.state.lojas" placeholder="" v-model="pesq.loja1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" />
              </div>
            </div>-->
            <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
              <div class="input-group">
                <span class="input-group-addon">{{$t('data')}}<span class="verm">*</span></span>
                <date-picker :clearable="false" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="foco" v-model="data_stock" :placeholder="$t('data_defeito')" id="data_stock2"></date-picker>
              </div>
            </div>
            <div class="padding-left-0 col-md-6 col-lg-3 col-sm-6 col-xs-12 form-group form-material">
              <div class="input-group">
                <span class="input-group-addon">{{$t('hora')}}<span class="verm">*</span></span>
                <date-picker :clearable="false" :editable="false" type="time" format="HH:mm:ss" :lang="$store.state.langData" class="foco" v-model="hora_stock" :placeholder="$t('hora_defeito')" id="hora_stock2" required :open.sync="openTime"></date-picker>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="ajuda alert alert-info margin-left-0 margin-top-10 margin-right-10 margin-bottom-10 hidden-xs" v-text="l('ajuda_data')"></div>
            <div class="ajuda alert alert-info margin-10 visible-xs" v-text="l('ajuda_data')"></div>
            <div class="clearfix"></div>

            <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
              <button class="btn btn-warning btn-block margin-bottom-10" @click="acertarStock1()">{{l('acertar')}}</button>
            </div>
            <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
              <button class="btn btn-default btn-block margin-bottom-10" @click="horaDefault(); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
            </div>
            <div id="divTable">
              <table id="tabelaFixa" class="table table-hover dataTable width-full table-striped table-condensed">
                <thead id="tabelaFixaThead" class="hidden-xs">
                  <tr>
                    <th class="text-left">{{l('codigo1')}}</th>
                    <th class="text-left">{{l('descricao')}}</th>
                    <th class="text-right">{{l('stock_anterior')}}</th>
                    <!--<th class="text-right">{{l('saida')}}</th>
                      <th class="text-right">{{l('entrada')}}</th>-->
                      <th class="text-right">{{l('novo_stock')}}</th>
                    </tr>
                    <tr>
                      <td colspan="100%">
                        <button class="btn btn-warning pull-right" @click="acertarStock1()">{{l('acertar')}}</button>
                        <button class="btn btn-default pull-right margin-right-10" @click="horaDefault(); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                      </td>
                    </tr>
                  </thead>
                  <tbody v-for="(dt, i) in entrada" :key="'xw' + i">
                    <tr>
                      <td class="visible-xs">
                        <span class="pull-left">
                          <b>{{dt.codigo}}</b><br />{{dt.nome}}
                        </span>
                        <span class="pull-right font-23"><span class="text-danger margin-right-10">{{dt.stockatual}}</span> -> {{dt.stockfinal}}</span>
                      <!--<div class="clearfix"></div>
                      <span class="pull-left">{{l('stock_anterior')}}:</span><b class="pull-right">{{dt.stockatual}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('entrada')}}:</span><b class="pull-right">{{dt.quantidade}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('novo_stock')}}:</span><b class="pull-right">{{dt.stockfinal}}</b>-->
                    </td>
                    <td class="hidden-xs text-left">{{dt.codigo}}</td>
                    <td class="hidden-xs text-left">{{dt.nome}}</td>
                    <td class="hidden-xs text-right">{{dt.stockatual}}</td>
                    <!--<td class="hidden-xs text-right"></td>
                      <td class="hidden-xs text-right">{{dt.quantidade}}</td>-->
                      <td class="hidden-xs text-right">{{dt.stockfinal}}</td>
                    </tr>
                  </tbody>
                  <tbody v-for="(dt, i) in saida" :key="'wd' + i">
                    <tr>
                      <td class="visible-xs">
                        <span class="pull-left">
                          <b>{{dt.codigo}}</b><br />{{dt.nome}}
                        </span>
                        <span class="pull-right font-23"><span class="text-danger risco margin-right-10">{{dt.stockatual}}</span>{{dt.stockfinal}}</span>
                      <!--<div class="clearfix"></div>
                      <span class="pull-left">{{l('stock_anterior')}}:</span><b class="pull-right">{{dt.stockatual}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('saida')}}:</span><b class="pull-right">{{dt.quantidade}}</b>
                      <div class="clearfix"></div>
                      <span class="pull-left">{{l('novo_stock')}}:</span><b class="pull-right">{{dt.stockfinal}}</b>-->
                    </td>
                    <td class="hidden-xs text-left">{{dt.codigo}}</td>
                    <td class="hidden-xs text-left">{{dt.nome}}</td>
                    <td class="hidden-xs text-right">{{dt.stockatual}}</td>
                    <!--<td class="hidden-xs text-right">{{dt.quantidade}}</td>
                      <td class="hidden-xs text-right"></td>-->
                      <td class="hidden-xs text-right">{{dt.stockfinal}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="saida.length + entrada.length > 9">
                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-right">
                  <button class="btn btn-warning btn-block margin-bottom-10" @click="acertarStock1()">{{l('acertar')}}</button>
                </div>
                <div class="padding-left-0 col-sm-6 col-xs-6 visible-xs visible-sm pull-left">
                  <button class="btn btn-default btn-block margin-bottom-10" @click="horaDefault(); SET_TITULO(l('titulo'))">{{$t('cancelar')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <msg-permissoes :ok="true" v-else :margin="true" :text="'nao_suporta_compras'"></msg-permissoes>
      </div>
      <msg-permissoes :ok="true" v-else :margin="true"></msg-permissoes>
    </div>
  </template>

  <script>
  import { itens, documentosCriar, stock } from '../../resources/fn'
  import { formUrl1, formVer, Principal } from '../../filters/index'
  import { mapMutations } from 'vuex'

  export default {
    name: 'pageStock',
    data () {
      return {
        pesq: {},
        pesq1: {},
        lojaSel: '',
        res: [],
        pag: 1,
        lastpage: 1,
      stockResult: false, // para nao aparecer os stocks a imprimir a 0
      total: 0,
      resultado: false,
      entrada: [],
      saida: [],
      data_stock: '',
      hora_stock: '',
      data_stock1: '',
      hora_stock1: '',
      f1: '',
      // ano: new Date().getFullYear() + 1
      openTime: false, // hora do modal da hora filtrar pesq
      openTime1: false, // hora do modal da hora importar inventario
      adicionar: false, // se adiciona stock ou se acerta
      stocks: [], // stock do inventario importado
      filtrar: 1
    }
  },
  computed: {
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    }
  },
  watch: {
    '$route' (a, b) {
      if (a.name !== b.name) {
        window.localStorage.removeItem('pesquisar')
        this.filtrar = 1
      }
      this.init()
    }
  },
  mounted () {
    this.formSearch = this._.debounce(function () {
      if (this.pesq.texto.length < 3 && this.pesq1.texto.length < 3 || JSON.stringify(this.pesq) === JSON.stringify(this.pesq1) && this.pesq1.ordenar) {
        return
      }
      this.formVer(1)
    }, 550)
    this.pesq = {
      loja1: '',
      loja: [],
      categoria: [],
      servico: false,
      taxa: false,
      produto: true,
      movimenta_stock: true,
      // codigo: '',
      // descricao: '',
      visivel: true,
      ordenar: 'descricao-ASC',
      texto: ''
    }
    this.init()
  },
  destroyed () {
    window.localStorage.removeItem('pesquisar')
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_LOAD',
      'SET_ERROR',
      'SET_TITULO',
      'SET_AVISO',
      'FOCO',
      'UP_STOCK_ITENS',
      'DOWNLOAD'
      ]),
    handleChangeTime (value, type) {
      if (type === 'second') {
        this.openTime = false
      }
      this.formVer(1)
    },
    handleChangeTime1 (value, type) {
      if (type === 'second') {
        this.openTime1 = false
      }
    },
    l (a) {
      return this.$t('itens.stocks.' + a)
    },
    init () {
      if (this.$store.state.utilizador1 && this.$store.state.categorias1 && this.$store.state.lojas1 && this.$store.state.series1) {
        if (window.localStorage.getItem('pesquisar')) {
          this.pesq = JSON.parse(window.localStorage.getItem('pesquisar'))
          this.data_stock = new Date(this.pesq.data1) || new Date()
          this.hora_stock = new Date(this.pesq.data1) || new Date()
        } else {
          this.pesq = {
            loja1: this.$store.state.loja,
            loja: [],
            categoria: [],
            servico: false,
            taxa: false,
            produto: true,
            movimenta_stock: true,
            texto: '',
            visivel: true,
            ordenar: 'descricao-ASC',
            itens: 1
          }
          this.data_stock = new Date()
          this.hora_stock = new Date()
        }
        this.pesq1 = Object.assign({}, this.pesq)

        this.lojaSel = this.$store.state.loja
        this.data_stock1 = new Date()
        this.hora_stock1 = new Date()
        this.formUrl1(parseInt(window.location.pathname.split('/')[2]) || 1)
      } else {
        this._.delay(this.init, 100)
      }
    },
    horaDefault (data) {
      if (data) {
        this.data_stock = new Date(data)
        this.hora_stock = new Date(data)
        this.data_stock1 = new Date(data)
        this.hora_stock1 = new Date(data)
        this.stocks = []
        this.formVer(1)
      } else {
        this.res.forEach((res) => {
          if (res.stock1) {
            res.stock = res.stock1
          }
        })
      }
      this.entrada = []
      this.saida = []
    },
    formUrl1 (pag, order) {
      if (formUrl1(order, this.pesq, this.pesq1)) {
        this.formVer(pag)
      }
    },
    formVer (pag, botaopesquisar) {
      try {
        let dd = new Date(this.data_stock) || new Date()
        let dd1 = new Date(this.hora_stock) || new Date()
        this.pesq.data1 = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2)
        this.pesq.loja = [ this.$store.state.loja ] // [ this.pesq.loja1 ]
        this.pesq1 = Object.assign({}, this.pesq)
        this.pesq.limite = 50
        this.$router.replace('/stocks/' + pag, () => {})
        window.localStorage.setItem('pesquisar', JSON.stringify(this.pesq))
        this.SET_LOAD(true)
        this.resultado = false
        itens(this.axios, formVer(this.pesq), pag, this.$store.state.modulo).then((res) => {
          this.resultado = true
          this.res = res.data.data
          // buscar stocks
          let i = res.data.data.length
          let ar = []
          this.stockResult = false
          if (i) {
            while (--i >= 0) {
              if (res.data.data[i].movimenta_stock && !res.data.data[i].servico) {
                /*
                if (res.data.data[i].produto_composto) {
                  res.data.data[i].composicao.map(a => {
                    if (a.item.movimenta_stock && !a.item.servico) {
                      ar.push(a.item_id)
                    }
                  })
                }
                */
                ar.push(res.data.data[i].id)
                res.data.data[i].stock = ''
              }
            }
            if (ar[0]) {
              stock(this.axios, { itens: ar, lojas: [this.$store.state.loja], data_stock: this.pesq.data1 }, 1, this.$store.state.modulo).then((res) => {
                i = res.data.data.length
                let u = this.res.length
                ar = this.res
                let x
                // let kk = 0
                // let xk = 0
                // let quantidade = -1
                while (--i >= 0) {
                  x = u
                  while (--x >= 0) {
                    if (res.data.data[i].item_id === ar[x].id) {
                      /*
                      if (ar[x].produto_composto) {
                        kk = ar[x].composicao.length
                        xk = xxk
                        quantidade = -1
                        while (--kk >= 0) {
                          if (ar[x].composicao[kk].item.movimenta_stock) {
                            while (--xk >= 0) {
                              if (ar[x].composicao[kk].item_id === res.data.data[xk].item_id) {
                                if (res.data.data[xk].stock > 0) {
                                  if (quantidade > 0) {
                                    quantidade = Math.min(res.data.data[xk].stock / ar[x].composicao[kk].quantidade, quantidade)
                                  } else {
                                    quantidade = res.data.data[xk].stock / ar[x].composicao[kk].quantidade
                                  }
                                } else {
                                  quantidade = 0
                                }
                                break
                              }
                            }
                            if (quantidade === 0) {
                              break
                            }
                          }
                        }
                        ar[x].stock = quantidade > 0 ? quantidade : 0
                      } else {
                        ar[x].stock = res.data.data[i].stock
                      }
                      */
                      ar[x].stock = res.data.data[i].stock || 0
                    }
                  }
                }
                this.$set(this.res, ar)
                this.stockResult = true
              }, () => {})
            }
          }
          // fim de buscar stock
          this.pag = res.data.current_page
          this.total = res.data.total
          this.SET_TITULO(this.l('titulo')) // + ' <sup class="text-success">' + this.total + '</sup>'
          this.lastpage = res.data.last_page
          if (botaopesquisar) {
            window.scrollTo(0, window.document.getElementById('tabelaFixa').getBoundingClientRect().top - (document.body.clientWidth >= 850 ? 50 : 0))
            this.filtrar = 1
          }
          this.SET_LOAD()
        }, () => {
          this.SET_ERROR(true)
        })
      } catch (e) {
        console.log(e)
        this.SET_ERROR(true)
      }
    },
    adicionarStock () {
      this.SET_TITULO(this.l('titulo_adicionar'))
      this.adicionar = true
      this.entrada = []
      this.res.forEach((res, i) => {
        if (res.quantidade > 0) {
          // entrada
          this.entrada.unshift({ item_id: res.id, codigo: res.codigo, nome: res.descricao, quantidade: parseFloat(res.quantidade), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, loja1: this.$store.state.loja /* this.pesq.loja1 */, stockatual: res.stock, i: i })
        }
        res.quantidade = ''
      })
      /*
      let i = this.res.length
      while (--i >= 0) {
        if (this.res[i].quantidade > 0) {
          // entrada
          this.entrada.unshift({ item_id: this.res[i].id, codigo: this.res[i].codigo, nome: this.res[i].descricao, quantidade: parseFloat(this.res[i].quantidade), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, loja1: this.pesq.loja1, stockatual: this.res[i].stock, i: i })
        }
        this.res[i].quantidade = ''
      }
      */
      if (!this.entrada.length) {
        this.$swal('', this.l('stockAddPreencher'), 'info')
      }
    },
    adicionarStock1 () {
      this.SET_LOAD(true)
      let dd = new Date(this.data_stock)
      let dd1 = new Date(this.hora_stock)
      documentosCriar(this.axios, { data: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2), serie_id: Principal(this.$store.state.series).id, loja_id: this.$store.state.loja /* this.pesq.loja1*/ , tipo_documento_id: 24, terminado: 1, produtos: this.entrada, prazo_vencimento: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2), metodo_pagamento_id: 0, metodo_expedicao_id: 0 }, this.$store.state.modulo).then((res) => {
        this.SET_TOAST({ msg: this.l('sucesso') })
        this.horaDefault(res.data.created_at)
      }, () => {
        this.SET_LOAD()
        this.$swal('', this.l('stockAddErro'), 'info')
      })
      this.SET_TITULO(this.l('titulo') + ' <sup class="text-success">' + this.total + '</sup>')
    },

    acertarStock () {
      this.SET_TITULO(this.l('titulo_acertar'))
      this.adicionar = false
      // let i = this.res.length
      this.entrada = []
      this.saida = []
      // let x
      this.res.forEach((res) => {
        let x
        if (res.quantidade >= 0 && parseInt(res.quantidade) !== (parseInt(res.stock) || 0)) {
          // entrada
          if (res.quantidade > res.stock) {
            if (res.stock >= 0) {
              x = parseFloat(res.quantidade) - res.stock
            } else {
              // quantidade negativa em stock
              x = parseFloat(res.quantidade) + res.stock * -1
            }
            this.entrada.unshift({ item_id: res.id, codigo: res.codigo, nome: res.descricao, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: res.stock, stockfinal: res.quantidade })
            res.stock1 = res.stock
            res.stock = res.quantidade
          } else {
            // saida
            if (parseFloat(res.quantidade) < parseFloat(res.stock)) {
              x = parseFloat(res.stock) - parseFloat(res.quantidade)
              this.saida.unshift(Object.assign({}, { item_id: res.id, codigo: res.codigo, nome: res.descricao, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: res.stock, stockfinal: res.quantidade }))
              res.stock1 = res.stock
              res.stock = res.quantidade
            }
          }
          res.quantidade = ''
        }
      })
      /*
      while (--i >= 0) {
        console.log(this.res[i].quantidade, this.res[i].stock)
        if (this.res[i].quantidade >= 0 && this.res[i].quantidade !== this.res[i].stock) {
          // entrada
          if (parseFloat(this.res[i].quantidade) > parseFloat(this.res[i].stock)) {
            if (parseFloat(this.res[i].stock) >= 0) {
              x = parseFloat(this.res[i].quantidade) - parseFloat(this.res[i].stock)
            } else {
              // quantidade negativa em stock
              x = parseFloat(this.res[i].quantidade) + parseFloat(this.res[i].stock * -1)
            }
            this.entrada.unshift({ item_id: this.res[i].id, codigo: this.res[i].codigo, nome: this.res[i].descricao, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: this.res[i].stock, stockfinal: this.res[i].quantidade })
            this.res[i].stock1 = this.res[i].stock
            this.res[i].stock = this.res[i].quantidade
          } else {
            // saida
            if (parseFloat(this.res[i].quantidade) < parseFloat(this.res[i].stock)) {
              x = parseFloat(this.res[i].stock) - parseFloat(this.res[i].quantidade)
              this.saida.unshift(Object.assign({}, { item_id: this.res[i].id, codigo: this.res[i].codigo, nome: this.res[i].descricao, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: this.res[i].stock, stockfinal: this.res[i].quantidade }))
              this.res[i].stock1 = this.res[i].stock
              this.res[i].stock = this.res[i].quantidade
            }
          }
        }
        this.res[i].quantidade = ''
      }
      */
      if (this.saida.length === 0 && this.entrada.length === 0) {
        this.$swal('', this.l('stockAcertarPreencher'), 'info')
        this.SET_TITULO(this.l('titulo')) // + ' <sup class="text-success">' + this.total + '</sup>'
      }
    },
    acertarStock1 () {
      let serie = Principal(this.$store.state.series).id
      let dd = new Date(this.data_stock)
      let dd1 = new Date(this.hora_stock)
      let data = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2)
      if (this.entrada.length) {
        this.SET_LOAD(true)
        documentosCriar(this.axios, { data: data, serie_id: serie, loja_id: this.$store.state.loja /* this.pesq.loja1 */, tipo_documento_id: 24, terminado: 1, produtos: this.entrada, prazo_vencimento: data, metodo_pagamento_id: 0, metodo_expedicao_id: 0 }, this.$store.state.modulo).then((res) => {
          if (this.saida.length) {
            this.SET_LOAD(true)
            documentosCriar(this.axios, { data: data, serie_id: serie, loja_id: this.$store.state.loja /* this.pesq.loja1 */, tipo_documento_id: 25, terminado: 1, produtos: this.saida, prazo_vencimento: data, metodo_pagamento_id: 0, metodo_expedicao_id: 0 }, this.$store.state.modulo).then((res) => {
              this.SET_TOAST({ msg: this.l('sucessoAcertar') })
              this.horaDefault(res.data.created_at)
              this.stocks = []
              this.formVer(1)
            }, () => {
              this.SET_LOAD()
              this.$swal('', this.l('stockAcertarErro'), 'error')
              this.horaDefault(res.data.created_at)
            })
          } else {
            this.SET_TOAST({ msg: this.l('sucessoAcertar') })
            this.entrada = []
            this.horaDefault(res.data.created_at)
            this.stocks = []
            this.formVer(1)
          }
        }, () => {
          if (this.saida.length) {
            this.SET_LOAD(true)
            documentosCriar(this.axios, { data: data, serie_id: serie, loja_id: this.$store.state.loja /* this.pesq.loja1 */, tipo_documento_id: 25, terminado: 1, produtos: this.saida, prazo_vencimento: data, metodo_pagamento_id: 0, metodo_expedicao_id: 0 }, this.$store.state.modulo).then((res) => {
              this.SET_TOAST({ msg: this.l('sucessoAcertar') })
              this.horaDefault(res.data.created_at)
              this.stocks = []
              this.formVer(1)
            }, () => {
              this.SET_LOAD()
              this.$swal('', this.l('stockAcertarErro'), 'error')
              this.horaDefault()
              this.stocks = []
              this.formVer(1)
            })
          }
        })
      } else {
        if (this.saida.length) {
          this.SET_LOAD(true)
          documentosCriar(this.axios, { data: data, serie_id: serie, loja_id: this.$store.state.loja /* this.pesq.loja1 */, tipo_documento_id: 25, terminado: 1, produtos: this.saida, prazo_vencimento: data, metodo_pagamento_id: 0, metodo_expedicao_id: 0 }, this.$store.state.modulo).then((res) => {
            this.SET_TOAST({ msg: this.l('sucessoAcertar') })
            this.horaDefault(res.data.created_at)
            this.stocks = []
            this.formVer(1)
          }, () => {
            this.SET_LOAD()
            this.$swal('', this.l('stockAcertarErro'), 'error')
            this.horaDefault()
          })
        }
      }
      this.SET_TITULO(this.l('titulo')) // + ' <sup class="text-success">' + this.total + '</sup>'
    },

    exportar () {
      let dd = new Date(this.data_stock)
      let dd1 = new Date(this.hora_stock)
      let dta = { data_stock: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2), lojas: [this.$store.state.loja /* this.pesq.loja1 */] }
      // itens: [] -> se quiser filtrar por array
      this.DOWNLOAD({ type: 'csv', url: '/exportar/inventario', data: dta, name: 'BILL-stock-' + dta.data_stock + '.csv' })
    },

    onFileChange (e) {
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      this.f1 = ''
      if (!files.length) {
        return
      }
      this.SET_TITULO(this.l('titulo_importar'))
      let data = new window.FormData()
      data.append('file', files[0])
      this.SET_LOAD(true)
      this.axios.post('/importar/inventario', data).then((res) => {
        let i = []
        res.data = Object.keys(res.data).map(key => res.data[key])
        res.data.forEach((item) => {
          if (!(i.findIndex(redItem => item.id === redItem.id) > -1)) {
            i.push(item)
          }
        })
        res.data = i
        i = res.data.length
        if (i) {
          let ar = []
          while (--i >= 0) {
            res.data[i].ClosingStockQuantity = res.data[i].ClosingStockQuantity.replace(',', '.')
            res.data[i].quantidade = parseFloat(res.data[i].ClosingStockQuantity > 0 ? res.data[i].ClosingStockQuantity : 0) || 0
            res.data[i].stock = ''
            ar.push(res.data[i].id)
          }
          let dd = new Date(this.data_stock1)
          let dd1 = new Date(this.hora_stock1)
          stock(this.axios, { itens: ar, data_stock: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd1.getHours()).slice(-2) + ':' + ('0' + dd1.getMinutes()).slice(-2) + ':' + ('0' + dd1.getSeconds()).slice(-2) }, 1, this.$store.state.modulo).then((res1) => {
            i = res1.data.data.length
            let u = res.data.length
            let x
            while (--i >= 0) {
              x = u
              while (--x >= 0) {
                if (res1.data.data[i].item_id === res.data[x].id) {
                  res.data[x].stock = res1.data.data[i].stock || 0
                }
              }
            }
            this.stocks = res.data
            window.scrollTo(0, 0)
            // this.SET_TOAST({ msg: this.l('importadosucesso') })
            this.$swal('', this.l('importadosucesso'), 'info')
            this.SET_LOAD()
          }, () => {})
        } else {
          this.formVer(1, 0)
          // this.SET_TOAST({ msg: this.l('importadosucesso_sem_stock') })
          this.$swal('', this.l('importadosucesso_sem_stock'), 'info')
        }
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    acertarStockFile () {
      this.adicionar = false
      let i = this.stocks.length
      // let serie = Principal(this.$store.state.series).id
      // let data = this.data_stock + ' ' + gethora(0, 1)
      this.entrada = []
      this.saida = []
      let x
      while (--i >= 0) {
        if (!this.stocks[i].stock) {
          this.stocks[i].stock = 0
        }
        if (this.stocks[i].quantidade >= 0 && this.stocks[i].quantidade !== this.stocks[i].stock) {
          // entrada
          if (parseFloat(this.stocks[i].quantidade) > parseFloat(this.stocks[i].stock)) {
            if (parseFloat(this.stocks[i].stock) >= 0) {
              x = parseFloat(this.stocks[i].quantidade) - parseFloat(this.stocks[i].stock)
            } else {
              // quantidade negativa em stock
              x = parseFloat(this.stocks[i].quantidade) + parseFloat(this.stocks[i].stock * -1)
            }
            this.entrada.push({ item_id: this.stocks[i].id, codigo: this.stocks[i].ProductCode, nome: this.stocks[i].ProductDescription, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: this.stocks[i].stock, stockfinal: this.stocks[i].quantidade })
            this.stocks[i].stock = this.stocks[i].quantidade
            this.stocks[i].quantidade = ''
          } else {
            // saida
            if (parseFloat(this.stocks[i].quantidade) < parseFloat(this.stocks[i].stock)) {
              x = parseFloat(this.stocks[i].stock) - parseFloat(this.stocks[i].quantidade)
              this.saida.push({ item_id: this.stocks[i].id, codigo: this.stocks[i].ProductCode, nome: this.stocks[i].ProductDescription, quantidade: parseFloat(x), preco_unitario: 0, imposto_id: 0, motivo_isencao_id: this.$store.state.isencao[0].id, stockatual: this.stocks[i].stock, stockfinal: this.stocks[i].quantidade })
              this.stocks[i].stock = this.stocks[i].quantidade
              this.stocks[i].quantidade = ''
            }
          }
        }
      }
      this.SET_LOAD()
      if (this.saida.length === 0 && this.entrada.length === 0) {
        this.$swal('', this.l('stockAcertarPreencher'), 'info')
      } else {
        this.acertarStock1()
      }
    }
  }
}
</script>
