<template>
  <div class="clearfix margin-left-20 listaArtigosContactos" v-if="contactos">
    <div v-if="contactos[0]">
      <button class="btn btn-danger" @click="$emit('delContato')"><i class="glyphicon glyphicon-trash"></i></button>
      <button class="listaDelete btn btn-default" v-for="(dt, index) in contactos" :key="index" @click="$emit('delContato', dt, index)">{{dt.nome}}</button>
    </div>
      <!--v-tooltip.top-start="$t('apagar') + ' ' + dt.nome"-->
  </div>
</template>

<script>
export default {
  props: {
    contactos: { type: Array }
  }
}
</script>
