<template>
 <div style="z-index:1;" class="clearfix pr" v-if="lastpage > 1">
  <ul class="pagination pagination-sm margin-bottom-0 pull-right margin-right-0">
    <li v-if="pag > 3"><router-link to="1">1</router-link></li>
    <li class="disabled" v-if="pag > 4"><a>...</a></li>

    <li v-if="(pag - 2) > 0"><router-link :to="'' + (pag - 2)">{{pag - 2}}</router-link></li>
    <li v-if="(pag - 1) > 0"><router-link :to="'' + (pag - 1)">{{pag - 1}}</router-link></li>

    <li class="active"><a>{{pag}}</a></li>

    <li v-if="pag < lastpage"><router-link :to="'' + (pag + 1)">{{pag + 1}}</router-link></li>
    <li v-if="(pag + 1) < lastpage"><router-link :to="'' + (pag + 2)">{{pag + 2}}</router-link></li>

    <li class="disabled" v-if="pag < (lastpage - 3)"><a>...</a></li>
    <li v-if="pag < (lastpage - 2)"><router-link :to="'' + lastpage">{{lastpage}}</router-link></li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'paginacaoPaginacao',
  props: {
    pag: { type: Number },
    lastpage: { type: Number }
  }
}
</script>
