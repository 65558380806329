import Vue from 'vue'
import { subCategorias, subCategoriasX, moveScroll } from './funcoes.js'

function ordenar (a) {
  return a.sort((a, b) => {
    let x = a.nome.toString().toLowerCase()
    let y = b.nome.toString().toLowerCase()
    return x < y ? -1 : (x > y ? 1 : 0)
  })
}

export const mutations = {
  SET_AFF (state, a) {
    state.aff = a
  },
  SET_IMPRIMIR (state, a) {
    state.modalImprimir = a
  },
  SMTP_ESTADO (state, a) {
    state.utilizador.empresa.smtp_privado = a
  },
  SET_LANG (state, lang) {
    Vue.i18n.set(lang)
    state.langData = {
      days: [Vue.i18n.translate('calendario.dom'), Vue.i18n.translate('calendario.seg'), Vue.i18n.translate('calendario.ter'), Vue.i18n.translate('calendario.qua'), Vue.i18n.translate('calendario.qui'), Vue.i18n.translate('calendario.sex'), Vue.i18n.translate('calendario.sab')],
      months: [Vue.i18n.translate('calendario.jan'), Vue.i18n.translate('calendario.fev'), Vue.i18n.translate('calendario.mar'), Vue.i18n.translate('calendario.abr'), Vue.i18n.translate('calendario.mai'), Vue.i18n.translate('calendario.jun'), Vue.i18n.translate('calendario.jul'), Vue.i18n.translate('calendario.ago'), Vue.i18n.translate('calendario.set'), Vue.i18n.translate('calendario.out'), Vue.i18n.translate('calendario.nov'), Vue.i18n.translate('calendario.dec')]
    }
  },
  SET_MODULO (state, data) {
    state.modulo = data
  },
  SET_INIT (state) {
    state.modulo = false
    state.limite = ''
    state.utilizador = {}
    state.utilizador1 = false
    state.utilizador2 = false
    state['tipos-documento'] = []
    state['tipos-documento1'] = false
    state['tipos-documento2'] = false
    state.idDocumento = []
    state.isencao = []
    state.isencao1 = false
    state.isencao2 = false
    state.series = []
    state.seriesAll = []
    state.series1 = false
    state.series2 = false
    state.seriestotal = 0
    state.lojas = []
    state.lojasAll = []
    state.lojas1 = false
    state.lojas2 = false
    state.lojastotal = 0
    state['metodos-pagamento'] = []
    state['metodos-pagamentoAll'] = []
    state['metodos-pagamento1'] = false
    state['metodos-pagamento2'] = false
    state['metodos-expedicao'] = []
    state['metodos-expedicaoALll'] = []
    state['metodos-expedicao1'] = false
    state['metodos-expedicao2'] = false
    state.impostos = []
    state.impostos1 = false
    state.impostos2 = false
    state['unidades-medida'] = []
    state['unidades-medida-id'] = []
    state['unidades-medidaAll'] = []
    state['unidades-medida1'] = false
    state['unidades-medida2'] = false
    state.viaturas = []
    state.viaturasAll = []
    state.viaturas1 = false
    state.viaturas2 = false
    state.tipos = []
    // state.tiposAll = []
    state.tipos1 = false
    state.tipos2 = false
    state.categorias = []
    // state.categoriasAll = []
    state.categorias1 = false
    state.categorias2 = false
    state.estados = []
    state.estadosAll = []
    state.estados1 = false
    state.estados2 = false
    state.pos = []
    state.posAll = []
    state.pos1 = false
    state.pos2 = false
    state.utilizadores = []
    state.utilizadoresAll = []
    state.utilizadores1 = false
    state.utilizadores2 = false
    state.postits = []
    state.postit = ''
    state.help = {}
    state.aviso = ''
    state.converter = {}
    state.converter_result1 = {}
    state.converterCompras = {}
    state.converterCompras1 = []
    state.menu = {}
    state.documents_bts = {}
    state.loja = ''
    state.MailDoc = {}
    state.MovStock = {}
    state.encomendarOrcamentar = ''
    state.ContatoEstatisticas = {}
    state.titulo = ''
    state.toast = {}
    state.menuConfiguracoes = ''
    state.scrollBottom = false
    state.stockNegativo = true
    state.upStockItens = 0
    state.menuLoja = false
    state.menuBandeiras = false
    state.menuFlutuante = false
    state.menuMobile = false
    state.modalSuporte = false
    state.corSimbLojas = {}
    state.corSimbEstados = {}
    state.itensMeta = []
    state.itensMetaOrder = ''
    state.itensMeta1 = false
    state.itensMeta2 = false
    state.contactosMeta = []
    state.contactosMetaOrder = ''
    state.contactosMeta1 = false
    state.contactosMeta2 = false
    state.documentosMeta = []
    state.documentosMetaOrder = ''
    state.documentosMeta1 = false
    state.documentosMeta2 = false
    state.modalAT = false
    state.modalATForm = {}
    state.reload = false
  },
  SET_RELOAD (state, data) {
    state.reload = data
  },
  SET_TOURSTEP (state, data) {
    state.tourStep = data
  },
  SET_SCROLLBOTTOM (state, data) {
    state.scrollBottom = data
  },
  SET_TOAST (state, data) {
    state.toast = data
  },
  // fazer pedidos http
  SET_HTTP (state, http) {
    state.http = http
  },
  // load nos pedidos de ajax
  SET_LOAD (state, estado) {
    state.load = estado || false
  },
  // estourou o site
  SET_ERROR (state, estado) {
    state.load = false
    state.error = estado
  },
  // pagina aonde me encontro
  SET_PAGE (state) {
    let a = window.location.pathname.split('/')
    state.page = a[1]
    state.page1 = a[2] || ''
    state.page2 = a[3] || ''
    state.page3 = a[4] || ''
    state.page4 = a[5] || ''
    state.Demo = window.location.hostname.indexOf('dev.bill.pt') !== -1 && state.utilizador.email !== 'nunob87@gmail.com' // && state.utilizador.email !== 'cyrusmoney@gmail.com'
  },
  SET_LIMITE (state, limite) {
    state.limite = limite
  },
  SET_HELP (state, a) {
    state.help = a
  },
  SET_AVISO (state, a) {
    state.aviso = a
  },
  SET_CONVERTER (state, a) {
    state.converter = a
  },
  SET_CONVERTER_RESULT1 (state, a) {
    state.converter_result1 = a
  },
  SET_CONVERTER_COMPRAS (state, a) {
    state.converterCompras = a
  },
  SET_CONVERTER_COMPRAS1 (state, a) {
    state.converterCompras1 = a
  },
  SET_MENU (state, a) {
    state.menu = a
  },
  SET_DOCUMENTO_BTS (state, a) {
    state.documents_bts = a
  },
  SET_MENUSTOCK (state, a) {
    state.menuStock = a
  },
  SET_ENCOMENDAR_ORCAMENTAR (state, a) {
    state.encomendarOrcamentar = a
  },

  // buscar informaçao necessaria para o site
  GET_INFO (state, campo) {
    // console.log(Vue.$t('cancelar'))
    // i18n('guardar')
    let i
    let u
    let x = ''
    let traduz = ''
    if (campo === 'lojas' && state.utilizador.lojas) {
      if (state.utilizador.lojas.length) {
        state[campo] = state.utilizador.lojas
        i = state[campo].length
        u = 0
        state.lojastotal = 0
        let cores = ['#1abc9c', '#f1c40f', '#e67e22', '#3498db', '#e74c3c', '#9b59b6', '#34495e', '#c0392b', '#2c3e50', '#2ecc71']
        while (--i >= 0) {
          // state[campo][i].cor = cores[u++]
          x = state[campo][i].nome.split(' ')
          if (x.length > 1) {
            state.corSimbLojas[state[campo][i].id] = { c: cores[u++], s: x[0].substring(0, 1) + x[x.length - 1].substring(0, 1) }
          } else {
            state.corSimbLojas[state[campo][i].id] = { c: cores[u++], s: state[campo][i].nome.substring(0, 2) }
          }
          if (state[campo][i].visivel) {
            ++state.lojastotal
          }
          traduz = 'lojas.' + state[campo][i].nome.replace(/\s/g, '_')
          if (Vue.i18n.keyExists(traduz)) {
            state[campo][i].nome = Vue.i18n.translate(traduz)
          }
          state[campo][i].label = state[campo][i].nome.toString()
        }
        state[campo + '2'] = true
        state[campo + '1'] = true
        state[campo + 'All'] = state[campo]
        state[campo] = state[campo + 'All'].filter(a => a.visivel)
      }
    }
    if (!state[campo + '1'] && !state[campo + '2']){
      let url = campo
      if (!state.modulo) {
        url += '/ver'
      }
      let data = {}
      switch (campo) {
        case 'utilizador':
        case 'utilizadorX':
          campo = 'utilizador'
          if (state.modulo) {
            url = 'user'
          } else {
            url = 'utilizador'
          }
          break
        case 'isencao':
          if (state.modulo) {
            url = 'motivos-isencao'
          } else {
            url = 'isencao/motivos'
          }
          break
        case 'template':
          url = 'email-template'
          if (!state.modulo) {
            url += '/ver'
          }
          break
        case 'tipos-documento':
          data = { tipo: ['faturas', 'guias', 'orcamentos', 'encomendas', 'compras', 'stock', 'outro'] }
          break
      }
      state[campo + '2'] = true
      let pedido
      if (state.modulo) {
        pedido = state.http.get('/' + url, data)
      } else {
        pedido = state.http.post('/' + url, data)
      }
      pedido.then((res) => {
        state[campo] = res.data
        state[campo + '1'] = true
        i = res.data.length
        let cores = ['#1abc9c', '#f1c40f', '#e67e22', '#3498db', '#e74c3c', '#9b59b6', '#34495e', '#c0392b', '#2c3e50', '#2ecc71']
        let ar = []
        let ar1 = []
        let a = state.lojas.length
        switch (campo) {
          case 'lojas':
            state.lojastotal = 0
            i = res.data.length
            state.lojastotal = 0
            u = 0
            state.corSimbLojas = {}
            while (--i >= 0) {
              // res.data[i].cor = cores[u++]
              x = res.data[i].nome.split(' ')
              if (x.length > 1) {
                state.corSimbLojas[res.data[i].id] = { c: cores[u++], s: x[0].substring(0, 1) + x[x.length - 1].substring(0, 1) }
              } else {
                state.corSimbLojas[res.data[i].id] = { c: cores[u++], s: res.data[i].nome.substring(0, 2) }
              }
              if (res.data[i].visivel) {
                ++state.lojastotal
              }
              traduz = 'lojas.' + res.data[i].nome.replace(/\s/g, '_')
              if (Vue.i18n.keyExists(traduz)) {
                res.data[i].nome = Vue.i18n.translate(traduz)
              }
              res.data[i].label = res.data[i].nome.toString()
            }
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'isencao':
            /*
            while (--i >= 0) {
              res.data[i].motivo = Vue.i18n.translate('isencoes.' + res.data[i].id)
            }
            */
            while (--i >= 0) {
              res.data[i].motivo = res.data[i].codigo + ' ' + res.data[i].motivo
            }
            res.data.sort((a, b) => {
              let x = a.motivo.toString().toLowerCase()
              let y = b.motivo.toString().toLowerCase()
              return x < y ? -1 : (x > y ? 1 : 0)
            })
            break
          case 'unidades-medida':
            while (--i >= 0) {
              if (Vue.i18n.keyExists('unidades.' + res.data[i].simbolo)) {
                res.data[i].nome = Vue.i18n.translate('unidades.' + res.data[i].simbolo)
              }
            }
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)

            state['unidades-medida-id'] = []
            state[campo + 'All'].map(a => {
              state['unidades-medida-id'][a.id] = a.simbolo
            })

            break
          case 'metodos-pagamento':
            while (--i >= 0) {
              if (Vue.i18n.keyExists('pagamento.' + res.data[i].simbolo)) {
                res.data[i].nome = Vue.i18n.translate('pagamento.' + res.data[i].simbolo)
                res.data[i].label = res.data[i].nome
              }
            }
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'series':
            state.seriestotal = 0
            while (--i >= 0) {
              res.data[i].label = res.data[i].nome.toString()
              if (res.data[i].visivel) {
                ++state.seriestotal
              }
            }
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'estados':
            state.corSimbEstados = {}
            while (--i >= 0) {
              x = res.data[i].nome.split(' ')
              if (x.length > 1) {
                state.corSimbEstados[res.data[i].id] = { c: res.data[i].cor, s: x[0].substring(0, 1) + x[x.length - 1].substring(0, 1) }
              } else {
                state.corSimbEstados[res.data[i].id] = { c: res.data[i].cor, s: res.data[i].nome.substring(0, 2) }
              }
            }
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'categorias':
          case 'tipos':
            res.data.sort((a, b) => {
              let x = a.nome.toString().toLowerCase()
              let y = b.nome.toString().toLowerCase()
              return x < y ? -1 : (x > y ? 1 : 0)
            })
            u = i
            i = 0
            while (i < u) {
              if (!res.data[i].categoria_pai_id) {
                res.data[i].categoria_pai_id = ''
                ar.push(res.data[i])
                ar = ar.concat(subCategorias(res.data[i].id, res.data))
              }
              ++i
            }
            i = 0
            state[campo] = ar
            u = ar.length
            while (i < u) {
              if (!ar[i].categoria_pai_id && ar[i].visivel) {
                ar1.push({ id: ar[i].id, label: ar[i].nome.toString() })
                x = subCategoriasX(ar.slice(i + 1), ar[i].id)
                if (x.length) {
                  ar1[ar1.length - 1].children = x
                }
              }
              ++i
            }
            state[campo + 'X'] = ar1
            break
          case 'impressoes':
            while (--i >= 0) {
              u = a
              while (--u >= 0) {
                if (state.lojas[u].id === res.data[i].loja_id) {
                  switch (res.data[i].tamanho) {
                    case 'A4':
                      state.lojas[u].a4 = true
                      break
                    case 'A5':
                      state.lojas[u].a5 = true
                      break
                    case 'talao':
                      state.lojas[u].termico = true
                      break
                  }
                }
              }
            }
            state[campo] = res.data
            break
          case 'pos':
            while (--i >= 0) {
              res.data[i].label = res.data[i].nome.toString()
            }
            state[campo + 'All'] = ordenar(res.data)
            state[campo] = state[campo + 'All'].filter(a => a.activo)
            break
          case 'tipos-documento':
            state.idDocumento = []
            state.tpDocumento = []
            res.data.map(a => {
              state.idDocumento[a.tipificacao] = a.id
              state.tpDocumento[a.id] = a.tipificacao
            })
            state[campo] = res.data
            break
          case 'impostos':
            state[campo].push({
              id: 0,
              visivel: true,
              valor: 0,
              nome: Vue.i18n.translate('isento_iva')
            })
            state[campo].sort((a, b) => {
              return b.valor - a.valor
            })
            break
          case 'viaturas':
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'metodos-expedicao':
            state[campo + 'All'] = ordenar(state[campo])
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
            break
          case 'utilizador':
          case 'template':
          case 'postits':
            state[campo] = res.data
            break
          case 'utilizadores':
            state['utilizadoresAll'] = res.data
            state['utilizadores'] = state['utilizadoresAll'] // .filter(a => a.activo)
            break
          default:
            state[campo + 'All'] = res.data
            state[campo] = state[campo + 'All'].filter(a => a.visivel)
        }
      }, (er) => {
        if (er.response.error === 'token_expired' || er.response.error === 'token_invalid') {
          window.location = 'https://' + window.location.hostname
        } else {
          state.error = true
        }
        state[campo + '1'] = false
      })
    }
  },
  SET_INFO (state, data) {
    let i
    let u
    let x
    let cores = ['#1abc9c', '#f1c40f', '#e67e22', '#3498db', '#e74c3c', '#9b59b6', '#34495e', '#c0392b', '#2c3e50', '#2ecc71']
    let ar = []
    let ar1 = []
    switch (data.url) {
      case 'user':
        state.utilizador = data.res
        break
      case 'utilizador':
        state.utilizador.empresa = data.res
        break
      case 'apiToken':
        state.utilizador.api_token = data.res
        break
      case 'plano':
        state.utilizador.plano = data.res
        break
      case 'categorias':
      case 'tipos':
        u = data.res.length
        i = 0
        while (i < u) {
          if (!data.res[i].categoria_pai_id) {
            data.res[i].categoria_pai_id = ''
            ar.push(data.res[i])
            ar = ar.concat(subCategorias(data.res[i].id, data.res))
          }
          ++i
        }
        state[data.url] = ar
        u = ar.length
        i = 0
        while (i < u) {
          if (!ar[i].categoria_pai_id && ar[i].visivel) {
            ar1.push({ id: ar[i].id, label: ar[i].nome.toString() })
            x = subCategoriasX(ar.slice(i + 1), ar[i].id)
            if (x.length) {
              ar1[ar1.length - 1].children = x
            }
          }
          ++i
        }
        state[data.url + 'X'] = ar1
        break
      case 'series':
        state[data.url + 'All'] = data.res
        i = data.res.length
        state.seriestotal = 0
        while (--i >= 0) {
          data.res[i].label = data.res[i].nome.toString()
          if (data.res[i].visivel) {
            ++state.seriestotal
          }
        }
        state[data.url] = state[data.url + 'All'].filter(a => a.visivel)
        break
      case 'lojas':
        i = data.res.length
        state.lojastotal = 0
        u = 0
        state.corSimbLojas = {}
        while (--i >= 0) {
          x = data.res[i].nome.split(' ')
          if (x.length > 1) {
            state.corSimbLojas[data.res[i].id] = { c: cores[u++], s: x[0].substring(0, 1) + x[x.length - 1].substring(0, 1) }
          } else {
            state.corSimbLojas[data.res[i].id] = { c: cores[u++], s: data.res[i].nome.substring(0, 2) }
          }
          if (data.res[i].visivel) {
            ++state.lojastotal
          }
          data.res[i].label = data.res[i].nome.toString()
        }
        state[data.url + 'All'] = data.res
        state[data.url] = state[data.url + 'All'].filter(a => a.visivel)
        break
      case 'estados':
        i = data.res.length
        while (--i >= 0) {
          x = data.res[i].nome.split(' ')
          if (x.length > 1) {
            state.corSimbEstados[data.res[i].id] = { c: data.res[i].cor, s: x[0].substring(0, 1) + x[x.length - 1].substring(0, 1) }
          } else {
            state.corSimbEstados[data.res[i].id] = { c: data.res[i].cor, s: data.res[i].nome.substring(0, 2) }
          }
        }
        state[data.url + 'All'] = data.res
        state[data.url] = state[data.url + 'All'].filter(a => a.visivel)
        break
      case 'pos':
        i = data.res.length
        while (--i >= 0) {
          data.res[i].label = data.res[i].nome.toString()
        }
        state[data.url + 'All'] = data.res
        state[data.url] = state[data.url + 'All'].filter(a => a.activo)
        break
      case 'postits':
      case 'template':
      case 'impressoes':
        state[data.url] = data.res
        break
      default:
        state[data.url + 'All'] = data.res
        state[data.url] = state[data.url + 'All'].filter(a => a.visivel)
    }
  },
  // ficha da empresa atualizar imposto e pagaments
  SET_INFO1 (state, data) {
    state[data.op] = state[data.op].map(a => {
      if (a.id === data.res.id) {
        return data.res
      }
      return a
    })
  },
  SET_INFO_RESET (state, campo) {
    state[campo + '2'] = false
    state[campo + '1'] = false
  },
  // buscar informaçao meta dos itens e contactos
  GET_INFO_META (state, data) {
    if (!state[data + 'Meta2']) {
      state[data + 'Meta2'] = true
      let pedido
      if (state.modulo) {
        pedido = state.http.get('/' + (data === 'contactos' ? 'contatos' : (data === 'itens' ? 'items' : data)) + '/meta/estrutura')
      } else {
        pedido = state.http.post('/' + (data === 'contactos' ? 'contatos' : data) + '/meta/estrutrura')
      }
      pedido.then((res) => {
        let i = res.data.length
        state[data + 'MetaOrder'] = ''
        let indexOrder
        let ordem = []
        let ar = []
        while (--i >= 0) {
          if (res.data[i].meta_key === 'order') {
            state[data + 'MetaOrder'] = res.data[i]
            ordem = res.data[i].meta_value.split('|')
            indexOrder = i
            continue
          }
          res.data[i].option = {}
          if (res.data[i].meta_value.indexOf('|') !== -1) {
            let a = res.data[i].meta_value.split('|')
            res.data[i].meta_value = a.shift()
            res.data[i].option = { total: a.length, valores: a }
          }
        }
        if (state[data + 'MetaOrder']) {
          res.data.splice(indexOrder, 1)
          let u = ordem.length
          while (--u >= 0) {
            i = res.data.length
            while (--i >= 0) {
              if (parseInt(ordem[u]) === parseInt(res.data[i].id)) {
                ar.unshift(res.data[i])
                res.data.splice(i, 1)
                break
              }
            }
          }
        }
        state[data + 'Meta'] = ar.concat(res.data)
        state[data + 'Meta1'] = true
      }, (er) => {
        if (er.response.error === 'token_expired' || er.response.error === 'token_invalid') {
          window.location = 'http://' + window.location.hostname
        } else {
          state.error = true
        }
        state[data + 'Meta1'] = false
      })
    }
  },
  SET_INFO_META (state, data) {
    state[data.campo + 'Meta'] = data.res
  },
  SET_PLANO (state, data) {
    state.utilizador.plano = data
  },
  SET_LOJA (state, data) {
    state.loja = data
    let x = state.lojas.find(a => parseInt(a.id) === parseInt(state.loja))
    if (x) {
      state.stockNegativo = state.lojas.find(a => parseInt(a.id) === parseInt(state.loja)).stock_negativo
    } else {
      state.stockNegativo = true
    }
    /*
    let i = state.lojas.length
    while (--i >= 0) {
      if (parseInt(state.lojas[i].id) === parseInt(state.loja)) {
        state.stockNegativo = state.lojas[i].stock_negativo
        break
      }
    }
    */
  },
  SET_MAILDOC (state, data) {
    state.MailDoc = data
  },
  SET_MOVSTOCK (state, data) {
    state.MovStock = data
  },
  SET_TITULO (state, data) {
    state.titulo = data
  },
  SET_MENUCONFIGURACOES (state, data) {
    state.menuConfiguracoes = data
  },
  UP_STOCK_ITENS (state, data) {
    state.upStockItens = data
  },
  DOWNLOAD (state, data) {
    let x = data.name.split('.')
    let type = x[x.length - 1]
    state.load = true
    state.http.post(data.url, data.data, { responseType: 'arraybuffer' }).then((res) => {
      let filename = data.name
      let element = document.createElement('a')
      let blob = new Blob(['\ufeff', res.data], { encoding: 'UTF-8', 'type': state.mimeTypes[type] + ';charset=UTF-8' })
      element.setAttribute('href', window.URL.createObjectURL(blob))
      element.setAttribute('download', filename)
      element.dataset.downloadurl = [state.mimeTypes[type], element.download, element.href].join(':')
      element.draggable = true
      element.classList.add('dragout')
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      state.load = false
    }, () => {
      state.load = false
      window.alert(Vue.i18n.translate('errodownload'))
    })
  },
  FOCO (state, dta) {
    let textarea = true
    if (document.activeElement.nodeName === 'TEXTAREA') {
      let thisKeypressTime = new Date()
      if (thisKeypressTime - state.lastKeypressTime <= 500) {
        // aqui quer dizer que pode fazer o proximo ofoc
        document.activeElement.value = document.activeElement.value.trim()
      } else {
        document.activeElement.value += '\n'
        textarea = false
        state.lastKeypressTime = thisKeypressTime
      }
    }
    if (textarea) {
      window.setTimeout(function (dta) {
        let next = document.querySelectorAll('.foco:enabled, div.foco')
        let u = 0
        if (Array.isArray(dta)) {
          // inicio
        } else {
          // let i = Array.prototype.indexOf.call(next, document.activeElement)
          // console.log(i)
          // let next = document.getElementsByClassName('foco')
          let i = next.length - 1
          while (u <= i) {
            if (document.activeElement.isEqualNode(next[u]) || document.activeElement.isSameNode(next[u].getElementsByTagName('input')[0])) {
              break
            }
            ++u
          }
          ++u
          // --i
          if (u > i) {
            u = 0
          }
        }
        if (next[u]) {
          if (next[u].getElementsByTagName('input')[0]) {
            next[u].getElementsByTagName('input')[0].focus()
          } else {
            next[u].focus()
          }
        }
      }, 200, dta)
    }
    /*
    let data = dta
    let inicio = false
    if (Array.isArray(dta)) {
      data = dta[0]
      inicio = true // permite que o foco volte ao inicio quando chega ao ultimo campo
    }
    data.stopPropagation()
    data.preventDefault()
    let id = data.target.id
    let name = data.target.name
    // tou num trueselect, vou buscar o id
    if (data.target.className === 'vue-treeselect__input') {
      let xx = data.target.parentElement
      while (!xx.getElementsByClassName('foco')[0]) {
        xx = xx.parentElement
      }
      id = xx.id + '1'
      name = xx.id + '1'
    }
    let a = data.target
    let textarea = true
    if (a.nodeName === 'TEXTAREA') {
      let thisKeypressTime = new Date()
      if (thisKeypressTime - state.lastKeypressTime <= 500) {
        // aqui quer dizer que pode fazer o proximo ofoc
      } else {
        textarea = false
        state.lastKeypressTime = thisKeypressTime
      }
    }
    if (textarea) {
      // caso tenha um elemento ao lado do que dei enter
      let b
      let u = 0
      let elementNext = false // para saber se ja passou aquele elemento em que tava
      while (1) {
        u = 0
        a = a.parentElement
        if (a.nodeName === 'BODY') {
          break
        }
        elementNext = false
        while (1) {
          b = a.getElementsByClassName('foco')[u++]
          if (!b) {
            break
          }
          // ja tou no elemento que estava antes do enter, isto por causa de voltar aos elementos pais
          if (b.id === id) {
            elementNext = true
            continue
          }
          // num treeselect nao tem id mas tem name
          if ((b.id !== id && b.name !== name && name || b.id !== id && !name) && (elementNext || inicio) && !b.disabled) {
            break
          }
        }
        if (b) {
          console.log(b.id)
          // num treeselect nao tem id mas tem name
          if (b.getElementsByClassName('vue-treeselect__input')[0]) {
            b.getElementsByClassName('vue-treeselect__input')[0].focus()
          } else {
            b.focus()
          }
          break
        }
      }
    }
    */
  },
  SET_MENU_LOJA (state, dta) {
    state.menuLoja = dta
  },
  SET_MENU_BANDEIRAS (state, dta) {
    state.menuBandeiras = dta
  },
  SET_MENU_FLUTUANTE (state, dta) {
    state.menuFlutuante = dta
  },
  SET_MENU_MOBILE (state, dta) {
    state.menuMobile = dta
  },
  SET_SUPORTE_MODAL (state, dta) {
    state.modalSuporte = dta
  },
  SET_SUPORTE_AT_MODAL (state, dta) {
    state.modalAT = dta.ok
    state.modalATForm = dta.res
  },
  SET_POSITION_TABLE_FIXED () {
    moveScroll()
  },
  SET_FULLSCREEN () {
    if (window.innerHeight !== screen.height) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        document.documentElement.webkitRequestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
        document.documentElement.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen()
      }
    }
  },
  SET_SCROLL (state, a) {
    let element = document.getElementById(a || 'itens')
    let y = 0
    while (element) {
      y += (element.offsetTop - element.scrollTop + element.clientTop)
      element = element.offsetParent
    }
    window.scrollTo(0, y - window.innerHeight / 2)
  },
  SET_POSTIT (state, a) {
    window.scrollTo(0, 0)
    state.postit = a
  },
  SET_APITOKEN (state, token) {
    state.apiToken = token
  }
}
