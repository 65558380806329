<template>
  <div class="modal-mask" v-if="$store.state.postit" transition="modal">
    <div class="modalScrollMobile">
      <div class="modal-wrapper">
        <div class="modal-fundo"></div>
        <div class="modal-container padding-0 modal-800" v-if="$store.state.postit.tipo == 'add'">
          <div class="modal-header">
            <h3 class="modal-title">{{l('titulo_adicionar')}}<button @click="SET_POSTIT('')" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15">
            <div>
              <ul class="margin-0 padding-0 pull-left">
                <li class="vc-compact-color-item" style="background: #cbf0f8" @click="form.cor = '#cbf0f8'"><div v-if="form.cor == '#cbf0f8'" class="vc-compact-dot"></div></li>
                <li class="vc-compact-color-item" style="background: #f28c82" @click="form.cor = '#f28c82'"><div v-if="form.cor == '#f28c82'" class="vc-compact-dot"></div></li>
                <li class="vc-compact-color-item" style="background: #fff375" @click="form.cor = '#fff375'"><div v-if="form.cor == '#fff375'" class="vc-compact-dot"></div></li>
                <li class="vc-compact-color-item" style="background: #ccff91" @click="form.cor = '#ccff91'"><div v-if="form.cor == '#ccff91'" class="vc-compact-dot"></div></li>
                <li class="vc-compact-color-item" style="background: #aecbfa" @click="form.cor = '#aecbfa'"><div v-if="form.cor == '#aecbfa'" class="vc-compact-dot"></div></li>
                <li class="vc-compact-color-item" style="background: #fdcee7" @click="form.cor = '#fdcee7'"><div v-if="form.cor == '#fdcee7'" class="vc-compact-dot"></div></li>
              </ul>
              <div class="pull-right" style="margin-right:-10px;margin-top:-18px;">
                <input class="foco" type="checkbox" v-model="form.destacado" id="destacado" @keydown.enter.prevent="FOCO">
                <label for="destacado">{{l('destacado')}}</label>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="form-group form-material margin-top-5" v-if="users[0]" id="utilizadores">
              <div class="padding-left-0 input-group pr foco" id="utilizadores1" name="utilizadores1">
                <span class="input-group-addon padding-0">
                  {{$t('utilizadores')}}
                </span>
                <treeselect-treeselect :clearable="true" :searchable="true" :multiple="true" :options="users" placeholder="" v-model="form.users1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" no-results-text="" no-options-text="" :default-expand-level="1" />
              </div>
              <p class="padding-left-0 ajuda margin-top-5 text-justify col-md-12" v-html="l('ajuda_users')"></p>
            </div>
            <div class="form-group">
              <textarea rows="7" autocomplete="off" required class="form-control foco" v-model="form.nota" maxlength="800" id="notaaaa" :placeholder="l('nota')" v-texto></textarea>
            </div>
            <div class="clearfix"></div>
            <button class="btn btn-default btn-pure pull-left" type="button" @click="SET_POSTIT('')">{{$t('cancelar')}}</button>
            <button v-if="form.nota.length > 3" class="btn btn-primary pull-right" type="button" @click="submit()">{{$t('guardar')}}</button>
            <div class="clearfix"></div>
          </div>
        </div>
        <!--editar postit-->
        <div class="modal-container modal-800 padding-0" v-else>
          <div class="modal-header">
            <h3 class="modal-title">
              {{l('titulo_ver')}}
              <button v-tooltip.top-start="l('apagar_user')" v-if="$store.state.postit.res.user_id == $store.state.postit.res.created_by.id" class="btn btn-danger pull-right margin-left-15" @click="apagar(1)"><i class="glyphicon glyphicon-trash"></i><i class="glyphicon glyphicon-user"></i></button>
              <button class="btn btn-danger pull-right" @click="apagar(0)"><i class="glyphicon glyphicon-trash"></i></button>
            </h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15">
            <div class="label padding-20" :style="'font-weight: 400;color: rgb(51, 51, 51);display: block;text-align: left;font-size: 16px;font-family: Roboto, Arial, sans-serif;letter-spacing: 0.1px;line-height: 24px;white-space: pre-line;word-break: break-all;background:' + $store.state.postit.res.cor">{{$store.state.postit.res.nota}}</div>
            <div class="clearfix"></div>
            <p class="margin-top-5">
              {{$store.state.postit.res.created_by.nome}}
              <small class="text-muted pull-right"><i class="glyphicon glyphicon-time"></i> {{$store.state.postit.res.created_at | data}} {{$store.state.postit.res.created_at.substring(11, 16)}}</small>
            </p>
            <div class="clearfix"></div>
            <button class="btn btn-primary pull-right" type="button" @click="SET_POSTIT('')">{{$t('sair')}}</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { postit, postitCriar, postitDel } from './../../../resources/fn'

export default {
  name: 'modalPostits',
  data () {
    return {
      postits: [],
      form: {
        nota: '',
        cor: '#fff375',
        users: [],
        users1: [],
        destacado: false
      },
      users: []
    }
  },
  watch: {
    '$store.state.postit' (val) {
      if (val) {
        this.form = {
          nota: '',
          cor: '#fff375',
          users: [],
          destacado: false
        }
      }
    }
  },
  mounted () {
    this.GET_INFO('utilizadores')
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_POSTITS',
      'SET_POSTIT',
      'SET_AVISO',
      'SET_TOAST',
      'GET_INFO',
      'SET_INFO',
      'SET_LOAD'
    ]),
    l (a) {
      return this.$t('postits.' + a)
    },
    init () {
      if (this.$store.state.utilizadores1) {
        this.users = []
        let i = this.$store.state.utilizadores.length
        if (i > 1) {
          let ar = []
          while (--i >= 0) {
            if (this.$store.state.utilizadores[i].id !== this.$store.state.utilizador.id) {
              ar.unshift({ id: this.$store.state.utilizadores[i].id, label: this.$store.state.utilizadores[i].nome.toString() })
            }
          }
          i = ar.length
          if (i === 1) {
            this.users = ar
          }
          if (i > 1) {
            this.users = [{ id: 'users', label: this.$t('todos'), children: ar }]
          }
        }
        this.postitsFN()
      } else {
        this._.delay(this.init, 150)
      }
    },
    postitsFN () {
      postit(this.axios, this.$store.state.modulo).then((res) => {
        this.postits = res.data
        this.SET_INFO({ url: 'postits', res: res.data })
      }, () => {})
      this._.delay(this.postitsFN, 600000)
    },
    submit () {
      this.SET_LOAD(true)
      if (this.form.users1) {
        if (this.form.users1[0]) {
          if (this.form.users1[0] === 'users') {
            this.form.users = this.users[0].children.map(a => a.id)
          } else {
            this.form.users = this.form.users1
          }
        }
      }
      postitCriar(this.axios, this.form, this.$store.state.modulo).then((res) => {
        this.SET_INFO({ url: 'postits', res: res.data })
        this.SET_POSTIT({ tipo: 'edit', res: res.data.reduce((a, b) => b.id > a.id ? b : a) })
        this.SET_TOAST({ msg: this.l('sucessoCriado') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    },
    apagar (todos) {
      this.SET_LOAD(true)
      postitDel(this.axios, { id: this.$store.state.postit.res.id, apagar_para_todos: todos }, this.$store.state.modulo).then((res) => {
        this.SET_POSTIT('')
        this.SET_INFO({ url: 'postits', res: res.data })
        this.SET_TOAST({ msg: this.l('sucessoApagar') })
      }, (er) => {
        this.SET_AVISO(er.response)
      })
    }
  }
}
</script>
