<template>
  <div>
    <criar-criar :form1.sync="form1"></criar-criar>
  </div>
</template>

<script>
import criarCriar from '../tpl/documentos/criar.vue'

export default {
  name: 'pageDocumentosCriar',
  components: { criarCriar },
  data () {
    return {
      form1: {}
    }
  }
}
</script>
