<template>
  <ul class="nav nav-tabs nav-tabs-line tabslogin margin-bottom-20">
    <li v-for="(dt, index) in ar" :key="index" :class="{active: pag === dt.id || pag === dt.comparacao}" :id="dt.id"><router-link :to="dt.link" class="text-uppercase">{{l(dt.text)}}</router-link></li>
    <li class="nav-tabs-autoline menulinha" :style="tabops"></li>
  </ul>
</template>

<script>
import { width } from '../../filters/index'

export default {
  name: 'tabs-tabs',
  data () {
    return {
      tabops: '',
      pag: ''
    }
  },
  props: {
    pagina: { default: '', type: String },
    page: { type: String },
    ar: { type: Array },
    comparacao: { type: String }
  },
  watch: {
    '$route' () {
      this.selOp()
    }
  },
  created () {
    this._.delay(this.selOp, 180)
  },
  methods: {
    l (a) {
      return this.$t(this.pagina + a)
    },
    selOp () {
      if (this.page) {
        this.pag = this.page
      } else {
        this.pag = window.location.pathname.split('/')[1]
      }
      let i = this.ar.length
      while (--i >= 0) {
        if (this.ar[i].id === this.pag || this.ar[i].comparacao === this.pag) {
          if (i === 0) {
            this.tabops = 'left: 0px; width: ' + width(this.ar[0].id) + 'px'
          }
          if (i === 1) {
            this.tabops = 'left: ' + width(this.ar[0].id) + 'px; width: ' + width(this.ar[1].id) + 'px'
          }
          if (i === 2) {
            this.tabops = 'left: ' + (width(this.ar[0].id) + width(this.ar[1].id)) + 'px; width: ' + width(this.ar[2].id) + 'px'
          }
        }
      }
    }
  }
}
</script>
