<template>
  <div v-if="ok" :class="'alert alert-danger alert-dismissible text-justify ' + (margin ? 'margin-vertical-50':'margin-vertical-20')">
    {{$t(text)}}
  </div>
</template>

<script>
export default {
  props: {
    text: { default: 'permissoes_acesso', type: String },
    ok: { default: false, type: Boolean },
    margin: { default: false, type: Boolean }
  }
}
</script>
