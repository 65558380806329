import Vue from 'vue'

Vue.directive('decimal', {
  bind: function (e) {
    /*
    if (!isNaN(e.value)) {
      if (e.value.indexOf('.') !== -1) {
        let a = e.value.toString().split('.')
        if (a[1].length < 2) {
          e.value += '0'
        }
      } else {
        e.value += '00'
      }
    }
    */
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    if (a.indexOf('.') !== -1) {
      if ((a.split('.')[1].length) > 6) {
        let exp = -6
        a = a.toString().split('e')
        a = Math['round'](+(a[0] + 'e' + (a[1] ? (+a[1] - exp) : -exp)))
        a = a.toString().split('e')
        a = +(a[0] + 'e' + (a[1] ? (+a[1] + exp) : exp))
      }
      e.value = a
    } else {
      e.value = a
    } 
  }
  /*
  componentUpdated: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
  */
})
/*
Vue.directive('money', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    if (a.indexOf('.') > 0 && (a.split('.')[1] || '').length) {
      let exp = -4
      a = a.toString().split('e')
      a = Math['round'](+(a[0] + 'e' + (a[1] ? (+a[1] - exp) : -exp)))
      a = a.toString().split('e')
      e.value = +(a[0] + 'e' + (a[1] ? (+a[1] + exp) : exp))
    } else {
      e.value = a
    }
  }
})
*/

Vue.directive('decimalx', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0 || parseFloat(a.target.value) === 1) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = 1
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^[0-9.*]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    if (a.indexOf('.') !== -1 && a.indexOf('*') === - 1) {
      if ((a.split('.')[1].length) > 6) {
        let exp = -6
        a = a.toString().split('e')
        a = Math['round'](+(a[0] + 'e' + (a[1] ? (+a[1] - exp) : -exp)))
        a = a.toString().split('e')
        a = +(a[0] + 'e' + (a[1] ? (+a[1] + exp) : exp))
      }
      e.value = a
    } else {
      e.value = a
    } 
  }
})
Vue.directive('decimal0', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value >= 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('decimal00', {
  bind: function (e) {
    e.addEventListener('focus', function () {
    })
    e.addEventListener('blur', function (a) {
      if (a.target.value >= 0) {
        a.target.value = parseFloat(a.target.value)
      } else {
        a.target.value = ''
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('numerico', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseInt(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseInt(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    e.value = e.value.replace(/\D/g, '')
  }
})
Vue.directive('numerico0', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseInt(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = 0
      } else {
        a.target.value = parseInt(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    e.value = e.value.replace(/\D/g, '')
  }
})
Vue.directive('numerico3', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseInt(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = 0
      } else {
        a.target.value = parseInt(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = parseInt(e.value)
    if (!(a === 1 || a === 2 || a === 3)) {
      e.value = ''
    } else {
      e.value = a
    }
  }
})
Vue.directive('percentagem', {
  bind: function (e) {
    e.addEventListener('focus', function (a) {
      if (parseFloat(a.target.value) === 0) {
        a.target.value = ''
        e.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
    e.addEventListener('blur', function (a) {
      if (!(a.target.value > 0)) {
        a.target.value = ''
      } else {
        a.target.value = parseFloat(a.target.value)
      }
      e.dispatchEvent(new Event('input', { bubbles: true }))
    })
  },
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d+\.?\d*|\.\d+)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    if (a > 100) {
      e.value = 100
    } else {
      e.value = a
    }
  }
})
Vue.directive('alfanumerico', {
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^[A-Za-z0-9]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('texto', {
  update: function (e) {
    let a = e.value
    let reg = /^[0-9A-Z a-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ~^´`¨°"\s|.ºª,\\!#$%:;|/&/()=?*€@*+_'<>-]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('postal', {
  update: function (e) {
    let a = e.value
    let reg = /^[0-9A-Z a-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ~^´`¨°"\s|.ºª,\\!#$%:;|/&/()=?*€@*+_'<>-]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})

Vue.directive('ip', {
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^(\d{0,3}|\d{1,3}\.\d{0,3}|\d{1,3}\.\d{1,3}\.\d{0,3}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{0,3})$/
    // /^[0-9.]+$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('cae', {
  update: function (e, b, c, d) {
    let a = e.value
    if (a) {
      if (d.data.domProps.value && c.data.domProps.value) {
        if (d.data.domProps.value.length > c.data.domProps.value.length) {
          // apaguei um caracter
          if (d.data.domProps.value.toString().charAt(c.data.domProps.value.length) === ' ') {
            a = a.substring(0, c.data.domProps.value.length - 1)
          }
        }
      }
      let reg = /^(\d{0,5}|([\d]{5}\s($|\d{0,5}))*)$/
      while (!reg.test(a) && a) {
        a = a.substring(0, a.length - 1)
      }
      let x = a.split(' ')
      if (x[x.length - 1].length === 5 || a.length === 5) {
        e.value = a + ' '
      } else {
        e.value = a
      }
    }
  }
})
Vue.directive('mail', {
  update: function (e) {
    let a = e.value
    a = a.replace(',', '.')
    let reg = /^([A-Za-z0-9_.-]*|[A-Za-z0-9_.-]*@|[A-Za-z0-9_.-]*@[A-Za-z0-9_.-]*)$/
    while (!reg.test(a) && a) {
      a = a.substring(0, a.length - 1)
    }
    e.value = a
  }
})
Vue.directive('$model', {
  bind: function (el, binding, vnode) {
    el.oninput = () => (vnode.context[binding.expression] = el.value)
  }
})
Vue.directive('valor2', {
    bind: function (e, b) {
      e.textContent = new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale(), { minimumFractionDigits: 2 }).format(Math.round(b.arg * 100) / 100)
    },
    unbind: function (e) {
      e.textContent = ''
    },
    componentUpdated: function (e, b) {
      e.textContent = new Intl.NumberFormat(Vue.i18n.locale() + '-' + Vue.i18n.locale(), { minimumFractionDigits: 2 }).format(Math.round(b.arg * 100) / 100)
    }
  })
