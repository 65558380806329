// const URL = 'https://dev.bill.pt/'
const URL = ''
const URL1 = 'https://app.bill.pt/api/1.0/'

function urlencode (str) {
  str = (str + '').toString()
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+')
}

var apiParamsSerializer = function (dta, prefixo, separador) {
  let value
  let key
  let tmp = []

  let httpGetData = function (key, val, separador) {
    let k
    let tmp = []
    if (val === true) {
      val = '1'
    } else if (val === false) {
      val = '0'
    }
    if (val && typeof val === 'object') {
      for (k in val) {
        if (val[k] || k === 'visivel') {
          tmp.push(httpGetData(key + '[' + k + ']', val[k], separador))
        }
      }
      return tmp.join(separador)
    } else if (typeof val !== 'function') {
      return urlencode(key) + '=' + urlencode(val)
    } else if (typeof val === 'function') {
      return ''
    } else {
      throw new Error('There was an error processing for http_build_query().')
    }
  }
  if (!separador) {
    separador = '&'
  }
  for (key in dta) {
    value = dta[key]
    if (prefixo && !isNaN(key)) {
      key = String(prefixo) + key
    }
    tmp.push(httpGetData(key, value, separador))
  }
  return tmp.join(separador)
}

export function ativarconta (f, id, token) {
  return f.post(URL + 'utilizador/' + id + '/' + token)
}
export function pedirmail (f) {
  return f.post(URL + 'utilizador/pedir-email-activacao', {})
}
export function login (f, data) {
  return f.post(URL + 'auth/login', data)
}
export function register (f, data) {
  return f.post(URL + 'auth/register', data)
}
export function forgotpassword (f, data) {
  return f.post(URL + 'password/email', data)
  // return f({method: 'post', baseURL: URL, url: 'password/email', data: data, params: data, crossdomain: true, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}})
}
export function updatepassword (f, data) {
  return f.post(URL + 'password/reset', data)
}

export function utilizador (f) {
  return f.post(URL + 'utilizador', {})
}
export function utilizadorUp (f, data) {
  return f.post(URL + 'utilizador/update', data)
}
export function utilizadorGerarPassword (f, data) {
  return f.post(URL + 'utilizador/gerar-password', data)
}
export function utilizadorCriar (f, data) {
  return f.post(URL + 'utilizador/criar', data)
}
export function utilizadorApagar (f, data) {
  return f.post(URL + 'utilizadores/apagar', data)
}
export function permissoesUp (f, data) {
  return f.post(URL + 'permissoes/update', data)
}
export function senhaUpdate (f, data) {
  return f.post(URL + 'utilizador/trocar-minha-password', data)
}
export function testeAT (f, data) {
  return f.post(URL + 'teste-dados-at', data)
}
export function comunicacoesAT (f, data, pag) {
  return f.post(URL + 'registo-comunicacoes?page=' + (pag || 1), data)
}
export function serieTeste (f) {
  return f.post(URL + 'series/testar-comunicacao')
}
export function serieRegistar (f, id) {
  return f.post(URL + 'series/registar/' + id)
}

export function empresaUp (f, data) {
  return f.post(URL + 'empresa/update', data)
}
export function empresaUsers (f, data) {
  return f.post(URL + 'empresa/utilizadores', data)
}
export function empresaUsersId (f, id) {
  return f.post(URL + 'utilizador/ver/' + id, {})
}
export function empresaCertificacao (f, data) {
  return f.post(URL + 'empresa/certificacao', data)
}
export function validarNif (f, data, pais, nif) {
  return f.post(URL + 'verifica/' + pais + '/' + encodeURIComponent(nif), data)
}

export function contatos (f, data, pag, modulo) {
  if (modulo) {
    return f.get(URL1 + 'contatos?page=' + (pag || 1), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'contatos/ver?page=' + (pag || 1), data)
}
export function contatosId (f, data, id, modulo) {
  if (modulo) {
    return f.get(URL1 + 'contatos/' + id)
  }
  return f.post(URL + 'contatos/ver/' + id, data)
}
export function contatosCriar (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'contatos', data)
  }
  return f.post(URL + 'contatos/criar', data)
}
export function contatosUp (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'contatos/' + data.id, data)
  }
  return f.post(URL + 'contatos/update', data)
}
export function contatosDel (f, data, modulo) {
  if (modulo) {
    return f.delete(URL1 + 'contatos/' + data.id)
  }
  return f.post(URL + 'contatos/apagar', data)
}

export function itens (f, data, page, modulo) {
  if (modulo) {
    return f.get(URL1 + 'items?page=' + (page || 1), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'itens/ver?page=' + (page || 1), data)
}
export function itensId (f, id, modulo) {
  if (modulo) {
    return f.get(URL1 + 'items/' + id)
  }
  return f.post(URL + 'itens/ver/' + id)
}
export function itensCriar (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'items', data)
  }
  return f.post(URL + 'itens/criar', data)
}
export function itensUp (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'items/' + data.id, data)
  }
  return f.post(URL + 'itens/update', data)
}
export function itensDel (f, data, modulo) {
  if (modulo) {
    return f.delete(URL1 + 'items/' + data.id)
  }
  return f.post(URL + 'itens/apagar', data)
}
export function upItensIva (f, data) {
  return f.post(URL + 'itens/actualizar-ivas', data)
}

export function duplicarArtigo (f, data) {
  return f.post(URL + 'itens/duplicar', data)
}

export function importar (f, cat, tipo, data) {
  return f.post(URL + 'importar/' + cat + '/' + tipo, data)
}
export function importarDocumentos (f, data, loja) {
  return f.post(URL + 'importar/documentos/saft?faturas=1&guias=0&orcamentos_encomendas=0&recibos=1&loja_id=' + loja, data)
}
export function importarDocumentosVer (f) {
  return f.post(URL + 'importar/documentos/saft/ver')
}
export function saftAT (f, data) {
  return f.post(URL + 'saft/enviar', data)
}

export function importarDocumentosDel (f, id) {
  return f.post(URL + 'documentos/importados/apagar', { id: id })
}
export function importarContactos (f, data) {
  return f.post(URL + 'contatos/criar/em-serie', data)
}
export function importarContactosDelID (f, id) {
  return f.post(URL + 'contatos/importados/limpar/' + id, {})
}
export function importarContactosDel (f) {
  return f.post(URL + 'contatos/importados/limpar', {})
}
export function importarItens (f, data) {
  return f.post(URL + 'itens/criar/em-serie', data)
}
export function importarItensDelID (f, id) {
  return f.post(URL + 'itens/importados/limpar/' + id, {})
}
export function importarItensDel (f) {
  return f.post(URL + 'itens/importados/limpar', {})
}
export function importadosPendentesItens (f, data, pag) {
  return f.post(URL + 'itens/importados/pendentes?page=' + (pag || 1), data)
}
export function importadosPendentesContatos (f, data, pag) {
  return f.post(URL + 'contatos/importados/pendentes?page=' + (pag || 1), data)
}
export function importadosNotaLiquidacao (f, data) {
  return f.post(URL + 'documentos/importados/nota-liquidacao', data)
}

export function saftEmail (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'saft/email', data)
  }
  return f.post(URL + 'saft/email', data)
}

export function lojasVer (f, data) {
  return f.post(URL + 'lojas/ver', data)
}
export function lojasCriar (f, data) {
  return f.post(URL + 'lojas/criar', data)
}
export function lojasUsers (f, data) {
  return f.post(URL + 'lojas/utilizadores/ver', data)
}
export function lojasUsersCriar (f, data) {
  return f.post(URL + 'lojas/utilizadores/adicionar', data)
}
export function lojasUsersDel (f, data) {
  return f.post(URL + 'lojas/utilizadores/remover', data)
}
export function lojasContatos (f, data) {
  return f.post(URL + 'lojas/contatos/ver', data)
}
export function lojasContatosCriar (f, data) {
  return f.post(URL + 'lojas/contatos/adicionar', data)
}
export function lojasContatosDel (f, data) {
  return f.post(URL + 'lojas/contatos/remover', data)
}
export function lojasItens (f, data) {
  return f.post(URL + 'lojas/itens/ver', data)
}
export function lojasItensCriar (f, data) {
  return f.post(URL + 'lojas/itens/adicionar', data)
}
export function lojasItensDel (f, data) {
  return f.post(URL + 'lojas/itens/remover', data)
}

export function lojasItensAtr (f, data) {
  return f.post(URL + 'itens/atributo/criar', data)
}
export function lojasItensAtrDel (f, data) {
  return f.post(URL + 'itens/atributo/apagar', data)
}
export function lojasItensVariacaoCriar (f, data) {
  return f.post(URL + 'itens/variacao/criar', data)
}
export function lojasItensVariacaoUpdate (f, data) {
  return f.post(URL + 'itens/variacao/update', data)
}
export function lojasItensVariacaoDel (f, data) {
  return f.post(URL + 'itens/variacao/apagar', data)
}

export function documentos (f, data, pag, modulo) {
  if (modulo) {
    data.embed = ['contato', 'loja']
    return f.get(URL1 + 'documentos?page=' + (pag || 1), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'documentos/ver?page=' + (pag || 1), data)
}

export function docsImportados (f, data, pag) {
  return f.post(URL + 'documentos/importados?page=' + (pag || 1), data)
}
export function docsImportadosDivida (f, data) {
  return f.post(URL + 'documentos/importados/divida', data)
}
export function docsImportarDividaCSV (f, data) {
  return f.post(URL + 'documentos/importados/importar', data)
}

export function servePagamento (f, data) {
  return f.post(URL + 'pagamentos/serve-pagamento', data)
}
export function ignoraPagamento (f, data) {
  return f.post(URL + 'pagamentos/ignorado', data)
}

export function documentosAT (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos/adicionar/codigo-at', data)
  }
  return f.post(URL + 'documentos/adicionar/codigo-at', data)
}
export function saldoinicial (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos/saldo-inicial', data)
  }
  return f.post(URL + 'documentos/saldo-inicial', data)
}
export function pagamentoDevolucao (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'pagamentos/devolucao', data)
  }
  return f.post(URL + 'pagamentos/devolucao', data)
}
export function ATAutomatic (f, tipo, id, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos/comunicar/guia/' + id, {})
  }
  return f.post(URL + 'comunicacoes/' + tipo + '/' + id, {})
}
export function enviarmail (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos/enviar-por-email', data)
  }
  return f.post(URL + 'documentos/enviar-por-email', data)
}
export function documentosId (f, id, modulo) {
  if (modulo) {
    return f.get(URL1 + 'documentos/' + id)
  }
  return f.post(URL + 'documentos/ver/' + id, {})
}
export function documentosIds (f, ids) {
  return f.post(URL + 'documentos/ver/multiplos', ids)
}
export function documentosCriar (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos', data)
  }
  return f.post(URL + 'documentos/criar', data)
}
export function documentosAnular (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'documentos', data)
  }
  return f.post(URL + 'documentos/anular', data)
}
export function documentosDel (f, data, modulo) {
  if (modulo) {
    return f.delete(URL1 + 'documentos/' + data.id)
  }
  return f.post(URL + 'documentos/apagar', data)
}
export function documentosUp (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'documentos/update', data)
  }
  return f.post(URL + 'documentos/update', data)
}
export function documentosNota (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'documentos/nota-documento', data)
  }
  return f.post(URL + 'documentos/nota-documento', data)
}

/*
export function documentosTipoCategoria (f, data, categoria) {
  return f.post(URL + 'tipos-documento/categoria/' + categoria, data)
}

export function isencaoMotivos (f, data) {
  return f.post(URL + 'isencao/motivos', {})
}
*/

export function movimentosPendentes (f, data, pag, modulo) {
  if (modulo) {
    return f.get(URL1 + 'movimentos-pendentes?page=' + (pag || 1), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'movimentos/pendentes?page=' + (pag || 1), data)
}
export function movimentosPendentesOrigem (f, origem, modulo) {
  if (modulo) {
    return f.get(URL1 + 'movimentos-pendentes/' + origem)
  }
  return f.post(URL + 'movimentos/pendentes/' + origem, {})
}
export function movimentosPendentesMultiplos (f, data, modulo) {
  if (modulo) {
    return f.get(URL1 + 'movimentos-pendentes/multiplos', { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'movimentos/pendentes/multiplos', data)
}

export function pagamentosAberto (f, data, modulo) {
  if (modulo) {
    return f.get(URL1 + 'pagamentos/em-aberto', { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'pagamentos/em-aberto', data)
}
export function pagamentosCriar (f, data, modulo) {
  if (modulo) {
    return f.post(URL + 'recibos', data)
  }
  return f.post(URL + 'pagamentos/criar', data)
}

/*
export function saft (f, data, tipo, modulo) {
  if (modulo) {
    return f.get(URL1 + 'gerar/saft/' + tipo, { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'gerar/saft/' + tipo, data)
}
*/

export function stock (f, data, pag, modulo) {
  if (modulo) {
    return f.get(URL1 + 'stock?page=' + (pag || 1), { params: data, paramsSerializer: apiParamsSerializer })
  } else {
    return f.post(URL + 'stock/ver?page=' + (pag || 1), data)
  }
}
export function stockMovimentos (f, data, pag, modulo) {
  if (modulo) {
    return f.get(URL1 + 'stock/movimentos?page=' + (pag || 1), data)
  }
  return f.post(URL + 'stock/movimentos?page=' + (pag || 1), data)
}
export function maisVendidos (f, data, modulo) {
  if (modulo) {
    return f.get(URL1 + 'estatisticas/vendas-por-produto', data)
  }
  return f.post(URL + 'estatisticas/vendas-por-produto', data)
}
export function stockEstado (f, data, op, modulo) {
  if (modulo) {
    return f.get(URL + 'stock/' + (op === 'excesso' ? 'em-excesso' : 'em-falta'), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'stock/' + (op === 'excesso' ? 'em-excesso' : 'em-falta'), data)
}

export function planosRenovar (f, data) {
  return f.post(URL + 'planos/pagamentos/gerar-pedido-renovacao', data)
}
export function planosHistorico (f, pag) {
  return f.post(URL + 'planos/pagamentos/historico?page=' + (pag || 1), {})
}
export function cupoesVer (f, token) {
  return f.post(URL + 'cupoes/ver/' + token, {})
}
export function cupoesUsados (f) {
  return f.post(URL + 'cupoes/usados')
}
export function cupoesPorUsar (f) {
  return f.post(URL + 'cupoes/por-usar')
}
export function planosMudarGratis (f, data) {
  return f.post(URL + 'planos/mudar-para-gratuito', data)
}
export function planos (f, data) {
  return f.post(URL + 'planos/todos-os-planos', data)
}
export function planoAtual (f, data) {
  return f.post(URL + 'planos/actual', data)
}
export function codigoOferta (f, codigo) {
  return f.post(URL + 'codigo-oferta/' + codigo, {})
}

export function estatisticasmensais (f, data) {
  return f.post(URL + 'estatisticas/mensais', data)
}
export function estatisticasglobais (f, data) {
  return f.post(URL + 'estatisticas/globais', data)
}
export function estatisticasContato (f, data) {
  return f.post(URL + 'estatisticas/globais/contato', data)
}

export function estatisticasDocsVencidos (f, data) {
  return f.post(URL + 'estatisticas/documentos-vencidos', data)
}
export function estatisticasMaisVendidos (f, data) {
  return f.post(URL + 'estatisticas/mais-vendidos', data)
}
export function estatisticasTotalComprasVendasPorProduto (f, data) {
  return f.post(URL + 'estatisticas/total-compras-vendas-por-produto', data)
}
export function estatisticasInteligentes (f, data) {
  return f.post(URL + 'estatisticas/inteligente', data)
}

export function estadosUP (f, data, modulo) {
  if (modulo) {
    return f.post(URL + 'estados/mudar-estado', data)
  }
  return f.post(URL + 'estados/mudar-estado', data)
}

// relatorios
export function mapaVencidos (f, data) {
  return f.post(URL + 'mapas/conta-vencidos/json', data)
}
export function mapaAberto (f, data) {
  return f.post(URL + 'mapas/conta-em-aberto/json', data)
}
export function mapaExtractoDocumentos (f, data) {
  return f.post(URL + 'mapas/extracto-documentos/json', data)
}
export function mapaCorrente (f, data) {
  return f.post(URL + 'mapas/conta-corrente/json', data)
}
export function mapaProdutos (f, data) {
  return f.post(URL + 'mapas/movimentos-produto/json', data)
}
export function mapaProdutosVinho (f, data) {
  return f.post(URL + '/mapas/movimentos-produto-vinho/json', data)
}
export function mapaTotais (f, data) {
  return f.post(URL + 'mapas/totais-por-mes/json', data)
}
export function mapaFaturado (f, data) {
  return f.post(URL + 'mapas/total-faturado/json', data)
}
export function mapaPagamentos (f, data) {
  return f.post(URL + 'mapas/extracto-pagamentos/json', data)
}
export function mapaImpostos (f, data) {
  return f.post(URL + 'mapas/mapa-de-ivas/json', data)
}
export function magemLucro (f, data) {
  return f.post(URL + 'mapas/margens-de-lucro', data)
}

export function recalcular (f, id) {
  return f.post(URL + 'contatos/recalcular-contas/' + id)
}

export function ip (f) {
  return f.post(URL + 'ip-actual', {})
}
export function cambios (f) {
  return f.get(URL + 'cambios', {})
}

export function pos (f) {
  return f.post(URL + 'pos/ver', {})
}
export function posID (f, id) {
  return f.post(URL + 'pos/ver/' + id, {})
}
export function posFecharCaixa (f, data) {
  return f.post(URL + 'pos/relatorio-fecho', data)
}
export function posCriar (f, data) {
  return f.post(URL + 'pos/criar', data)
}
export function posUP (f, data) {
  return f.post(URL + 'pos/update', data)
}
export function posDEL (f, data) {
  return f.post(URL + 'pos/apagar', data)
}
export function posEstadoId (f, id) {
  return f.post(URL + 'pos/estado/' + id)
}
export function posEstadoUp (f, data) {
  return f.post(URL + 'pos/mudar-estado', data)
}
export function posCaixaLog (f, data, pag) {
  return f.post(URL + 'pos/log?page=' + (pag || 1), data)
}
export function consFinal (f) {
  return f.post(URL + 'contatos/consumidor-final')
}

export function smtp (f) {
  return f.post(URL + 'smtp/ver')
}
export function smtpDel (f) {
  return f.post(URL + 'smtp/apagar')
}
export function smtpUp (f, data) {
  return f.post(URL + 'smtp/criar', data)
}
export function smtpTeste (f, data) {
  return f.post(URL + 'smtp/email-teste', data)
}

export function templateDel (f, data) {
  return f.post(URL + 'email-template/apagar', data)
}
export function templateCriar (f, data) {
  return f.post(URL + 'email-template/criar', data)
}
export function templateUp (f, data) {
  return f.post(URL + 'email-template/update', data)
}

// metas itens contactos e documentos
export function metaAd (f, data, pag) {
  return f.post(URL + (pag === 'contactos' ? 'contatos' : pag) + '/meta/estrutrura/adicionar', data)
}
export function metaUp (f, data, pag) {
  return f.post(URL + (pag === 'contactos' ? 'contatos' : pag) + '/meta/estrutrura/update', data)
}
export function metaDel (f, data, pag) {
  return f.post(URL + (pag === 'contactos' ? 'contatos' : pag) + '/meta/estrutrura/apagar', data)
}
export function metaAdMultiplos (f, data, pag) {
  return f.post(URL + (pag === 'contactos' ? 'contatos' : pag) + '/meta/dados/multiplos', data)
}

// impressoes
export function impressoesAd (f, data) {
  return f.post(URL + 'impressoes/criar', data)
}
export function impressoesDel (f, data) {
  return f.post(URL + 'impressoes/apagar', data)
}

// discussao
export function discussao (f, data) {
  return f.post(URL + 'documentos/com-codigo', data)
}
export function discussaoUp (f, data) {
  return f.post(URL + 'documentos/editar-discussao', data)
}
export function comentarContato (f, data) {
  return f.post(URL + 'documentos/contato/comentar', data)
}
export function comentarUtilizador (f, data) {
  return f.post(URL + 'documentos/utilizador/comentar', data)
}
export function discussaoDesbloquear (f, data) {
  return f.post(URL + 'documentos/desbloquear', data)
}

// agendamento
export function agendamento (f, data, page) {
  return f.post(URL + 'agendamento/ver?page=' + (page || 1), data)
}
export function agendamentoId (f, id) {
  return f.post(URL + 'agendamento/ver/' + id)
}
export function agendamentoCriar (f, data) {
  return f.post(URL + 'agendamento/criar', data)
}
export function agendamentoDel (f, data) {
  return f.post(URL + 'agendamento/apagar', data)
}

// post its
export function postit (f, data) {
  return f.post(URL + 'postit/ver', data)
}
export function postitCriar (f, data) {
  return f.post(URL + 'postit/criar', data)
}
export function postitDel (f, data) {
  return f.post(URL + 'postit/apagar', data)
}

// projetos
export function projetos (f, data, page, modulo) {
  if (modulo) {
    return f.get(URL1 + 'projetos?page=' + (page || 1), { params: data, paramsSerializer: apiParamsSerializer })
  }
  return f.post(URL + 'projetos/ver?page=' + (page || 1), data)
}
export function projetosId (f, id, modulo) {
  if (modulo) {
    return f.get(URL1 + 'projetos/' + id)
  }
  return f.post(URL + 'projetos/ver/' + id)
}
export function projetosCriar (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'projetos', data)
  }
  return f.post(URL + 'projetos/criar', data)
}
export function projetosUp (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'projetos/' + data.id, data)
  }
  return f.post(URL + 'projetos/update', data)
}
export function projetosUpEstado (f, data, modulo) {
  if (modulo) {
    return f.patch(URL1 + 'projetos/mudar-estado/' + data.projecto_id, data)
  }
  return f.post(URL + 'projetos/mudar-estado', data)
}
export function projetosDel (f, data, modulo) {
  if (modulo) {
    return f.delete(URL1 + 'projetos/' + data.id)
  }
  return f.post(URL + 'projetos/apagar', data)
}
export function projetosAddDoc (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'projetos/adicionar-documento', data)
  }
  return f.post(URL + 'projetos/adicionar-documento', data)
}
export function projetosDelDoc (f, data, modulo) {
  if (modulo) {
    return f.post(URL1 + 'projetos/remover-documento', data)
  }
  return f.post(URL + 'projetos/remover-documento', data)
}

export function propriaEmpresa (f, modulo) {
  if (modulo) {
    return f.get(URL1 + 'contatos/propria-empresa')
  }
  return f.post(URL + 'contatos/propria-empresa')
}

export function SetPOS (f, data) {
  return f.post(URL + 'pos/save-data', data)
}
export function GetPOS (f, data) {
  return f.post(URL + 'pos/get-data', data)
}

export function getTriplicados (f) {
  return f.post(URL + 'triplicados/ver')
}
export function postTriplicados (f, data) {
  return f.post(URL + 'triplicados', data)
}

//cloudbeds
export function clooudbedsToken (f, data) {
  return f.post(URL + 'cloudbeds/token', data)
}
export function clooudbedsDesligar (f) {
  return f.post(URL + 'cloudbeds/disconnect')
}
export function clooudbedsTestConexao (f) {
  return f.post(URL + 'cloudbeds/test-connection')
}
export function clooudbedsReservas (f, data, pag) {
  return f.post(URL + 'cloudbeds/reservations?page=' + (pag || 1), data)
}
export function clooudbedsReserva (f, data) {
  return f.post(URL + 'cloudbeds/reservation', data)
}
export function clooudbedsDoc (f, data) {
  return f.post(URL + 'cloudbeds/send-document', data)
}
export function clooudbedsFindReserva (f, data) {
  return f.post(URL + 'cloudbeds/find-reservation', data)
}

export function concatoCriaOuEncontra (f, data) {
  return f.post(URL + 'contatos/encontra-ou-cria', data)
}
