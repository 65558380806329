<template>
  <select class="form-control foco" v-model="moeda" :disabled="estado" id="moeda" @keydown.enter.prevent="FOCO" @change="$emit('cambio', moeda)">
    <option value="EUR_€">Euro (€)</option>
    <option value="USD_$">U.S. dollar ($)</option>
    <option value="GBP_£">Pound sterling (£)</option>
    <option value="CAD_C$">Canadian dollar (C$)</option>
    <option value="AUD_A$">Australian dollar (A$)</option>
    <option value="ZAR_R">South African rand (R)</option>
    <option value="AFN_؋">Afghan afghani (؋)</option>
    <option value="ALL_L">Albanian lek (L)</option>
    <option value="DZD_د.ج">Algerian dinar (د.ج)</option>
    <option value="AOA_Kz">Angolan kwanza (Kz)</option>
    <option value="ARS_$">Argentine peso ($)</option>
    <option value="AMD_դր.">Armenian dram (դր.)</option>
    <option value="AWG_ƒ">Aruban florin (ƒ)</option>
    <option value="AZN_¤">Azerbaijani manat (¤)</option>
    <option value="BSD_$">Bahamian dollar ($)</option>
    <option value="BHD_ب.د">Bahraini dinar (ب.د)</option>
    <option value="BDT_¤">Bangladeshi taka (¤)</option>
    <option value="BBD_$">Barbadian dollar ($)</option>
    <option value="BYR_Br">Belarusian ruble (Br)</option>
    <option value="BZD_$">Belize dollar ($)</option>
    <option value="BMD_$">Bermudian dollar ($)</option>
    <option value="BTN_¤">Bhutanese ngultrum (¤)</option>
    <option value="BOB_Bs.">Bolivian boliviano (Bs.)</option>
    <option value="BAM_KM">Bosnia &amp; Herzegovina mark (KM)</option>
    <option value="BWP_P">Botswana pula (P)</option>
    <option value="BRL_R$">Brazilian real (R$)</option>
    <option value="BND_$">Brunei dollar ($)</option>
    <option value="BGN_лв">Bulgarian lev (лв)</option>
    <option value="BIF_Fr">Burundian franc (Fr)</option>
    <option value="KHR_¤">Cambodian riel (¤)</option>
    <option value="CVE_Esc">Cape Verdean escudo (Esc)</option>
    <option value="KYD_$">Cayman Islands dollar ($)</option>
    <option value="XAF_Fr">Central African CFA franc (Fr)</option>
    <option value="XPF_Fr">CFP franc (Fr)</option>
    <option value="CLP_$">Chilean peso ($)</option>
    <option value="CNY_¥">Chinese yuan (¥)</option>
    <option value="COP_$">Colombian peso ($)</option>
    <option value="KMF_Fr">Comorian franc (Fr)</option>
    <option value="CDF_Fr">Congolese franc (Fr)</option>
    <option value="CRC_₡">Costa Rican colón (₡)</option>
    <option value="HRK_kn">Croatian kuna (kn)</option>
    <option value="CUC_$">Cuban convertible peso ($)</option>
    <option value="CUP_$">Cuban peso ($)</option>
    <option value="CZK_Kč">Czech koruna (Kč)</option>
    <option value="DKK_kr.">Danish krone (kr.)</option>
    <option value="DJF_Fr">Djiboutian franc (Fr)</option>
    <option value="DOP_$">Dominican peso ($)</option>
    <option value="XCD_$">East Caribbean dollar ($)</option>
    <option value="EGP_ج.م">Egyptian pound (ج.م)</option>
    <option value="ERN_Nfk">Eritrean nakfa (Nfk)</option>
    <option value="EEK_KR">Estonian kroon (KR)</option>
    <option value="ETB_¤">Ethiopian birr (¤)</option>
    <option value="FKP_£">Falkland Islands pound (£)</option>
    <option value="FJD_$">Fijian dollar ($)</option>
    <option value="GMD_D">Gambian dalasi (D)</option>
    <option value="GEL_ლ">Georgian lari (ლ)</option>
    <option value="GHS_₵">Ghanaian cedi (₵)</option>
    <option value="GIP_£">Gibraltar pound (£)</option>
    <option value="GTQ_Q">Guatemalan quetzal (Q)</option>
    <option value="GNF_Fr">Guinean franc (Fr)</option>
    <option value="GYD_$">Guyanese dollar ($)</option>
    <option value="HTG_G">Haitian gourde (G)</option>
    <option value="HNL_L">Honduran lempira (L)</option>
    <option value="HKD_$">Hong Kong dollar ($)</option>
    <option value="HUF_Ft">Hungarian forint (Ft)</option>
    <option value="ISK_kr">Icelandic króna (kr)</option>
    <option value="INR_Rs">Indian rupee (Rs)</option>
    <option value="IDR_Rp">Indonesian rupiah (Rp)</option>
    <option value="IRR_﷼">Iranian rial (﷼)</option>
    <option value="IQD_ع.د">Iraqi dinar (ع.د)</option>
    <option value="ILS_₪">Israeli new sheqel (₪)</option>
    <option value="JMD_$">Jamaican dollar ($)</option>
    <option value="JPY_¥">Japanese yen (¥)</option>
    <option value="JOD_د.ا">Jordanian dinar (د.ا)</option>
    <option value="KZT_〒">Kazakhstani tenge (〒)</option>
    <option value="KES_Sh">Kenyan shilling (Sh)</option>
    <option value="KWD_د.ك">Kuwaiti dinar (د.ك)</option>
    <option value="KGS_¤">Kyrgyzstani som (¤)</option>
    <option value="LAK_₭">Lao kip (₭)</option>
    <option value="LVL_Ls">Latvian lats (Ls)</option>
    <option value="LBP_ل.ل">Lebanese pound (ل.ل)</option>
    <option value="LSL_L">Lesotho loti (L)</option>
    <option value="LRD_$">Liberian dollar ($)</option>
    <option value="LYD_ل.د">Libyan dinar (ل.د)</option>
    <option value="LTL_Lt">Lithuanian litas (Lt)</option>
    <option value="MOP_P">Macanese pataca (P)</option>
    <option value="MKD_ден">Macedonian denar (ден)</option>
    <option value="MGA_¤">Malagasy ariary (¤)</option>
    <option value="MWK_MK">Malawian kwacha (MK)</option>
    <option value="MYR_RM">Malaysian ringgit (RM)</option>
    <option value="MVR_Rf">Maldivian rufiyaa (Rf)</option>
    <option value="MRO_UM">Mauritanian ouguiya (UM)</option>
    <option value="MUR_₨">Mauritian rupee (₨)</option>
    <option value="MXN_$">Mexican peso ($)</option>
    <option value="MDL_L">Moldovan leu (L)</option>
    <option value="MNT_₮">Mongolian tögrög (₮)</option>
    <option value="MAD_د.م.">Moroccan dirham (د.م.)</option>
    <option value="MZN_MZN">Mozambican metical (MZN)</option>
    <option value="MMK_K">Myanma kyat (K)</option>
    <option value="NAD_$">Namibian dollar ($)</option>
    <option value="NPR_₨">Nepalese rupee (₨)</option>
    <option value="ANG_ƒ">Netherlands Antillean guilder (ƒ)</option>
    <option value="TWD_$">New Taiwan dollar ($)</option>
    <option value="NZD_$">New Zealand dollar ($)</option>
    <option value="NIO_C$">Nicaraguan córdoba (C$)</option>
    <option value="NGN_₦">Nigerian naira (₦)</option>
    <option value="KPW_₩">North Korean won (₩)</option>
    <option value="NOK_kr">Norwegian krone (kr)</option>
    <option value="OMR_ر.ع.">Omani rial (ر.ع.)</option>
    <option value="PKR_₨">Pakistani rupee (₨)</option>
    <option value="PAB_B/.">Panamanian balboa (B/.)</option>
    <option value="PGK_K">Papua New Guinean kina (K)</option>
    <option value="PYG_₲">Paraguayan guaraní (₲)</option>
    <option value="PEN_S/.">Peruvian nuevo sol (S/.)</option>
    <option value="PHP_₱">Philippine peso (₱)</option>
    <option value="PLN_zł">Polish złoty (zł)</option>
    <option value="QAR_ر.ق">Qatari riyal (ر.ق)</option>
    <option value="RON_L">Romanian leu (L)</option>
    <option value="RUB_р.">Russian ruble (р.)</option>
    <option value="RWF_Fr">Rwandan franc (Fr)</option>
    <option value="SHP_£">Saint Helena pound (£)</option>
    <option value="SVC_₡">Salvadoran colón (₡)</option>
    <option value="WST_T">Samoan tala (T)</option>
    <option value="STD_Db">São Tomé and Príncipe dobra (Db)</option>
    <option value="SAR_ر.س">Saudi riyal (ر.س)</option>
    <option value="RSD_дин.">Serbian dinar (дин.)</option>
    <option value="SCR_₨">Seychellois rupee (₨)</option>
    <option value="SLL_Le">Sierra Leonean leone (Le)</option>
    <option value="SGD_$">Singapore dollar ($)</option>
    <option value="SBD_$">Solomon Islands dollar ($)</option>
    <option value="SOS_Sh">Somali shilling (Sh)</option>
    <option value="KRW_₩">South Korean won (₩)</option>
    <option value="LKR_Rs">Sri Lankan rupee (Rs)</option>
    <option value="SDG_£">Sudanese pound (£)</option>
    <option value="SRD_$">Surinamese dollar ($)</option>
    <option value="SZL_L">Swazi lilangeni (L)</option>
    <option value="SEK_kr">Swedish krona (kr)</option>
    <option value="CHF_Fr">Swiss franc (Fr)</option>
    <option value="SYP_ل.س">Syrian pound (ل.س)</option>
    <option value="TJS_ЅМ">Tajikistani somoni (ЅМ)</option>
    <option value="TZS_Sh">Tanzanian shilling (Sh)</option>
    <option value="THB_฿">Thai baht (฿)</option>
    <option value="TOP_T$">Tongan paʻanga (T$)</option>
    <option value="TTD_$">Trinidad and Tobago dollar ($)</option>
    <option value="TND_د.ت">Tunisian dinar (د.ت)</option>
    <option value="TRY_TL">Turkish lira (TL)</option>
    <option value="TMM_m">Turkmenistani manat (m)</option>
    <option value="UGX_Sh">Ugandan shilling (Sh)</option>
    <option value="UAH_₴">Ukrainian hryvnia (₴)</option>
    <option value="AED_د.إ">United Arab Emirates dirham (د.إ)</option>
    <option value="UYU_$">Uruguayan peso ($)</option>
    <option value="UZS_¤">Uzbekistani som (¤)</option>
    <option value="VUV_Vt">Vanuatu vatu (Vt)</option>
    <option value="VEF_Bs F">Venezuelan bolívar (Bs F)</option>
    <option value="VND_₫">Vietnamese đồng (₫)</option>
    <option value="XOF_Fr">West African CFA franc (Fr)</option>
    <option value="YER_﷼">Yemeni rial (﷼)</option>
    <option value="ZMK_ZK">Zambian kwacha (ZK)</option>
    <option value="ZWR_$">Zimbabwean dollar ($)</option>
    <option value="XDR_SDR">Special Drawing Rights (SDR)</option>
    <option value="TMT_m">Turkmen manat (m)</option>
    <option value="VEB_Bs">Venezuelan bolivar (Bs)</option>
  </select>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      moeda: ''
    }
  },
  props: {
    form: { type: Object },
    estado: { default: true, type: Boolean }
  },
  methods: {
    ...mapMutations([
      'FOCO'
    ])
  },
  mounted () {
    this.moeda = this.form.moeda
  }
}
</script>
