// esta funçao serve para o select em tree apresentar os ids
export function selectIds (dta) {
  let ar = []
  if (Array.isArray(dta)) {
    let i = dta.length
    let u = 0
    while (u < i) {
      ar.push(dta[u].id)
      ++u
    }
  }
  return ar
}

export function focWidth () {
  return document.body.clientWidth > 500
}
export function val (id) {
  return document.getElementById(id || 'form').checkValidity()
}
export function v (id) {
  return document.getElementById(id).value
}

export function checkNIF (f, nif, form) {
  return new Promise(function (resolve) {
    if ((form.pais === 'PT' && nif.length === 9) || form.pais !== 'PT') {
      f.post('/verifica/' + form.pais + '/' + encodeURIComponent(nif), {}).then((res) => {
        let a = res.data.address ? res.data.address.trim() : ''
        form.nome = form.upNIF1 ? form.nome : ((res.data.denomination || res.data.name) ? (res.data.denomination + ' ' + res.data.name).toUpperCase() : '')
        a = a.split('\n')
        let x = ''
        let k = a.length
        let i = k
        if (form.pais === 'PT') {
          while (--i >= 0) {
            x = a[i].split(' ')
            if (/[0-9]{4}-[0-9]{3}/.test(x[0])) {
              form.codigo_postal = x[0] || ''
              a = res.data.address.split(form.codigo_postal)
              form.cidade = a[1].toUpperCase() || ''
              form.morada = a[0].replace(/\n/g, ' ').toUpperCase() || ''
              break
            }
          }
        } else {
          k = a[i - 1].split(' ')
          a.splice(i - 1, 1)
          form.morada = a.join(' ').toUpperCase() || ''
          form.codigo_postal = k[0].toUpperCase() || ''
          k.splice(0, 1)
          if (k[0]) {
            form.cidade = k.join(' ').toUpperCase() || ''
          } else {
            form.cidade = ''
          }
        }
        resolve()
      }, () => {
        // er.response.data.error
        if (form.pais === 'PT' && nif.length === 9) {
          if (
            nif.substr(0, 1) !== '1' && // pessoa singular
            nif.substr(0, 1) !== '2' && // pessoa singular
            nif.substr(0, 1) !== '3' && // pessoa singular
            nif.substr(0, 2) !== '45' && // pessoa singular não residente
            nif.substr(0, 1) !== '5' && // pessoa colectiva
            nif.substr(0, 1) !== '6' && // administração pública
            nif.substr(0, 2) !== '70' && // herança indivisa
            nif.substr(0, 2) !== '71' && // pessoa colectiva não residente
            nif.substr(0, 2) !== '72' && // fundos de investimento
            nif.substr(0, 2) !== '77' && // atribuição oficiosa
            nif.substr(0, 2) !== '79' && // regime excepcional
            nif.substr(0, 1) !== '8' && // empresário em nome individual (extinto)
            nif.substr(0, 2) !== '90' && // condominios e sociedades irregulares
            nif.substr(0, 2) !== '91' && // condominios e sociedades irregulares
            nif.substr(0, 2) !== '98' && // não residentes
            nif.substr(0, 2) !== '99' // sociedades civis
            ) {
            resolve(-1)
          }
          let check1 = nif.substr(0, 1) * 9
          let check2 = nif.substr(1, 1) * 8
          let check3 = nif.substr(2, 1) * 7
          let check4 = nif.substr(3, 1) * 6
          let check5 = nif.substr(4, 1) * 5
          let check6 = nif.substr(5, 1) * 4
          let check7 = nif.substr(6, 1) * 3
          let check8 = nif.substr(7, 1) * 2
          let total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8
          let divisao = total / 11
          let modulo11 = total - parseInt(divisao) * 11
          let comparador
          if (modulo11 === 1 || modulo11 === 0) {
            comparador = 0
          } else {
            comparador = 11 - modulo11
          }
          if ((nif.substr(8, 1) * 1) !== comparador) {
            resolve(-1)
          }
          resolve()
        } else {
          resolve(-1)
        }
      })
    } else {
      resolve(-1)
    }
  })
}

export function slug (a) {
  return a.toString().toLowerCase().replace(/ç/g, 'c').replace(/-/g, '').replace(/\s+/g, '').replace(/[^\w-]+/g, '').replace(/--+/g, '').replace(/^-+/, '').replace(/-+$/, '')
}

export function urlhashDocs () {
  return window.location.search
}

export function exportTableToCSV (filename) {
  let csv = []
  let rows = document.querySelectorAll('table#tabelaFixa tr, table#tabelaFixa1 tr')
  let downloadLink
  var reg = /^[0-9 ,]+$/

  let trows = rows.length
  for (var i = 0; i < trows; i++) {
    var row = []
    var cols = rows[i].querySelectorAll('td, th')

    if (rows[i].style.display === 'none') {
      continue
    }

    let tcols = cols.length
    let quebrou = 0
    for (var j = 0; j < tcols; j++) {
      if (cols[j].parentNode.parentNode.style.display === 'none') {
        quebrou = 1
        break
      }
      if (cols[j].className.indexOf('calcular') === -1) {
        let a = cols[j].innerText.toString().replaceAll('\n', ' - ')
        if (reg.test(a)) {
          row.push(a.replaceAll('&nbsp;', ''))
        } else {
          row.push(a)
        }
      } else {
        row.push('')
      }
      if (cols[j].colSpan > 1) {
        var x = cols[j].colSpan
        while (--x > 0) {
          row.push('')
        }
      }
    }
    if (quebrou) {
      continue
    }
    csv.push(row.join(';'))
  }

  downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + csv.join('\n')) // window.URL.createObjectURL(new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8;' }))
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

export function getData () {
  let a = new Date()
  let mes = a.getMonth() + 1
  return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
}
export function gethora (m, seg) {
  let a = new Date()
  a.setMinutes(a.getMinutes() + (m || 0))
  return (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + (seg ? ':' + (a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds()) : '')
}
export function somarDias (dias, data) {
  let a = new Date((data ? new Date(data).getTime() : new Date().getTime()) + (dias * 24 * 60 * 60 * 1000))
  let mes = a.getMonth() + 1
  return a.getFullYear() + '-' + (mes < 10 ? '0' + mes : mes) + '-' + (a.getDate() < 10 ? '0' + a.getDate() : a.getDate())
}

export function Principal (ar, valor, existe) {
  let i = ar.length
  while (--i >= 0) {
    if (parseInt(ar[i].id) === parseInt(valor) && (valor || valor === 0) && (ar[i].visivel || existe || ar[i].tipificacao)) {
      return ar[i]
    }
    if (ar[i].principal && !valor && (ar[i].visivel || existe || ar[i].tipificacao)) {
      return ar[i]
    }
  }
  if (existe) {
    return ar[0]
  } else {
    i = ar.length
    while (--i >= 0) {
      if (ar[i].visivel) {
        return ar[i]
      }
    }
  }
  return []
}

export function ordernar (order) {
  if (order === 'ASC') {
    return 'DESC'
  }
  return 'ASC'
}
export function objToUrl (obj, ok) {
  let parts = []
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      parts.push(encodeURIComponent((ok ? ok + '__' : '') + key) + '=' + encodeURIComponent(obj[key]))
    }
  }
  parts = parts.join('&')
  if (parts) {
    return '&' + parts
  }
  return ''
}
export function pesquisar () {
  let search = window.location.search
  if (search) {
    search = search.substring(1).split('&')
    let i = search.length
    let ret = {}
    let a = []
    while (--i >= 0) {
      search[i] = search[i].split('=')
      if (search[i][0].indexOf('__') !== -1) {
        a = search[i][0].split('__')
        if (!ret[a[0]]) {
          ret[a[0]] = {}
        }
        ret[a[0]][a[1]] = decodeURIComponent(search[i][1])
        continue
      }
      if (search[i][1] === 'true' || search[i][1] === 'false') {
        if (search[i][1] === 'true') {
          ret[search[i][0]] = true
        } else {
          ret[search[i][0]] = false
        }
      } else {
        ret[search[i][0]] = decodeURIComponent(search[i][1])
      }
    }
    if (!ret['order_by']) {
      ret['order_by'] = { 'system_entry_date': 'ASC' }
    }
    if (!ret['texto']) {
      ret['texto'] = {}
    }
    if (!ret['data_menor']) {
      ret['data_menor'] = {}
    }
    if (!ret['data_maior']) {
      ret['data_maior'] = {}
    }
    if (!ret['data_igual']) {
      ret['data_igual'] = {}
    }
    if (!ret['valor_menor']) {
      ret['valor_menor'] = {}
    }
    if (!ret['valor_maior']) {
      ret['valor_maior'] = {}
    }
    if (!ret['valor_igual']) {
      ret['valor_igual'] = {}
    }
    if (!ret['pesquisa_avancada']) {
      ret['pesquisa_avancada'] = {
        tipoDocumento: {}
      }
    }
    return ret
  }
}

export function width (id) {
  if (!document.getElementById(id)) {
    return 0
  }
  return document.getElementById(id).offsetWidth
}
export function urlsearch (name) {
  let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export function round10 (value, exp, type) {
  // return parseFloat(Number.parseFloat(value).toFixed(exp * -1))
  // type pode ser round, ceil, floor
  if (!type) {
    type = 'round'
  }
  if (!exp) {
    exp = -7
  }
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return ''
  }
  if (value < 0) {
    return -round10(-value, exp, type)
  }
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}

export function formUrl (order, pesq, pesq1) {
  switch (order) {
    case 'codigo':
    if (pesq.codigo === pesq1.codigo) {
      return
    }
    break
    case 'descricao':
    if (pesq.descricao === pesq1.descricao) {
      return
    }
    break
    case 'nome':
    if (pesq.nome === pesq1.nome) {
      return
    }
    break
    case 'nif':
    if (pesq.nif === pesq1.nif) {
      return
    }
    break
    case 'contato':
    if (pesq.contato === pesq1.contato) {
      return
    }
    break
    case 'invoice_number':
    if (pesq.invoice_number === pesq1.invoice_number) {
      return
    }
    break
    case 'invoice_number1':
    if (pesq.invoice_number1 === pesq1.invoice_number1) {
      return
    }
    break
    case 'invoice_date':
    if (pesq.invoice_date === pesq1.invoice_date) {
      return
    }
    break
    case 'prazo_vencimento':
    if (pesq.prazo_vencimento === pesq1.prazo_vencimento) {
      return
    }
    break
    case 'gross_total':
    if (pesq.gross_total === pesq1.gross_total) {
      return
    }
    break
    case 'info':
    if (pesq.info === pesq1.info) {
      return
    }
    break
    case 'resultado':
    if (pesq.resultado === pesq1.resultado) {
      return
    }
    break
  }
  return true
}
export function formUrl1 (order, pesq, pesq1) {
  let a
  if (!pesq.ordenar) {
    return true
  }
  a = pesq.ordenar.split('-')
  switch (order) {
    case 'descricao':
    if (a[0] === 'descricao' && a[1] === 'DESC') {
      pesq.ordenar = 'descricao-ASC'
    } else {
      pesq.ordenar = 'descricao-DESC'
    }
    break
    case 'nome':
    if (a[0] === 'nome' && a[1] === 'DESC') {
      pesq.ordenar = 'nome-ASC'
    } else {
      pesq.ordenar = 'nome-DESC'
    }
    break
    case 'codigo':
    if (a[0] === 'codigo' && a[1] === 'DESC') {
      pesq.ordenar = 'codigo-ASC'
    } else {
      pesq.ordenar = 'codigo-DESC'
    }
    break
    case 'nif':
    if (a[0] === 'nif' && a[1] === 'DESC') {
      pesq.ordenar = 'nif-ASC'
    } else {
      pesq.ordenar = 'nif-DESC'
    }
    break
    case 'pais':
    if (a[0] === 'pais' && a[1] === 'DESC') {
      pesq.ordenar = 'pais-ASC'
    } else {
      pesq.ordenar = 'pais-DESC'
    }
    break
    case 'loja':
    if (Array.isArray(pesq.loja)) {
      if (pesq.loja.join('.') === pesq1.loja.join('.')) {
        return
      }
    } else {
      if (pesq.loja === pesq1.loja) {
        return
      }
    }
    break
    case 'tipo':
    if (pesq.tipo.join('.') === pesq1.tipo.join('.')) {
      return
    }
    break
    // saft pesq
    case 'tipo1':
    if (pesq.tipo1 === pesq1.tipo1) {
      return
    }
    break
    case 'created_at':
    if (a[0] === 'created_at' && a[1] === 'DESC') {
      pesq.ordenar = 'created_at-ASC'
    } else {
      pesq.ordenar = 'created_at-DESC'
    }
    break
    case 'categoria':
    if (pesq.categoria.join('.') === pesq1.categoria.join('.')) {
      return
    }
    break
    case 'contato':
    if (a[0] === 'contato' && a[1] === 'DESC') {
      pesq.ordenar = 'contato-ASC'
    } else {
      pesq.ordenar = 'contato-DESC'
    }
    break
    case 'prazo_vencimento':
    if (a[0] === 'prazo_vencimento' && a[1] === 'DESC') {
      pesq.ordenar = 'prazo_vencimento-ASC'
    } else {
      pesq.ordenar = 'prazo_vencimento-DESC'
    }
    break
    case 'gross_total':
    if (a[0] === 'gross_total' && a[1] === 'DESC') {
      pesq.ordenar = 'gross_total-ASC'
    } else {
      pesq.ordenar = 'gross_total-DESC'
    }
    break
    case 'invoice_number':
    if (a[0] === 'invoice_number' && a[1] === 'DESC') {
      pesq.ordenar = 'invoice_number-ASC'
    } else {
      pesq.ordenar = 'invoice_number-DESC'
    }
    break
    case 'system_entry_date':
    if (a[0] === 'system_entry_date' && a[1] === 'DESC') {
      pesq.ordenar = 'system_entry_date-ASC'
    } else {
      pesq.ordenar = 'system_entry_date-DESC'
    }
    break
    case 'invoice_date':
    if (a[0] === 'invoice_date' && a[1] === 'DESC') {
      pesq.ordenar = 'invoice_date-ASC'
    } else {
      pesq.ordenar = 'invoice_date-DESC'
    }
    break
    case 'loja_id':
    if (pesq.loja_id === pesq1.loja_id) {
      return
    }
    break
    case 'modulo_estado_id':
    if (pesq.modulo_estado_id === pesq1.modulo_estado_id) {
      return
    }
    break
  }
  return true
}
export function formVer (pesq, documento, metas) {
  // window.scrollTo(0, 0)
  /*
  let search = { pesquisa: { texto: {}, order_by: {}, pesquisa_avancada: {} } }
  let a = pesq.ordenar.split('-')
  search.pesquisa.visivel = pesq.visivel
  if (pesq.codigo !== '') {
    search.pesquisa.texto.codigo = pesq.codigo
    search.pesquisa.texto.codigo_barras = pesq.codigo
  }
  if (pesq.nome !== '') {
    search.pesquisa.texto.nome = pesq.nome
  }
  if (pesq.pais !== '') {
    search.pesquisa.pais = pesq.pais
  }
  if (pesq.nif !== '') {
    search.pesquisa.texto.nif = pesq.nif
  }
  if (pesq.retencao) {
    search.pesquisa.retencao = true
  }
  if (pesq.loja) {
    if (Array.isArray(pesq.loja)) {
      if (pesq.loja.length && pesq.loja[0] !== 'lojas') {
        search.pesquisa.loja = pesq.loja
      }
    } else {
      if (pesq.loja > 0) {
        search.pesquisa.loja = [pesq.loja]
      }
    }
  }
  if (pesq.tipo) {
    if (pesq.tipo.length) {
      search.pesquisa.tipo = pesq.tipo
    }
  }

  if ((pesq.produto && pesq.servico && pesq.taxa) || (!pesq.produto && !pesq.servico && !pesq.taxa)) {
    // nao faz nada
  } else {
    if (pesq.servico) {
      search.pesquisa.servico = true
    } else {
      search.pesquisa.servico = false
    }
    if (pesq.taxa) {
      search.pesquisa.taxa = true
    } else {
      search.pesquisa.taxa = false
    }
  }
  if (pesq.movimenta_stock && pesq.produto) {
    search.pesquisa.movimenta_stock = true
  }
  if (pesq.produto_composto && pesq.produto) {
    search.pesquisa.produto_composto = true
  }
  if (pesq.descricao !== '') {
    search.pesquisa.texto.descricao = pesq.descricao
  }
  if (pesq.categoria) {
    if (pesq.categoria.length) {
      search.pesquisa.categoria = pesq.categoria
    }
  }

  if (documento) {
    search.pesquisa.pesquisa_avancada.tipoDocumento = { categoria: documento }
  }
  let dd
  if (pesq.prazo_vencimento_min || pesq.prazo_vencimento_max) {
    if (pesq.prazo_vencimento_min) {
      dd = new Date(pesq.prazo_vencimento_min)
      search.pesquisa.data_menor = { prazo_vencimento: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    }
    if (pesq.prazo_vencimento_max) {
      dd = new Date(pesq.prazo_vencimento_max)
      search.pesquisa.data_maior = { prazo_vencimento: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    }
  }
  if (pesq.invoice_date_min || pesq.invoice_date_max) {
    if (pesq.invoice_date_min) {
      dd = new Date(pesq.invoice_date_min)
      search.pesquisa.data_menor = { invoice_date: pesq.invoice_date_min }
    }
    if (pesq.invoice_date_max) {
      dd = new Date(pesq.invoice_date_max)
      search.pesquisa.data_maior = { invoice_date: pesq.invoice_date_max }
    }
  }
  if (pesq.invoice_date) {
    dd = new Date(pesq.invoice_date)
    if (a[1] === 'DESC') {
      search.pesquisa.data_menor = { invoice_date: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    } else {
      search.pesquisa.data_maior = { invoice_date: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    }
  }
  if (pesq.gross_total_min || pesq.gross_total_max) {
    if (pesq.gross_total_min) {
      search.pesquisa.valor_menor = { gross_total: pesq.gross_total_max }
    }
    if (pesq.gross_total_max) {
      search.pesquisa.valor_maior = { gross_total: pesq.gross_total_min }
    }
  } else {
    if (pesq.gross_total) {
      if (a[1] === 'DESC') {
        search.pesquisa.valor_menor = { gross_total: pesq.gross_total }
      } else {
        search.pesquisa.valor_maior = { gross_total: pesq.gross_total }
      }
    }
  }
  if (pesq.contato) {
    search.pesquisa.pesquisa_avancada.contato = { texto: { nome: pesq.contato, nif: pesq.contato, codigo: pesq.contato } }
  }
  if (pesq.invoice_number !== '') {
    search.pesquisa.texto.invoice_number = pesq.invoice_number
    search.pesquisa.texto.documento_original = pesq.invoice_number
  }
  if (pesq.loja_id) {
    search.pesquisa.loja_id = pesq.loja_id
  }
  if (pesq.modulo_estado_id) {
    search.pesquisa.modulo_estado_id = pesq.modulo_estado_id
  }
  if (pesq.em_aberto) {
    search.pesquisa.em_aberto = true
  }
  if (a[0] === 'contato') {
    search.pesquisa.order_by = { 'contatos.nome': a[1] }
  } else {
    search.pesquisa.order_by[a[0]] = a[1]
  }
  if (pesq.contato_id > 0) {
    search.contato_id = pesq.contato_id
  }
  if (pesq.tipificacao) {
    search.tipificacao = pesq.tipificacao
  }
  if (pesq.invoice_number1 !== '') {
    search.pesquisa.pesquisa_avancada.origem_documento = { texto: { invoice_number: pesq.invoice_number1 } }
  }
  if (pesq.pos_id > 0) {
    search.pesquisa.pos_id = pesq.pos_id
  }
  if (Array.isArray(pesq.pos)) {
    if (pesq.pos.length) {
      search.pesquisa.pos = pesq.pos
    }
  }
  // saft
  if (pesq.data) {
    if (a[1] === 'DESC') {
      search.pesquisa.data_menor = { created_at: pesq.data }
    } else {
      search.pesquisa.data_maior = { created_at: pesq.data }
    }
  }
  if (pesq.tipo1) {
    search.pesquisa.tipo = pesq.tipo1
  }
  if (pesq.info !== '') {
    search.pesquisa.texto.info = pesq.info
  }
  if (pesq.resultado !== '') {
    search.pesquisa.texto.resultado = pesq.resultado
  }
  if (Array.isArray(pesq.tipificacoes)) {
    if (pesq.tipificacoes.length) {
      if (pesq.tipificacoes[0] !== 'faturas' && pesq.tipificacoes[0] !== 'orcamentos' && pesq.tipificacoes[0] !== 'encomendas' && pesq.tipificacoes[0] !== 'guias' && pesq.tipificacoes[0] !== 'compras') {
        search.pesquisa.tipo_documento_id = pesq.tipificacoes
      }
    }
  }
  if (Array.isArray(pesq.series)) {
    if (pesq.series.length) {
      if (pesq.series[0] !== 'series') {
        search.pesquisa.serie_id = pesq.series
      }
    }
  }
  if (pesq.limite) {
    search.limite = pesq.limite
  }
  */
  let search = { pesquisa: { order_by: {}, pesquisa_avancada: {} } }
  let a = (pesq.ordenar || '').split('-')
  search.pesquisa.visivel = pesq.visivel
  if (pesq.itens && pesq.texto) {
    if (pesq.texto.length > 2) {
      search.pesquisa.texto = { codigo: pesq.texto, descricao: pesq.texto, codigo_barras: pesq.texto }
    }
  } else {
    if (pesq.texto) {
      if (pesq.texto.length > 2) {
        search.pesquisa.texto = pesq.texto
      }
    }
  }

  if (pesq.info || pesq.resultado) {
    search.pesquisa.texto = { info: pesq.info, resultado: pesq.resultado }
  } else {
    if (pesq.info) {
      search.pesquisa.texto = {}
      search.pesquisa.texto.info = pesq.info
    }
    if (pesq.resultado) {
      search.pesquisa.texto = {}
      search.pesquisa.texto.resultado = pesq.resultado
    }
  }

  if (pesq.invoice_number) {
    search.pesquisa.texto = { invoice_number: pesq.invoice_number, documento_original: pesq.invoice_number }
  }
  if (pesq.pais !== '') {
    search.pesquisa.pais = pesq.pais
  }
  if (pesq.retencao) {
    search.pesquisa.retencao = true
  }
  if (pesq.loja) {
    if (Array.isArray(pesq.loja)) {
      if (pesq.loja.length && pesq.loja[0] !== 'lojas') {
        search.pesquisa.loja = pesq.loja
      }
    } else {
      if (pesq.loja > 0) {
        search.pesquisa.loja = [pesq.loja]
      }
    }
  }
  if (pesq.tipo) {
    if (pesq.tipo.length) {
      search.pesquisa.tipo = pesq.tipo
    }
  }

  if ((pesq.produto && pesq.servico && pesq.taxa) || (!pesq.produto && !pesq.servico && !pesq.taxa)) {
    // nao faz nada
  } else {
    if (pesq.servico) {
      search.pesquisa.servico = true
    } else {
      search.pesquisa.servico = false
    }
    if (pesq.taxa) {
      search.pesquisa.taxa = true
    } else {
      search.pesquisa.taxa = false
    }
  }
  if (pesq.movimenta_stock && pesq.produto) {
    search.pesquisa.movimenta_stock = true
  }
  if (pesq.produto_composto && pesq.produto) {
    search.pesquisa.produto_composto = true
  }
  /*
  if (pesq.descricao !== '') {
    search.pesquisa.texto.descricao = pesq.descricao
  }
  */
  if (pesq.categoria) {
    if (pesq.categoria.length) {
      search.pesquisa.categoria = pesq.categoria
    }
  }

  if (documento) {
    search.pesquisa.pesquisa_avancada.tipoDocumento = { categoria: documento }
  }

  let dd
  if (pesq.prazo_vencimento_min) {
    dd = new Date(pesq.prazo_vencimento_min)
    search.pesquisa.data_maior = { prazo_vencimento: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
  }
  if (pesq.prazo_vencimento_max) {
    dd = new Date(pesq.prazo_vencimento_max)
    search.pesquisa.data_menor = { prazo_vencimento: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
  }

  if (pesq.invoice_date_min || pesq.invoice_date_max) {
    if (pesq.invoice_date_min) {
      dd = new Date(pesq.invoice_date_min)
      search.pesquisa.data_maior = { invoice_date: pesq.invoice_date_min }
    }
    if (pesq.invoice_date_max) {
      dd = new Date(pesq.invoice_date_max)
      search.pesquisa.data_menor = { invoice_date: pesq.invoice_date_max }
    }
  }
  if (pesq.invoice_date) {
    dd = new Date(pesq.invoice_date)
    if (a[1] === 'DESC') {
      search.pesquisa.data_menor = { invoice_date: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    } else {
      search.pesquisa.data_maior = { invoice_date: dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) }
    }
  }

  if (pesq.gross_total_max) {
    search.pesquisa.valor_menor = { gross_total: pesq.gross_total_max }
  }
  if (pesq.gross_total_min) {
    search.pesquisa.valor_maior = { gross_total: pesq.gross_total_min }
  }

  if (pesq.contato) {
    search.pesquisa.pesquisa_avancada.contato = { texto: { nome: pesq.contato, nif: pesq.contato, codigo: pesq.contato } }
  }
  if (pesq.contatos) {
    search.pesquisa.contato_id = pesq.contatos
  }
  if (pesq.loja_id) {
    search.pesquisa.loja_id = pesq.loja_id
  }
  if (pesq.modulo_estado_id) {
    search.pesquisa.modulo_estado_id = pesq.modulo_estado_id
  }
  if (pesq.em_aberto) {
    search.pesquisa.em_aberto = true
  }
  if (pesq.pago) {
    search.pesquisa.pago = false
  }
  if (a[0] === 'contato') {
    search.pesquisa.order_by = { 'contatos.nome': a[1] }
  } else {
    search.pesquisa.order_by[a[0]] = a[1]
  }
  if (Array.isArray(pesq.contato_id)) {
    if (pesq.contato_id.length) {
      search.pesquisa.contato_id = pesq.contato_id.map(a => a.id)
    }
  } else {
    if (pesq.contato_id > 0) {
      search.contato_id = pesq.contato_id
    }
  }
  if (pesq.tipificacao) {
    search.tipificacao = pesq.tipificacao
  }
  if (pesq.invoice_number1 !== '') {
    search.pesquisa.pesquisa_avancada.origem_documento = { texto: { invoice_number: pesq.invoice_number1 } }
  }
  if (pesq.pos_id > 0) {
    search.pesquisa.pos_id = pesq.pos_id
  }
  if (Array.isArray(pesq.pos)) {
    if (pesq.pos.length) {
      search.pesquisa.pos = pesq.pos
    }
  }
  // saft
  if (pesq.data) {
    if (a[1] === 'DESC') {
      search.pesquisa.data_menor = { created_at: pesq.data }
    } else {
      search.pesquisa.data_maior = { created_at: pesq.data }
    }
  }
  if (pesq.tipo1) {
    search.pesquisa.tipo = pesq.tipo1
  }
  if (Array.isArray(pesq.tipificacoes)) {
    if (pesq.tipificacoes.length) {
      if (pesq.tipificacoes[0] !== 'faturas' && pesq.tipificacoes[0] !== 'orcamentos' && pesq.tipificacoes[0] !== 'encomendas' && pesq.tipificacoes[0] !== 'guias' && pesq.tipificacoes[0] !== 'compras') {
        search.pesquisa.tipo_documento_id = pesq.tipificacoes
      }
    }
  }
  if (Array.isArray(pesq.series)) {
    if (pesq.series.length) {
      if (pesq.series[0] !== 'series') {
        search.pesquisa.serie_id = pesq.series
      }
    }
  }
  if (pesq.limite) {
    search.limite = pesq.limite
  }
  if (Array.isArray(pesq.metas)) {
    search.pesquisa.meta = {}
    for (const key in pesq.metas) {
      if (metas.find(a => a.meta_key === key).meta_value === 'checkbox') {
        if (pesq.metas[key] === true) {
           search.pesquisa.meta[key] = 'true'
        }
      } else {
        if (pesq.metas[key]) {
          search.pesquisa.meta[key] = pesq.metas[key]
        }
      }
    }
  }
  return search
}
