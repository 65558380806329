<template>
  <div class="modal-mask" v-if="$store.state.modalAT" transition="modal" id="suporteAtModal">
    <div class="modalScrollMobile">
      <div class="modal-wrapper">
        <div class="modal-fundo"></div>
        <div class="modal-container padding-0 modal-800">
          <div class="modal-header">
            <h3 class="modal-title">{{l('atxmlexportar')}}<button @click="SET_SUPORTE_AT_MODAL({ok: 0})" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
          </div>
          <div class="modal-body margin-0 padding-top-0 padding-bottom-15 text-center">
            <button v-if="new Date().getTime() < new Date(form1.data_carga).getTime()" type="button" @click="atxmlexportarFN()" class="btn btn-primary margin-10 btn-block-mobile" id="at_exportar"><i class="glyphicon glyphicon-download-alt margin-right-5"></i> {{l('obter_ficheiro')}}</button>

            <span class="btn btn-primary fileinput-button margin-10 btn-block-mobile"><i class="glyphicon glyphicon-download-alt margin-right-5"></i><span> {{l('carregar_resultado')}}</span><input type="file" @change="onFileChangeAT($event)"></span>

            <div class="clearfix"></div>
            <p class="ajuda margin-top-5 text-justify" v-html="l('suporte_xml_at')"></p>
            <div class="clearfix"></div>
            <button class="btn btn-default btn-pure pull-right" type="button" @click="SET_SUPORTE_AT_MODAL({ok: 0})">{{$t('cancelar')}}</button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'modalXmlAt',
  data () {
    return {
      form1: {}
    }
  },
  watch: {
    '$store.state.modalAT' (a) {
      if (a === 1) {
        this.form1 = this.$store.state.modalATForm
      }
    }
  },
  methods: {
    ...mapMutations([
      'DOWNLOAD',
      'SET_LOAD',
      'SET_SUPORTE_AT_MODAL',
      'SET_TOAST'
    ]),
    l (a) {
      return this.$t('documentos.' + a)
    },
    onFileChangeAT (e) {
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      let data = new window.FormData()
      data.append('file', files[0])

      this.SET_LOAD(true)
      e.target.value = ''
      this.axios.post((this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/resultado/guia', data).then((res) => {
        this.suporteAtModal = false
        let a = this.$store.state.modalATForm
        a.comunicado = 1
        if (res.data.success) {
          a.codigo_at = res.data.success[0].codigo_at
        }
        this.SET_SUPORTE_AT_MODAL({ res: a, ok: 2 })
        this.SET_TOAST({ msg: this.l('atxmlexportar_sucesso') })
      }, () => {
        this.$swal('', this.l('atxmlexportar_erro'), 'error')
        this.SET_LOAD()
      })
    },
    atxmlexportarFN () {
      this.DOWNLOAD({ url: (this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/xml', data: { id: this.form1.id }, name: this.form1.invoice_number + '.xml', type: 'xml' })
    }
  }
}
</script>
