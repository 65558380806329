<template>
  <div>
    <!--covnerter normal-->
    <div class="modal-mask" v-if="converterModal" transition="modal" id="modal">
      <div class="modalScrollMobile">
        <div class="modal-wrapper">
          <div class="modal-fundo"></div>
          <div class="modal-container padding-0" style="width:96%;z-index:2;position:relative">
            <div class="modal-header">
              <h3 class="modal-title">{{l('converter')}}<sup class="text-success">{{totalModal}}</sup><button @click="converterModal = false" type="button" class="btn btn-default btn-pure btn-lg modal-fechar"><i class="glyphicon glyphicon-remove"></i></button></h3>
            </div>
            <div class="modal-body margin-0 padding-vertical-0">

              <div :class="{'panel margin-bottom-15 visible-xs visible-sm': true, 'padding-bottom-15': pesquisar}" >
                <div class="panel-heading">
                  <h3 class="panel-title mao" @click="pesquisar = !pesquisar">{{$t('pesquisar')}}
                    <span :class="{'pull-right': true, caret: true, caret1: pesquisar}" href="javascript:void(0)"></span>
                  </h3>
                </div>
                <div class="panel-body container-fluid padding-0" v-if="pesquisar">
                  <div class="col-md-12 form-group form-material">

                    <div class="form-group form-material" v-if="$store.state.lojastotal > 1">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('loja_')}}</span>
                        <select class="form-control foco" @keydown.enter.prevent="FOCO" v-model="pesq.loja" id="loja11">
                          <option value=""></option>
                          <option v-for="(lj, index) in $store.state.lojas" :key="index" :value="lj.id">{{lj.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group form-material">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('serie')}}</span>
                        <input autocomplete="off" type="text" @keydown.enter.prevent="FOCO" class="form-control foco coluna_total" v-model="pesq.invoice_number1" :placeholder="$t('serie')" id="serie1ew1" v-texto />
                      </div>
                    </div>
                    <div class="form-group form-material">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('data')}}</span>
                        <!--<input autocomplete="off" @keydown.enter.prevent="FOCO" maxlength="10" pattern="[0-9]{2}\/[0-9]{2}\/[0-9]{4}$" min="2017-01-01" :max="ano + '-12-31'" type="date" class="form-control foco coluna_datas" v-model="pesq.invoice_date" :placeholder="$t('data')" id="invoice_date11" v-data />-->
                        <date-picker :clearable="true" v-model="pesq.invoice_date" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="width-120 foco" :placeholder="$t('data')" id="invoice_number11111"></date-picker>
                      </div>
                    </div>
                    <!--<div class="form-group form-material" v-if="$store.state.page2 != 'guias'">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('total')}}</span>
                        <input autocomplete="off" @keydown.enter.prevent="FOCO" type="text" class="form-control foco coluna_total" v-model="pesq.gross_total" :placeholder="$t('total')" id="gross_total11" v-decimal />
                      </div>
                    </div>-->
                    <div class="clearfix"></div>
                    <div class="form-group form-material">
                      <div class="input-group">
                        <span class="input-group-addon">{{$t('ordenar')}}</span>
                        <select class="form-control foco" @keydown.enter.prevent="FOCO([$event, 1])" v-model="pesq.ordenar" id="ordenar11">
                          <option value="invoice_number-ASC">{{$t('serie')}} {{$t('asc')}}</option>
                          <option value="invoice_number-DESC">{{$t('serie')}} {{$t('desc')}}</option>
                          <option value="invoice_date-ASC">{{$t('data')}} {{$t('asc')}}</option>
                          <option value="invoice_date-DESC">{{$t('data')}} {{$t('desc')}}</option>
                          <option value="gross_total-ASC">{{$t('total')}} {{$t('asc')}}</option>
                          <option value="gross_total-DESC">{{$t('total')}} {{$t('desc')}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="clearfix"></div>
                    <button @click="pesquisar = false; formVer(1, true);" class="btn btn-primary pull-right btn-block-mobile"><i class="glyphicon glyphicon-search margin-right-5"></i>{{$t('pesquisarBotao')}}</button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div>
                  <table id="tabelaFixa1" class="table table-hover tabelaVerde width-full table-condensed table-striped padding-bottom-0">
                    <thead id="tabelaFixaThead1" class="hidden-xs hidden-sm">
                      <tr>
                        <th class="width-45"></th>
                        <th v-if="$store.state.lojastotal > 1" class="text-left width-150"><div>{{$t('loja_')}}</div></th>
                        <th><a href="javascript:void(0)" @click="formUrl1(1, 'invoice_number')" class="text-left"><i v-if="pesq.ordenar == 'invoice_number-ASC' || pesq.ordenar == 'invoice_number-DESC'" :class="{'seta glyphicon': true,'glyphicon-chevron-up': pesq.ordenar == 'invoice_number-ASC','glyphicon-chevron-down': pesq.ordenar == 'invoice_number-DESC'}"></i>{{$t('serie')}}</a></th>
                        <th class="width-110"><a href="javascript:void(0)" @click="formUrl1(1, 'invoice_date')" class="text-left"><i v-if="pesq.ordenar == 'invoice_date-ASC' || pesq.ordenar == 'invoice_date-DESC'" :class="{'seta glyphicon': true,'glyphicon-chevron-up': pesq.ordenar == 'invoice_date-ASC','glyphicon-chevron-down': pesq.ordenar == 'invoice_date-DESC'}"></i>{{$t('data')}}</a></th>

                        <th><a href="javascript:void(0)" @click="formUrl1(1, 'gross_total')" class="text-right"><i v-if="pesq.ordenar == 'gross_total-ASC' || pesq.ordenar == 'gross_total-DESC'" :class="{'seta glyphicon': true,'glyphicon-chevron-up': pesq.ordenar == 'gross_total-ASC','glyphicon-chevron-down': pesq.ordenar == 'gross_total-DESC'}"></i>{{$t('total')}}</a></th>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <label class="switch"><input type="checkbox" v-model="todos" @change="selTodos"><i></i></label>
                        </td>
                        <td class="form-group" v-if="$store.state.lojastotal > 1">
                          <select class="form-control foco" @keydown.enter.prevent="FOCO" @change="formUrl1(1, 'loja')" v-model="pesq.loja" id="loja132">
                            <option value=""></option>
                            <option v-for="(lj, index) in $store.state.lojas" :key="index" :value="lj.id">{{lj.nome}}</option>
                          </select>
                        </td>
                        <td class="form-group">
                          <input autocomplete="off" type="text" class="form-control foco" @keydown.enter.prevent="FOCO" v-model="pesq.invoice_number1" :placeholder="$t('n_documento')" @keyup="formUrl(1, 'invoice_number1')" v-texto />
                        </td>
                        <td class="form-group">
                          <!--<input autocomplete="off" type="date" class="form-control foco coluna_datas" @keydown.enter.prevent="FOCO" v-model="pesq.invoice_date" :placeholder="$t('data')" @change="formUrl(1, 'invoice_date')" id="invoice_date1" />-->
                          <date-picker :clearable="true" v-model="pesq.invoice_date" :editable="false" type="date" format="YYYY-MM-DD" :lang="$store.state.langData" class="width-120 foco" :placeholder="$t('data')" @change="formUrl(1, 'invoice_date')" id="invoice_number111"></date-picker>
                        </td>
                        <td class="form-group">
                          <!--<input autocomplete="off" type="text" class="form-control foco coluna_total" @keydown.enter.prevent="FOCO([$event, 1])" v-model="pesq.gross_total" :placeholder="$t('total')" @keyup="formUrl(1, 'gross_total')" id="gross_total1" v-decimal />-->
                        </td>
                      </tr>
                    </thead>
                    <tbody id="tbodyFixa1" v-if="convert.length">
                      <tr v-for="(dt, index) in convert" :key="index" @click="dt.sel = !dt.sel">
                        <td class="visible-xs visible-sm">
                          <p>
                            <label class="switch pull-left"><input type="checkbox" v-model="dt.sel" @change="dt.sel=!dt.sel"><i></i></label>
                            <b class="pull-right">{{dt.origem_documento.invoice_number}}</b>
                          </p>
                          <div class="clearfix"></div>
                          <div class="margin-top-5">
                            <span class="pull-left" v-if="$store.state.lojastotal > 1"><label class="top-2 label margin-right-10 text-uppercase" :style="'background: ' + $store.state.corSimbLojas[dt.origem_documento.loja_id].c">{{$store.state.corSimbLojas[dt.origem_documento.loja_id].s}}</label></span>
                            <span class="pull-left text-uppercase margin-top-5">{{dt.origem_documento.invoice_date | data}}</span>
                            <h4 class="pull-right">{{MOEDA}} <b v-valor2:[dt.origem_documento.gross_total]></b></h4>
                          </div>
                        </td>

                        <td class="text-center hidden-xs hidden-sm">
                          <label class="switch"><input type="checkbox" v-model="dt.sel" @change="dt.sel=!dt.sel"><i></i></label>
                        </td>
                        <td class="text-center hidden-xs hidden-sm" v-if="$store.state.lojastotal > 1">
                          <label class="label text-uppercase" :style="'background: ' + $store.state.corSimbLojas[dt.origem_documento.loja_id].c">{{$store.state.corSimbLojas[dt.origem_documento.loja_id].s}}</label>
                        </td>
                        <td class="text-left mao hidden-xs hidden-sm">{{dt.origem_documento.invoice_number}}</td>
                        <td class="text-left mao hidden-xs hidden-sm text-uppercase">{{dt.origem_documento.invoice_date | data}}</td>
                        <td class="text-right mao hidden-xs hidden-sm"><h4>{{MOEDA}} <b v-valor2:[dt.origem_documento.gross_total]></b></h4></td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <tr>
                        <td colspan="100%">
                          <div class="alert alert-danger alert-dismissible text-justify margin-top-50">
                            {{l('sem_documentos')}}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <paginacao1-paginacao1 :pag="pag" :lastpage="lastpage" @formUrl="formVer"></paginacao1-paginacao1>
              </div>
            </div>

            <div class="modal-footer text-center">
              <button class="btn-block-mobile btn btn-default btn-pure pull-left" type="button" @click="converterModal = false">{{$t('cancelar')}}</button>
              <button class="btn-block-mobile btn btn-primary pull-right" type="button" @click="multDocsConvert()">{{l('converter')}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--converter compras-->
    <div class="modal-mask" v-if="converterModalCompras" transition="modal" id="modalCompras">
      <div class="modalScrollMobile">
        <div class="modal-wrapper">
          <div class="modal-fundo" @click="converterModalCompras = false"></div>
          <div class="modal-container padding-0" style="width:96%;z-index:2;position:relative">
            <div class="modal-header">
              <h3 class="modal-title" v-if="convert.length === 1">{{l('seleccione_preco_produto')}}</h3>
              <h3 class="modal-title" v-if="convert.length > 1">{{l('seleccione_precos_produtos')}}</h3>
            </div>
            <div class="modal-body margin-0 padding-vertical-0">

              <div class="row">
                <div class="table-responsive">
                  <table class="table table-hover tabelaVerde width-full table-condensed table-striped">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <b>{{l('t_codigo')}}</b> {{l('t_descricao')}}
                        </th>
                        <th class="text-right maxwidth50">
                          <span v-if="PrecoIVA">{{l('t_preco_iva')}} ({{MOEDA}})</span>
                          <span v-else>{{l('t_preco')}} ({{MOEDA}})</span>
                        </th>
                        <th class="text-right maxwidth50" v-if="0">
                          {{l('t_iva')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="convert[0]">
                      <tr v-for="(dt, index) in convert" :key="index">
                        <td><b>{{dt.codigo}}</b> {{dt.descricao}}</td>
                        <td v-if="!PrecoIVA">
                          <select :id="'guiaPreco' + index" class="form-control" @keydown.enter.prevent="FOCO" v-model="dt.preco_unitario">
                            <option :value="dtt.preco_sem_iva" v-for="(dtt, index1) in dt.precos" :key="index1">{{MOEDA}} <b v-valor2:[dtt.preco_sem_iva]></b> - {{dtt.preco_nome}}</option>
                          </select>
                        </td>
                        <td v-else>
                          <select :id="'guiaPreco' + index" class="form-control" @keydown.enter.prevent="FOCO" v-model="dt.preco_unitario_iva">
                            <option :value="dtt.preco_com_iva" v-for="(dtt, index1) in dt.precos" :key="index1">{{MOEDA}} <b v-valor2:[dtt.preco_com_iva]></b> - {{dtt.preco_nome}}</option>
                          </select>
                        </td>
                        <!--<td class="text-right maxwidth50" v-if="0">
                          <select class="form-control" @keydown.enter.prevent="FOCO" style="max-width:100px;" v-model="dt.imposto_id">
                            <option value="0" :selected="dt.imposto == 0">{{l('isento_iva')}}</option>
                            <option v-for="(dtt, index1) in $store.state['impostos']" :key="index1" :value="dtt.id">{{dtt.valor}}</option>
                          </select>
                          <div class="clearfix"></div>
                          <select class="form-control margin-top-10" @keydown.enter.prevent="convert.length - 1 == index ? comprasConvert() : FOCO" style="max-width:100px;" v-if="!dt.imposto_id" v-model="dt.motivo_isencao_id">
                            <option value="" disabled>{{l('razao_isencao_iva')}}</option>
                            <option v-for="(dtt, index1) in $store.state.isencao" :key="index1" :value="dtt.id">{{dtt.motivo}}</option>
                          </select>
                        </td>-->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="modal-footer text-center">
              <button id="confirmarPrecos" class="btn btn-primary pull-right btn-block-mobile" type="button" @click="comprasConvert()">{{$t('confirmar')}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Principal, formUrl, formUrl1, formVer } from './../../../filters/index'
import { movimentosPendentes } from './../../../resources/fn'

export default {
  name: 'modal-converter',
  data () {
    return {
      converterModal: false,
      converterModalCompras: false,
      pesq: {
        loja: '',
        contato_id: '',
        tipificacao: '',
        invoice_number: '',
        invoice_date: '',
        gross_total: '',
        ordenar: 'invoice_date-DESC'
      },
      pesq1: {},
      documents: [],
      pag: 1,
      lastpage: 1,
      totalModal: '',
      convert: [],
      todos: false,
      form1: {},
      PrecoIVA: 0,
      pesquisar: '',
      formUrl: '',
      ano: 2016
    }
  },
  computed: {
    MOEDA: function () {
      return this.$auth.check() ? this.$store.getters.MOEDA : '€'
    }
  },
  watch: {
    '$store.state.converter' (val) {
      try {
        if (val.tipo_documento_id > 0) {
          this.form1 = val
          this.series = this.$store.state['series']
          this.PrecoIVA = Principal(this.series, this.form1.serie_id).iva_incluido
          this.pesq = {
            contato_id: this.form1.contato_id,
            tipificacao: [Principal(this.$store.state['tipos-documento'], this.form1.tipo_documento_id, 1).tipificacao],
            loja: this.$store.state.loja,
            invoice_number: '',
            invoice_date: '',
            gross_total: '',
            ordenar: 'invoice_date-DESC'
          }
          this.pesq1 = Object.assign({}, this.pesq)
          this.converterModal = true
          this.nivelModal = false
          this.formUrl1(1)
          this.todos = false
          this.convert = []
          this.SET_CONVERTER({})
          this._.delay(function () { window.scrollTo(0, 0) }, 100)
        }
      } catch (e) {
        console.log(e)
      }
    },
    '$store.state.converterCompras1' (val) {
      let i = this.$store.state.series.length
      while (--i >= 0) {
        if (this.$store.state.series[i].id === this.form1.serie_id) {
          this.PrecoIVA = this.$store.state.series[i].iva_incluido
          break
        }
      }
      i = val.length
      if (i) {
        this.SET_LOAD(true)
        while (--i >= 0) {
          val[i].preco_unitario = val[i].precos[0].preco_sem_iva
          val[i].preco_unitario_iva = val[i].precos[0].preco_com_iva
        }
        this.convert = val
        this.SET_LOAD()
        /*
          // vou buscar os produtos que tou a converter nas compras
          itens(this.axios, {pesquisa: {id: val}}).then((res) => {
            let i = res.data.data.length
            let temPrecos = false
            while (--i >= 0) {
              // tem precos
              if (res.data.data[i].precos.length > 1) {
                // if (res.data.data[i].precos[0].preco_sem_iva > 0) {
                temPrecos = true
                res.data.data[i].preco_unitario = res.data.data[i].precos[0].preco_sem_iva
                res.data.data[i].preco_unitario_iva = res.data.data[i].precos[0].preco_com_iva
                /*
                res.data.data[i].imposto = this.ISENTO ? 0 : Principal(this.impostos, res.data.data[i].imposto_id).valor
                if (!this.imposto) {
                  res.data.data[i].motivo_isencao_id = this.RAZOESISENCAO
                }
                * /
              } else {
                delete res.data.data[i]
              }
            }
            if (temPrecos) {
              this.converterModalCompras = true
              this.convert = []
              i = res.data.data.length
              while (--i >= 0) {
                if (res.data.data[i]) {
                  this.convert.unshift(res.data.data[i])
                }
              }
            }
            this.SET_LOAD()
            this.load = false
          }, (er) => {
            this.SET_LOAD()
            this.load = false
            this.converterModalCompras = false
            this.$swal('', this.l('naoConverteuPrecosCompras'), 'error')
          })
        */
        if (this.convert.length) {
          this.converterModalCompras = true
        } else {
          this.converterModalCompras = false
        }
        this._.delay(function () { window.scrollTo(0, 0) }, 100)
        this.SET_CONVERTER_COMPRAS1([])
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.formUrl = this._.debounce(function (pag, order) {
      if (formUrl(order, this.pesq, this.pesq1)) {
        this.formVer(pag)
      }
    }, 550)
    this.ano = parseInt(this.$store.state.utilizador.empresa.created_at) || 2016
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'FOCO',
      'SET_CONVERTER',
      'SET_CONVERTER_RESULT',
      'SET_CONVERTER_RESULT1',
      'SET_CONVERTER_COMPRAS',
      'SET_CONVERTER_COMPRAS1',
      'SET_DOCUMENTO_BTS'
    ]),
    l (a) {
      return this.$t('documentos.' + a)
    },
    Principal (ar, id) {
      return Principal(ar, id)
    },
    formUrl1 (pag, order) {
      if (formUrl1(order, this.pesq, this.pesq1)) {
        this.formVer(pag)
      }
    },
    formVer (pag) {
      window.scrollTo(0, 0)
      this.SET_LOAD(true)
      this.pesq1 = Object.assign({}, this.pesq)
      this.todos = false
      let dta = Object.assign({}, this.pesq)
      if (dta.invoice_date) {
        let dd = new Date(dta.invoice_date)
        dta.invoice_date = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
      }
      movimentosPendentes(this.axios, formVer(dta), pag, this.$store.state.modulo).then((res) => {
        this.pag = res.data.current_page
        let i = res.data.data.length
        while (--i >= 0) {
          res.data.data[i].sel = false
        }
        this.convert = res.data.data
        i = this.convert.length
        let u
        while (--i >= 0) {
          u = this.form1.produtos.length
          while (--u >= 0) {
            if (this.convert[i].origem_documento.invoice_number === this.form1.produtos[u].convertido) {
              this.convert.splice(i, 1)
              break
            }
          }
        }
        this.SET_LOAD()
        this.totalModal = res.data.total
        this.lastpage = res.data.last_page
      }, () => {
        this.converterModal = false
        this.$swal('', this.l('abrir_documentos_conversao'), 'success')
        this.SET_LOAD()
      })
    },
    multDocsConvert () {
      this.SET_CONVERTER_RESULT1(this.convert)
      this.converterModal = false
    },
    comprasConvert () {
      this.converterModalCompras = false
      this.SET_CONVERTER_COMPRAS(this.convert)
    },
    selTodos () {
      let i = this.convert.length
      while (--i >= 0) {
        this.convert[i].sel = this.todos
      }
    }
  }
}
</script>
