<template>
  <div v-if="$store.state.utilizador1" id="fullscreen1">
    <div class="visible-xs visible-sm">
      <button class="btn btn-primary btn-block margin-bottom-10" v-if="!BotCriar" @click="openForm()">{{l('botao')}}</button>
      <div v-if="BotCriar">
        <div>

          <div class="form-group form-material">
            <div class="input-group">
              <span class="input-group-addon">{{l('campo')}}<span class="verm">*</span></span>
              <input autocomplete="off" type="text" class="form-control foco" v-model="form.meta_key" id="meta_key1" @keydown.enter.prevent="FOCO" maxlength="25" v-texto>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group form-material">
            <div class="input-group">
              <span class="input-group-addon">{{l('tipo')}}<span class="verm">*</span></span>
              <select class="form-control foco" id="meta_select1" v-model="form.meta_value" @keydown.enter.prevent="FOCO" @change="selectTipoFN()">
                <option value="text">{{$t('campos.text')}}</option>
                <option value="textarea">{{$t('campos.textarea')}}</option>
                <option value="select">{{$t('campos.select')}}</option>
                <option value="checkbox">{{$t('campos.checkbox')}}</option>
                <option value="radio">{{$t('campos.radio')}}</option>
                <option value="image">{{$t('campos.image')}}</option>
                <option value="hidden">{{$t('campos.hidden')}}</option>
                <option value="password">{{$t('campos.password')}}</option>
              </select>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="form-group form-material"  v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
            <div class="input-group">
              <span class="input-group-addon">{{l('total_opcoes')}}<span class="verm">*</span></span>
              <input class="form-control foco text-center margin-top-5" v-model="form.option.total" id="total_campos_meta1" v-numerico @keyup="arrayOptions(form)" @keydown.enter.prevent="FOCO" />
            </div>
          </div>
          <div class="clearfix"></div>

          <div v-if="form.option">
            <div v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
              <div class="form-group form-material">
                <div class="input-group">
                  <span class="input-group-addon">{{l('valor')}}<span class="verm">*</span></span>
                  <input :class="{'margin-top-5': index, 'form-control foco': 1}" v-for="(cp, index) in form.option.valores" :key="index" autocomplete="off" type="text" v-model="form.option.valores[index]" :id="'valor_meta1_' + index" @keydown.enter.prevent="FOCO" v-texto>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <bot-form v-show="BotCriar" @cancelar="reset" :botCancelar="true" :botSubmit="true" :botText="form.id ? 'atualizar' : 'guardar'" @submit="submit"></bot-form>
    </div>
    <div id="divTable" :class="{'hidden-xs hidden-sm': BotCriar}">
      <table id="tabelaFixa" class="table table-hover dataTable width-full table-striped table-condensed">
        <thead class="hidden-xs hidden-sm" id="tabelaFixaThead">
          <tr>
            <th class="text-left">{{l('campo')}}</th>
            <th class="text-left width-150">{{l('tipo')}}</th>
            <th class="text-left">{{l('valor')}}</th>
            <th class="width-120"></th>
          </tr>
          <tr v-if="BotCriar">
            <td>
              <input autocomplete="off" type="text" class="form-control foco" v-model="form.meta_key" id="meta_key" @keydown.enter.prevent="FOCO" maxlength="25" v-texto>
            </td>
            <td class="hidden-sm hidden-xs">
              <select class="form-control foco" id="meta_select" v-model="form.meta_value" @keydown.enter.prevent="FOCO" @change="selectTipoFN()">
                <option value="text">{{$t('campos.text')}}</option>
                <option value="textarea">{{$t('campos.textarea')}}</option>
                <option value="select">{{$t('campos.select')}}</option>
                <option value="checkbox">{{$t('campos.checkbox')}}</option>
                <option value="radio">{{$t('campos.radio')}}</option>
                <option value="image">{{$t('campos.image')}}</option>
                <option value="hidden">{{$t('campos.hidden')}}</option>
                <option value="password">{{$t('campos.password')}}</option>
              </select>
              <div v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
                <input class="form-control foco text-center margin-top-5" :placeholder="l('total_opcoes')" v-model="form.option.total" id="total_campos_meta" v-numerico @keyup="arrayOptions(form)" @keydown.enter.prevent="FOCO" />
              </div>
            </td>
            <td class="hidden-sm hidden-xs">
              <div v-if="form.option">
                <div v-if="form.meta_value === 'select' || form.meta_value === 'radio'">
                  <input :class="{'margin-top-5': index, 'form-control foco': 1}" v-for="(cp, index) in form.option.valores" :key="index" autocomplete="off" type="text" v-model="form.option.valores[index]" :id="'valor_meta_' + index" @keydown.enter.prevent="FOCO" v-texto>
                </div>
                <!--<div v-if="form.meta_value === 'order'">
                  <input autocomplete="off" type="text" class="form-control foco" v-model="form.option.valor" id="valor_metaxx" @keydown.enter.prevent="FOCO" v-texto>
                </div>-->
              </div>
            </td>
            <td class="text-center text-center">
              <button type="button" class="btn btn-primary foco btn-block" @click="submit()"><i class="glyphicon glyphicon-floppy-disk"></i></button>
              <div class="clearfix"></div>
              <button type="button" class="btn btn-default btn-pure btn-lg" @click="reset()"><i class="glyphicon glyphicon-remove"></i></button>
            </td>
          </tr>
          <tr v-else>
            <td colspan="4">
              <button class="btn btn-primary btn-block" @click="openForm()">{{l('botao')}}</button>
            </td>
          </tr>
        </thead>
        <draggable-draggable @end="ordena" id="tbodyFixa" v-model="form.produtos" tag="tbody" :disabled="!widthBody" v-if="!BotCriar">
          <tr :id="dt.id" v-for="(dt, index) in res" :key="index">
            <td class="hidden-xs hidden-sm text-left">{{dt.meta_key.toString().split('|')[0]}}</td>
            <td class="hidden-xs hidden-sm text-left">{{$t('campos.' + dt.meta_value)}}</td>
            <td>
              <div class="visible-xs visible-sm">
                <!--<span class="pull-left">{{$t('campos.' + dt.meta_value.toString().split('|')[0])}}</span>-->
                {{dt.meta_key}}
              </div>
              <div v-if="dt.meta_value === 'radio'">
                <div class="margin-top-5" v-for="(dtt,index1) in dt.option.valores" :key="index1">
                  <input disabled :name="'fdddfs' + index + '-' + index1" type="radio">
                  <label :for="'fdfdfs' + index + '-' + index1">{{dtt}}</label>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div v-if="dt.meta_value === 'checkbox'">
                <div class="margin-top-5">
                  <input disabled type="checkbox">
                  <label>{{dt.meta_key}}</label>
                </div>
              </div>
              <div v-if="dt.meta_value === 'select'">
                <select class="form-control">
                  <option disabled v-for="(dtt,index1) in dt.option.valores" :key="index1">{{dtt}}</option>
                </select>
              </div>
              <div v-if="dt.meta_value === 'text' || dt.meta_value === 'order'">
                <input type="text" class="form-control" disabled />
              </div>
              <div v-if="dt.meta_value === 'textarea'">
                <textarea rows="2" class="form-control" disabled></textarea>
              </div>
              <div v-if="dt.meta_value === 'password'">
                <input type="passwword" class="form-control" disabled>
              </div>
              <div v-if="dt.meta_value === 'image'">
                <input type="file" disabled>
              </div>
              <div class="clearfix"></div>
              <div class="visible-xs visible-sm text-right padding-top-10">
                <a class="btn btn-lg btn-pure btn-default" @click="editar(dt)"><i class="glyphicon glyphicon-pencil"></i></a>
                <a class="btn btn-lg btn-pure btn-danger" @click="apagar(dt, index)"><i class="glyphicon glyphicon-trash"></i></a>
                <!--<a v-tooltip.top-start="$t('mover')" class="btn-lg btn-icon btn btn-pure btn-default"><i class="glyphicon glyphicon-move"></i></a>-->
              </div>
            </td>
            <td class="hidden-xs hidden-sm text-right">
              <a class="btn-sm btn-icon btn btn-pure btn-default" @click="editar(dt)"><i class="glyphicon glyphicon-pencil"></i></a>
              <a class="btn-sm btn-icon btn btn-pure btn-danger" @click="apagar(dt, index)"><i class="glyphicon glyphicon-trash"></i></a>
              <a class="btn-sm btn-icon btn btn-pure btn-default"><i class="glyphicon glyphicon-move"></i></a>
            </td>
          </tr>
        </draggable-draggable>
      </table>
    </div>
    <div v-show="!res[0]" class="alert alert-danger alert-dismissible text-justify margin-top-50 margin-bottom-50">
      {{$t('nao_existe_dados')}}
    </div>
  </div>
</template>

<script>
import { metaAd, metaDel, metaUp } from '../../resources/fn'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'pageMetaDados',
  data () {
    return {
      res: [],
      form: { meta_key: '', meta_value: 'text', option: {} },
      arrayOptions: '',
      BotCriar: false,
      widthBody: false
      // {tipo: 'text', total: '', valor: '', valores: []}
    }
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1',
      page2: 'page2'
    }),
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    }
  },
  watch: {
    '$route' () {
      this.init()
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted () {
    this.arrayOptions = this._.debounce(function () {
      if (this.form.option.total > 0) {
        while (this.form.option.total > this.form.option.valores.length) {
          this.form.option.valores.push('')
        }
        while (this.form.option.valores.length > this.form.option.total) {
          this.form.option.valores.pop()
        }
      }
    }, 1000)
    this.init()
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_LOAD',
      'SET_TITULO',
      'SET_AVISO',
      'FOCO',
      'SET_INFO_META'
    ]),
    l (a) {
      return this.$t('meta_dados.' + a)
    },
    handleWindowResize () {
      this.widthBody = document.body.clientWidth > 850
    },
    init () {
      if (this.$store.state.utilizador1 && this.$store.state[this.page + 'Meta1']) {
        this.res = this.$store.state[this.page + 'Meta']
        this.SET_TITULO(this.l('titulo_' + this.page)) // + ' <sup class="text-success">' + this.res.length + '</sup>'
        this.SET_LOAD()
      } else {
        this._.delay(this.init, 100)
      }
    },
    selectTipoFN () {
      this.form.option = { total: '', valores: [''] }
    },
    apagar (dt, index) {
      let self = this
      this.$swal({
        title: self.l('confirmar_apagar'),
        text: self.l('confirmar_desc_' + self.page),
        showCancelButton: true,
        confirmButtonText: self.$t('confirmar'),
        cancelButtonText: self.$t('cancelar'),
        allowOutsideClick: false
      }).then((value) => {
        if (value.value || value.value === '') {
          self.SET_LOAD(true)
          metaDel(self.axios, { id: dt.id }, self.page, self.$store.state.modulo).then(() => {
            self.SET_TOAST({ msg: self.l('sucessoDel') })
            self.res.splice(index, 1)
            self.SET_TITULO(self.l('titulo_' + self.page)) // + ' <sup class="text-success">' + self.res.length + '</sup>'
            self.SET_INFO_META({ res: self.res, campo: self.page })
          }, (er) => {
            self.SET_AVISO(er.response)
          })
        }
      })
    },
    submit () {
      this.SET_LOAD(true)
      let a = this.form.meta_value
      if (this.form.meta_value === 'select' || this.form.meta_value === 'radio') {
        a = this.form.meta_value + '|' + this.form.option.valores.join('|')
      }
      if (this.form.id) {
        metaUp(this.axios, { meta_key: this.form.meta_key, meta_value: a, id: this.form.id }, this.page, this.$store.state.modulo).then(() => {
          let i = this.res.length
          while (--i >= 0) {
            if (this.res[i].id === this.form.id) {
              this.res[i] = { meta_key: this.form.meta_key, meta_value: a, id: this.form.id }
              break
            }
          }
          this.BotCriar = false
          this.SET_TITULO(this.l('titulo_' + this.page)) // + ' <sup class="text-success">' + this.res.length + '</sup>'
          this.SET_INFO_META({ res: this.res, campo: this.page })
          this.SET_TOAST({ msg: this.l('sucesso_up') })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } else {
        metaAd(this.axios, { meta_key: this.form.meta_key, meta_value: a }, this.page, this.$store.state.modulo).then((res) => {
          if (res.data.meta_value.indexOf('|') !== -1) {
            let a = res.data.meta_value.split('|')
            res.data.meta_value = a.shift()
            res.data.option = { total: a.length, valores: a }
          } else {
            res.data.option = { valor: '' }
          }
          this.res.push(res.data)
          this.BotCriar = false
          this.SET_INFO_META({ res: this.res, campo: this.page })
          this.SET_TITULO(this.l('titulo_' + this.page)) // + ' <sup class="text-success">' + this.res.length + '</sup>'
          this.SET_TOAST({ msg: this.l('sucesso') })
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      }
    },
    editar (dt) {
      this.SET_TITULO(this.l('editar'))
      this.BotCriar = true
      this.form = Object.assign({}, dt)
      window.scrollTo(0, window.document.getElementById('tabelaFixaThead').offsetTop)
    },
    ordena (dt) {
      /*
      let temp = Object.assign(this.res[index])
      let total = this.res.length - 1
      // avança para a frente
      if (position === 1) {
        if (index === total) {
          this.$set(this.res, index, Object.assign(this.res[0]))
          this.$set(this.res, 0, temp)
        } else {
          this.$set(this.res, index, Object.assign(this.res[index + 1]))
          this.$set(this.res, index + 1, temp)
        }
      } else {
        // passa para tras uma posição
        if (index === 0) {
          this.$set(this.res, 0, Object.assign(this.res[total]))
          this.$set(this.res, total, temp)
        } else {
          this.$set(this.res, index, Object.assign(this.res[index - 1]))
          this.$set(this.res, index - 1, temp)
        }
      }
      */
      let temp = Array.from(dt.from.children).map(a => a.id)
      /*
      // guardar odem
      let i = this.res.length
      let temp = []
      while (--i >= 0) {
        temp.unshift(this.res[i].id)
      }
      console.log(temp)
      */
      // existe ordem
      if (this.$store.state[this.page + 'MetaOrder']) {
        metaUp(this.axios, { meta_key: 'order', meta_value: temp.join('|'), id: this.$store.state[this.page + 'MetaOrder'].id }, this.page, this.$store.state.modulo).then(() => {
          this.SET_INFO_META({ res: temp.map(a => this.res.find(id => parseInt(a) === id.id)), campo: this.page })
        }, () => {})
      } else {
        // nao existe ordem e cria
        metaAd(this.axios, { meta_key: 'order', meta_value: temp.join('|') }, this.page, this.$store.state.modulo).then(() => {
          this.SET_INFO_META({ res: temp.map(a => this.res.find(id => parseInt(a) === id.id)), campo: this.page })
        }, () => {})
      }
    },
    reset () {
      this.BotCriar = false
      this.SET_TITULO(this.l('titulo_' + this.page)) // + ' <sup class="text-success">' + this.res.length + '</sup>'
    },
    openForm () {
      this.form = { meta_key: '', meta_value: 'text', option: { total: '', valor: '', valores: [] } }
      this.SET_TITULO(this.l('botao'))
      this.BotCriar = true
    }
  }
}
</script>
