import { fnMobile } from './funcoes.js'

export const getters = {
  // ver se tou num tablet ou telemovel
  MOBILE () {
    return fnMobile()
  },
  EMPRESA (state) {
    return state.utilizador.empresa
  },
  VENCIMENTO (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.prazo_vencimento : 0
  },
  USERATIVO (state) {
    return state.utilizador.activo
  },
  RETENCAO (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.retencao : ''
  },
  MOEDA (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.moeda.split('_')[1] : '€'
  },
  PAIS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.pais : 'PT'
  },
  ISENTO (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.isento_iva : false
  },
  CASASDECIMAIS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.casas_decimais : 6
  },
  RAZOESISENCAO (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.razoes_isencao_iva : ''
  },
  ARREDONDAMENTOS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.arredondamentos : false
  },
  REFERENCIAMANUAL (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.referencia_manual : false
  },
  TRIPLICADO (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.triplicado : false
  },
  NOTAS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.nota_documento : false
  },
  MENUCONTACTOS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.categorias_contactos : false
  },
  MENUARTIGOS (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.categorias_artigo : false
  },
  MAILSAFT (state) {
    return state.utilizador.empresa ? state.utilizador.empresa.email_contabilidade : ''
  },
  CONFIGCAMPOS (state) {
    let url = window.location.pathname.split('/')[2]
    switch (url) {
      case 'unidades':
        url = 'unidades-medida'
        break
      case 'pagamento':
      case 'expedicao':
        url = 'metodos-' + url
        break
    }
    return state[url]
  }
}
