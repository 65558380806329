<template>
  <div>
    <transition mode="out-in">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <h1 class="modal-title text-center text-danger"><i class="glyphicon glyphicon-warning-sign"></i> ERROR!</h1>
            </div>
            <div class="modal-body">
              <p class="text-justify">{{$t('error_texto')}}</p>
              <h1 class="text-center">{{errorContador}}</h1>
              <p class="text-center"><button @click="contagem()" class="btn btn-info btn-block">{{$t('error_botao')}}</button></p>
              <p class="text-center"><a href="/" class="btn btn-default btn-block">Home</a></p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      errorContador: 60
    }
  },
  mounted () {
    if (this.$auth.check()) {
      this.errorContador = 60
      this.contagem(1)
    } else {
      let a = this.$t('acabou_sessao')
      this.$swal('', a, 'error').then(function () {
        window.location.assign('/login')
      })
      window.location.assign('/login')
    }
  },
  methods: {
    contagem (ok) {
      this.errorContador -= 1
      if (this.errorContador && ok) {
        this._.delay(this.contagem, 1000, 1)
      } else {
        window.location.reload()
      }
    }
  }
}
</script>
