<template>
  <div v-if="$store.state.utilizador1">

    <div class="modal-mask" v-if="modalEncomendas && PERMISSOES.compras" transition="modal" id="modalEncomendas">
      <div class="modalScrollMobile">
        <div class="modal-wrapper">
          <div class="modal-fundo"></div>
          <div class="modal-container padding-0 modal1">
            <div class="modal-header">
              <h3 class="modal-title" v-if="modalEncomendas == 21">{{l1('encomendar')}}</h3>
              <h3 class="modal-title" v-else>{{l1('orcamentar')}}</h3>
            </div>
            <div class="modal-body margin-0 padding-top-0 padding-bottom-15">
              <div v-if="artigos[0]">
                <div class="col-md-6 col-sm-12 col-lg-12 form-group form-material" v-if="$store.state.lojas.length">
                  <div class="input-group">
                    <span class="input-group-addon">{{$t('loja_')}}</span>
                    <select class="form-control foco" v-model="loja_id" @keydown.enter.prevent="FOCO">
                      <option :value="dt.id" v-for="(dt, index) in $store.state.lojas" :key="index">{{dt.nome}}</option>
                    </select>
                  </div>
                </div>
                <div class="clearfix"></div>
                <h3 class="modal-title margin-bottom-20">
                  {{l1('artigos')}}
                  <button v-if="modalEncomendas == 21" id="limparprecos" v-tooltip.top-start="l('limpar_precos_lojas')" @click="limparPrecosLoja()" class="btn btn-danger btn-xs pull-right"><i class="glyphicon glyphicon-erase"></i></button>
                </h3>
                <div class="padding-bottom-15" v-for="(dt,index) in artigos" :key="index">
                  <input autocomplete="off" type="text" class="m-width-70 form-control foco text-center pull-left" v-model="dt.quantidade" @keydown.enter.prevent="FOCO" :placeholder="$t('quantidade')" v-decimal>
                  <span @click="dt.descricao_extra1 = !dt.descricao_extra1" class="pull-left margin-top-7 margin-left-5 mao">x <i class="glyphicon glyphicon-plus-sign text-primary"></i> {{dt.descricao}}</span>

                  <input autocomplete="off" type="text" class=" m-width-70 form-control foco text-center pull-right" v-model="dt.preco_custo" @keydown.enter.prevent="FOCO" :placeholder="$t('preco')" v-decimal v-if="modalEncomendas == 21">
                  <span class="pull-right margin-top-7 margin-right-5" v-if="modalEncomendas == 21">{{MOEDA}}</span>
                  <div class="clearfix"></div>
                  <textarea autocomplete="off" v-if="dt.descricao_extra1" class="form-control foco margin-top-5" type="text" v-model="dt.descricao_extra" maxlength="150" :placeholder="$t('documentos.descricao_extra')" @keydown.enter.prevent="FOCO"></textarea>
                  <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <textarea autocomplete="off" class="form-control foco margin-top-5" type="text" v-model="observacoes" maxlength="200" :placeholder="$t('documentos.observacoes')" @keydown.enter.prevent="FOCO"></textarea>
                <div class="clearfix"></div>
                <p class="margin-top-15" v-if="modalEncomendas == 21">{{l1('preco_sem_iva_descricao')}}</p>
                <div class="clearfix margin-bottom-20"></div>
              </div>
              <div class="alert alert-danger" v-else>
                {{l1('fornecedor_sem_artigos_associados')}}
              </div>
              <button class="btn btn-default btn-pure pull-left" type="button" @click="modalEncomendas = ''">{{$t('cancelar')}}</button>
              <button v-if="artigos[0]" class="btn btn-primary pull-right" type="submit" @click="criarDocumento()">{{$t('confirmar')}}</button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="margin-bottom-10" v-if="form.usado && PERMISSOES.relatorio_pagamentos_em_falta && PERMISSOES.relatorio_saldo_por_cliente && PERMISSOES.relatorio_facturacao_por_cliente && PERMISSOES.relatorio_extracto_pagamentos && PERMISSOES.relatorios && !$store.state.modulo" :id="form.id">
      <div class="loader" v-if="relatorios[0]"></div>
      <div v-else-if="relatorios.vendas && relatorios.compras && relatorios.compras && relatorios.vendas_vencido && relatorios.compras_vencido">
        <div class="col-lg-3 col-sm-6 col-md-6 vendas padding-5 padding-bottom-0" v-if="relatorios.vendas[0].total_documentos">
          <div id="widgetLineareaOne" :class="{'widget widget-shadow bill-card-green margin-bottom-5': 1, 'ocultarwidget': relatoriosVendas, 'mostrarwidget': !relatoriosVendas}">
            <div class="widget-content texto-branco">
              <div class="padding-20">
                  <div class="clearfix"></div>
                  <!--<span :class="{'pull-right caret mao': 1, 'caret1': !relatoriosVendas}" @click="relatoriosVendas = !relatoriosVendas"></span>-->
                  <div class="pull-left padding-vertical-10">
                    <h2 class="text-uppercase texto-branco mao" @click="relatoriosVendas = !relatoriosVendas">{{l('vendas')}}</h2>
                  </div>
                  <div class="clearfix margin-bottom-20"></div>
                  <span class="pull-left">{{l('total_vendido')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].gross_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_recebido')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].total_pago]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left" v-if="relatorios.notas_de_credito[0].gross_total > 0">{{l('notas_credito')}}</span>
                  <span class="pull-right font-size-23 b300" v-if="relatorios.notas_de_credito[0].gross_total > 0">{{MOEDA}} <b v-valor2:[relatorios.notas_de_credito[0].gross_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_divida')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].total_divida]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left" v-if="relatorios.vendas[0].tax_total > 0">{{l('tax_total')}}</span>
                  <span class="pull-right font-size-23 b300" v-if="relatorios.vendas[0].tax_total > 0">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].tax_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('net_total')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].net_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left" v-if="relatorios.vendas[0].retencao_total > 0">{{l('retencao_total')}}</span>
                  <span class="pull-right font-size-23 b300" v-if="relatorios.vendas[0].retencao_total > 0">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].retencao_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left" v-if="relatorios.vendas[0].total_desconto > 0">{{l('total_desconto')}}</span>
                  <span class="pull-right font-size-23 b300" v-if="relatorios.vendas[0].total_desconto > 0">{{MOEDA}} <b v-valor2:[relatorios.vendas[0].total_desconto]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_documentos')}}</span>
                  <span class="pull-right font-size-23 b300">{{relatorios.vendas[0].total_documentos + relatorios.notas_de_credito[0].total_documentos}}</span>
                  <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 vendas padding-5 padding-bottom-0" v-if="relatorios.compras[0].total_documentos">
          <div id="widgetLineareaOne" :class="{'widget widget-shadow bill-card-green margin-bottom-5': 1, 'ocultarwidget': relatoriosCompras, 'mostrarwidget': !relatoriosCompras}">
            <div class="widget-content texto-branco">
              <div class="padding-20">
                  <div class="clearfix"></div>
                  <!--<span :class="{'pull-right caret mao': 1, 'caret1': !relatoriosCompras}" @click="relatoriosCompras = !relatoriosCompras"></span>-->
                  <div class="pull-left padding-vertical-10">
                    <h2 class="text-uppercase texto-branco mao" @click="relatoriosCompras = !relatoriosCompras">{{l('compras')}}</h2>
                  </div>
                  <div class="clearfix margin-bottom-20"></div>
                  <span class="pull-left">{{l('total_vendido')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].gross_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_recebido')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].total_pago]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left" v-if="relatorios.compras_notas_de_credito[0].gross_total > 0">{{l('notas_credito')}}</span>
                  <span class="pull-right font-size-23 b300" v-if="relatorios.compras_notas_de_credito[0].gross_total > 0">{{MOEDA}} <b v-valor2:[relatorios.compras_notas_de_credito[0].gross_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_divida')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].total_divida]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('tax_total')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].tax_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('net_total')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].net_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('retencao_total')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].retencao_total]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_desconto')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras[0].total_desconto]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_documentos')}}</span>
                  <span class="pull-right font-size-23 b300">{{relatorios.compras[0].total_documentos + relatorios.compras_notas_de_credito[0].total_documentos}}</span>
                  <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 vendas padding-5 padding-bottom-0" v-if="relatorios.vendas_vencido[0].total_documentos && PERMISSOES.relatorio_extracto_pagamentos">
          <div id="widgetLineareaOne" class="widget widget-shadow bill-card-red margin-bottom-5">
            <div class="widget-content texto-branco">
              <div class="padding-20">
                  <div class="clearfix"></div>
                  <div class="pull-left padding-vertical-10">
                    <h2 class="text-uppercase texto-branco">{{l('vendas_vencidas')}}</h2>
                  </div>
                  <div class="clearfix margin-bottom-20"></div>
                  <span class="pull-left">{{l('total_divida')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.vendas_vencido[0].total_divida]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_documentos')}}</span>
                  <span class="pull-right font-size-23 b300">{{relatorios.vendas_vencido[0].total_documentos}}</span>
                  <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 vendas padding-5 padding-bottom-0" v-if="relatorios.compras_vencido[0].total_documentos && PERMISSOES.relatorio_pagamentos_em_falta">
          <div id="widgetLineareaOne" class="widget widget-shadow bill-card-red margin-bottom-5">
            <div class="widget-content texto-branco">
              <div class="padding-20">
                  <div class="clearfix"></div>
                  <div class="pull-left padding-vertical-10">
                    <h2 class="text-uppercase texto-branco">{{l('compras_vencidas')}}</h2>
                  </div>
                  <div class="clearfix margin-bottom-20"></div>
                  <span class="pull-left">{{l('total_divida')}}</span>
                  <span class="pull-right font-size-23 b300">{{MOEDA}} <b v-valor2:[relatorios.compras_vencido[0].total_divida]></b></span>
                  <div class="clearfix"></div>
                  <span class="pull-left">{{l('total_documentos')}}</span>
                  <span class="pull-right font-size-23 b300">{{relatorios.compras_vencido[0].total_documentos}}</span>
                  <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>

    <div class="panel margin-bottom-15 padding-bottom-15">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('dados_empresa')}}</h3>
      </div>
      <div class="panel-body container-fluid padding-0">

        <div class="col-md-4 col-sm-4 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('pais')}}
            </span>
            <select ref="pais" class="form-control foco" v-model="form.pais" :disabled="form.id && form.usado > 0 || PERMISSOES.contatos_gerir < 1" id="pais" @keydown.enter.prevent="FOCO">
              <option v-for="(dt, i) in paises" :key="i" :value="dt.code">{{dt.name}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
            {{$t('contribuinte')}}</span>
            <input v-show="form.pais === 'PT'" ref="nif" autocomplete="off" type="number" class="form-control foco" v-model="form.nif" maxlength="9" id="nif" @keydown.enter.prevent="Fnif();FOCO($event)" @blur="retencaoNif()" :disabled="form.usado && !form.nif1 || PERMISSOES.contatos_gerir < 1" v-numerico>
            <input v-show="form.pais !== 'PT'" ref="nif" autocomplete="off" type="text" class="form-control foco" v-model="form.nif" maxlength="30" id="nif" @keydown.enter.prevent="Fnif();FOCO($event)" @blur="form.retencao = false" :disabled="form.usado && !form.nif1 || PERMISSOES.contatos_gerir < 1" v-texto>
            <a v-if="!(form.usado && !form.nif1) && PERMISSOES.contatos_gerir" href="javascript:void(0)" class="text-warning input-group-addon glyphicon glyphicon-search hidden-xs" @click="Fnif()"></a>
            <a v-if="!(form.usado && !form.nif1) && PERMISSOES.contatos_gerir" href="javascript:void(0)" class="text-warning input-group-addon glyphicon glyphicon-search visible-xs menos30" @click="Fnif()"></a>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">{{l('codigo')}}</span>
            <input ref="codigo" autocomplete="off" type="text" class="form-control foco" v-model="form.codigo" :disabled="form.id && form.usado > 0 || PERMISSOES.contatos_gerir < 1" maxlength="15" id="codigo" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-12 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('nome')}}<span class="verm">*</span>
            </span>
            <input ref="nome" autocomplete="off" type="text" class="form-control foco" v-model="form.nome" required maxlength="150" id="nome" @keydown.enter.prevent="FOCO" minlength="3" v-texto>
            <!--:disabled="form.id && form.usado > 0 || PERMISSOES.contatos_gerir < 1"-->
          </div>
        </div>

      </div>
    </div>

    <div class="panel margin-bottom-15">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('inf_contatos')}}</h3>
      </div>
      <div class="panel-body container-fluid padding-0 padding-bottom-15">

        <div class="col-md-12 col-sm-12 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.morada || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('morada')}}
            </span>
            <input ref="morada" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.morada" id="morada" @keydown.enter.prevent="FOCO" maxlength="100" v-texto>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.codigo_postal || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('codigo_postal')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.codigo_postal" id="codigo_postal" @keydown.enter.prevent="FOCO" maxlength="15" v-texto :placeholder="form.pais === 'PT' ? 'xxxx-xxx' : ''">
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.cidade || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('cidade')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.cidade" maxlength="50" id="cidade" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.site || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('site')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.site" maxlength="200" id="site" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.email || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{$t('email')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="email" class="form-control foco" v-model="form.email" maxlength="150" id="email" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.pessoa_contacto || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('pessoa_contacto')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.pessoa_contacto" maxlength="50" id="pessoa_contacto" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="PERMISSOES.contatos_gerir < 1 && form.telefone_contacto || PERMISSOES.contatos_gerir > 0">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('telefone_contacto')}}
            </span>
            <input :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="form.telefone_contacto" maxlength="30" id="telefone_contacto" @keydown.enter.prevent="form.moradas.length ? FOCO($event) : AdMorada()" v-texto>
          </div>
        </div>

        <div class="clearfix"></div>
        <h3 class="panel-title padding-left-15" v-if="PERMISSOES.contatos_gerir < 1 && form.moradas.length || PERMISSOES.contatos_gerir > 0">{{l('moradas_clientes')}}</h3>
        <div class="clearfix"></div>

        <div v-if="PERMISSOES.contatos_gerir < 1 && form.moradas.length || PERMISSOES.contatos_gerir > 0" class="margin-horizontal-15">
          <table id="tabelaFixa" class="table table-hover dataTable width-full table-striped table-condensed margin-0">
            <thead class="hidden-xs hidden-sm">
              <tr>
                <th class="text-left" style="width: 110px;">{{$t('pais')}}</th>
                <th class="text-left">{{$t('morada')}}</th>
                <th class="text-left">{{$t('codigo_postal')}}</th>
                <th class="text-left">{{$t('cidade')}}</th>
                <th class="width-35" v-if="PERMISSOES.contatos_gerir > 0"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dt, index) in form.moradas" :key="index">
                <td>
                  <select :ref="'pais' + index" :disabled="PERMISSOES.contatos_gerir < 1" class="form-control foco" v-model="dt.pais" :id="'pais' + index" @keydown.enter.prevent="FOCO">
                    <option v-for="(dt, i) in paises" :key="i" :value="dt.code">{{dt.name}}</option>
                  </select>
                  <div class="padding-top-10 visible-xs visible-sm">
                    <input :placeholder="$t('morada')" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control" v-model="dt.morada" v-texto @keydown.enter.prevent="FOCO" maxlength="100">
                  </div>
                  <div class="padding-top-10 visible-xs visible-sm">
                    <input :placeholder="$t('codigo_postal') + (dt.pais === 'PT' ? ' (xxxx-xxx)' : '')" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control" v-model="dt.codigo_postal" @keydown.enter.prevent="FOCO" maxlength="15" v-texto>
                  </div>
                  <div class="padding-top-10 visible-xs visible-sm">
                    <input :placeholder="$t('cidade')" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control" v-model="dt.cidade" @keydown.enter.prevent="form.moradas.length - 1 == index && dt.morada ? AdMorada() : FOCO($event)" maxlength="50" v-texto>
                  </div>
                  <div v-if="PERMISSOES.contatos_gerir > 0" class="padding-top-10 text-right visible-xs visible-sm">
                    <button type="button" @click="removeMorada(dt, index)" class="btn btn-danger btn-xs"><i class="glyphicon glyphicon-trash"></i></button>
                  </div>
                </td>
                <td class="hidden-sm hidden-xs">
                  <input :ref="'morada' + index" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="dt.morada" v-texto :id="'morada' + index" @keydown.enter.prevent="FOCO" maxlength="100">
                </td>
                <td class="hidden-sm hidden-xs">
                  <input :ref="'codigo_postal' + index" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="dt.codigo_postal" :id="'codigo_postal' + index" @keydown.enter.prevent="FOCO" maxlength="15" v-texto :placeholder="dt.pais === 'PT' ? 'xxxx-xxx' : ''">
                </td>
                <td class="hidden-sm hidden-xs">
                  <input :ref="'cidade' + index" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" type="text" class="form-control foco" v-model="dt.cidade" :id="'cidade' + index" @keydown.enter.prevent="form.moradas.length - 1 == index && dt.morada ? AdMorada() : FOCO($event)" maxlength="50" v-texto>
                </td>
                <td class="hidden-sm hidden-xs" v-if="PERMISSOES.contatos_gerir > 0">
                  <button type="button" @click="removeMorada(dt, index)" class="btn btn-danger btn-xs"><i class="glyphicon glyphicon-trash"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="100%">
                  <button type="button" class="btn btn-primary btn-block" @click="AdMorada()">{{l('morada_adicionar')}}</button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <p class="ajuda margin-top-5 text-justify margin-horizontal-10" v-html="l('ajuda_moradas')"></p>
      </div>
    </div>

    <div class="panel margin-bottom-15" v-if="$store.state.lojas1">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('informacao')}}</h3>
      </div>
      <div class="panel-body container-fluid padding-0 padding-bottom-15">
        <div class="col-md-12 col-lg-12 form-group form-material" v-if="$store.state.tiposX.length && $store.state.utilizador.empresa.categorias_contactos && (PERMISSOES.contatos_gerir || !PERMISSOES.contatos_gerir && form.tipos.length)" id="tipos">
          <div class="input-group foco" id="tipos1" name="tipos1" @keydown.enter.prevent="FOCO">
            <span class="input-group-addon">
              {{l('tipos')}}
            </span>

            <treeselect-treeselect :disabled="!PERMISSOES.contatos_gerir_tipos" :clearable="true" :searchable="true" :multiple="true" :options="$store.state.tiposX" placeholder="" v-model="form.tipos" :flat="true" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" no-results-text="" no-options-text="" />

          </div>
          <p class="ajuda margin-top-5 text-justify margin-horizontal-10" v-html="l('ajuda_tipos')"></p>
        </div>

        <div class="col-md-12 col-lg-12 form-group form-material" v-if="$store.state.lojastotal > 1 && (PERMISSOES.contatos_gerir || !PERMISSOES.contatos_gerir && form.lojas.length)" id="lojas">
          <div class="input-group foco" id="lojas1" name="lojas1" @keydown.enter.prevent="FOCO">
            <span class="input-group-addon">
              {{$t('lojas')}}
            </span>

            <treeselect-treeselect :disabled="!PERMISSOES.contatos_gerir" :clearable="true" :searchable="true" :multiple="true" :options="lojas" placeholder="" v-model="form.lojas1" :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :close-on-select="true" no-results-text="" no-options-text="" :default-expand-level="1" />
          </div>
          <p class="ajuda margin-top-5 text-justify margin-horizontal-10" v-html="l('ajuda_lojas')"></p>
        </div>

        <div class="col-md-12 form-group form-material" v-if="!PERMISSOES.contatos_gerir && form.observacoes || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('observacoes')}}
            </span>
            <textarea :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" class="form-control foco" v-model="form.observacoes" maxlength="250" id="observacoes" @keydown.enter.prevent="FOCO" v-texto></textarea>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="!PERMISSOES.contatos_gerir && form.banco || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('banco')}}
            </span>
            <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="text" class="form-control foco" v-model="form.banco" id="banco" maxlength="25" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="!PERMISSOES.contatos_gerir && form.iban || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('iban')}}
            </span>
            <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="text" class="form-control foco" v-model="form.iban" id="iban" maxlength="25" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="!PERMISSOES.contatos_gerir && form.swift || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('swift')}}
            </span>
            <input autocomplete="off" type="text" class="form-control foco" v-model="form.swift" id="swift" maxlength="15" @keydown.enter.prevent="FOCO" v-texto>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('prazo_vencimento')}}
            </span>
            <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="number" class="form-control foco text-center" v-model="form.prazo_vencimento" id="prazo_vencimento" @keydown.enter.prevent="FOCO" min="0" max="999" maxlength="3" step="1" v-numerico>
            <span class="input-group-addon hidden-xs" v-if="form.terminado!=1">
              {{$t('dias')}}
            </span>
            <span class="input-group-addon visible-xs menos30" v-if="form.terminado!=1">
              {{$t('dias')}}
            </span>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('metodo_pagamento')}}
            </span>
            <select :disabled="!PERMISSOES.contatos_gerir" class="form-control foco" v-model="form.metodo_pagamento_id" id="metodo_pagamento_id" @keydown.enter.prevent="FOCO">
              <option v-for="(dt, i) in $store.state['metodos-pagamento']" :key="i" :value="dt.id" :selected="dt.principal == 1"> {{dt.nome}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('metodo_expedicao')}}
            </span>
            <select :disabled="!PERMISSOES.contatos_gerir" class="form-control foco" v-model="form.metodo_expedicao_id" id="metodo_expedicao_id" @keydown.enter.prevent="FOCO">
              <option v-for="(dt, i) in $store.state['metodos-expedicao']" :key="i" :value="dt.id" :selected="dt.principal == 1"> {{dt.nome}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="!PERMISSOES.contatos_gerir && form.desconto_padrao || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('desconto_cliente')}}
            </span>
            <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="text" class="form-control foco text-center" v-model="form.desconto_padrao" id="desconto_padrao" maxlength="4" @keydown.enter.prevent="FOCO" v-percentagem>
            <span class="input-group-addon hidden-xs">%</span>
            <span class="input-group-addon visible-xs menos30">%</span>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="!PERMISSOES.contatos_gerir || PERMISSOES.contatos_gerir">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('credito_maximo')}}
            </span>
            <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="number" class="form-control foco text-center" v-model="form.credito_maximo" id="credito_maximo" @keydown.enter.prevent="FOCO" min="0" step="1" v-numerico>
            <span class="input-group-addon hidden-xs">€</span>
            <span class="input-group-addon visible-xs menos30">€</span>
          </div>
        </div>

        <div class="col-md-6 col-sm-6 col-lg-4 form-group form-material" v-if="form.pais!='PT' && (!PERMISSOES.contatos_gerir || PERMISSOES.contatos_gerir)">
          <div class="input-group">
            <span class="input-group-addon">
              {{l('lingua_padrao')}}
            </span>
            <select class="form-control foco" v-model="form.lingua_padrao_documentos" id="lingua" @keydown.enter.prevent="FOCO">
              <option value="en">English</option>
              <option value="fr">Français</option>
              <option value="pt">Português</option>
            </select>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="col-md-12 form-group" >
          <div class="pull-left margin-right-20 padding-10" v-if="form.id && (!PERMISSOES.contatos_gerir && form.visivel || PERMISSOES.contatos_gerir)">
            <input :disabled="!PERMISSOES.contatos_gerir" class="foco" type="checkbox" id="visivel" v-model="form.visivel" @keydown.enter.prevent="FOCO">
            <label for="visivel">{{$t('estado')}}</label>
          </div>

          <div class="clearfix visible-xs"></div>
          <div class="pull-left margin-right-20 padding-10" v-if="RETENCAO > 0 && (!PERMISSOES.contatos_gerir && form.retencao || PERMISSOES.contatos_gerir)">
            <input :disabled="!PERMISSOES.contatos_gerir" class="foco" type="checkbox" id="retencao" v-model="form.retencao" @keydown.enter.prevent="FOCO">
            <label for="retencao">{{l('retencao')}}</label>
          </div>

          <div class="clearfix visible-xs"></div>
          <div class="pull-left margin-right-20 padding-10" v-if="/\S+@\S+\.\S+/.test(form.email) && (!PERMISSOES.contatos_gerir && form.envio_automatico_documento || PERMISSOES.contatos_gerir)">
            <input :disabled="!PERMISSOES.contatos_gerir" class="foco" type="checkbox" id="documento_automatico" v-model="form.envio_automatico_documento" @keydown.enter.prevent="FOCO" >
            <label for="documento_automatico">{{l('documento_automatico')}}</label>
          </div>

          <div class="clearfix visible-xs"></div>
          <div class="pull-left margin-right-20 padding-10" @click="SET_SCROLLBOTTOM(true)" v-if="/\S+@\S+\.\S+/.test(form.email) && (!PERMISSOES.contatos_gerir && form.activar_alerta || PERMISSOES.contatos_gerir)">
            <input :disabled="!PERMISSOES.contatos_gerir" class="foco" type="checkbox" id="activar_alerta" @change="SET_SCROLLBOTTOM(true)" v-model="form.activar_alerta" @keydown.enter.prevent="FOCO">
            <label for="activar_alerta">{{l('activar_alerta')}}</label>
          </div>

          <div class="clearfix"></div>
          <div class="col-md-12" v-if="form.activar_alerta && /\S+@\S+\.\S+/.test(form.email)">

            <div class="col-md-12 form-group margin-top-10">
              <label>{{l('alerta_cobranca')}}</label>
              <div>
                <div class="input-group pull-left" style="width:45%">
                  <div class="input-group-addon">{{l('alerta_antes')}}</div>
                  <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="number" class="form-control foco text-center" v-model="form.alerta_dias_antes" id="alerta_antes" @keydown.enter.prevent="FOCO" min="0" maxlength="2" step="1" v-numerico />
                </div>
                <div class="input-group pull-right" style="width:45%">
                  <div class="input-group-addon">{{l('alerta_depois')}}</div>
                  <input :disabled="!PERMISSOES.contatos_gerir" autocomplete="off" type="number" class="form-control foco text-center" v-model="form.alerta_dias_depois" id="alerta_depois" @keydown.enter.prevent="FOCO" min="0" maxlength="2" step="1" v-numerico>
                </div>
              </div>
              <div class="clearfix"></div>
              <p class="ajuda alert alert-info margin-top-5 text-justify">{{l('nota_alertas')}}</p>
            </div>

          </div>
          <div class="clearfix"></div>
          <p class="pull-right text-justify margin-0 margin-right-10"><span class="verm">*</span> {{$t('campos_obrigatorios')}}</p>
          <div class="clearfix"></div>

        </div>
      </div>
    </div>

    <div v-if="metas[0]" class="panel margin-bottom-15">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('informacao_meta')}}</h3>
      </div>
      <div class="panel-body container-fluid padding-0 padding-bottom-15">
        <div :class="{'form-group form-material col-xs-12': 1, 'col-md-4 col-sm-6 col-lg-4': dt.meta_value !== 'textarea' && dt.meta_value !== 'radio' && dt.meta_value !== 'image', 'col-md-12 col-sm-12 col-lg-12': dt.meta_value === 'textarea' || dt.meta_value === 'radio' || dt.meta_value === 'image', 'padding-left-10': dt.meta_value === 'checkbox'}" v-for="(dt,index) in metas" :key="index">
          <div class="input-group">
            <span class="input-group-addon" v-if="dt.meta_value !== 'image' && dt.meta_value !== 'checkbox' && dt.meta_value !== 'radio'">
              {{dt.meta_key}}
            </span>
            <textarea v-if="dt.meta_value === 'textarea'" style="height:100px" :disabled="PERMISSOES.contatos_gerir < 1" autocomplete="off" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto></textarea>

            <input v-if="dt.meta_value === 'text' || dt.meta_value === 'hidden'" autocomplete="off" type="text" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto>

            <input v-if="dt.meta_value === 'password'" autocomplete="off" type="password" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" v-texto>

            <select v-if="dt.meta_value === 'select'" class="form-control foco" v-model="dt.option.valor" @keydown.enter.prevent="FOCO" :disabled="PERMISSOES.contatos_gerir < 1">
              <option value=""></option>
              <option v-for="(dtt,index1) in dt.option.valores" :key="index1" :value="dtt">{{dtt}}</option>
            </select>

            <input v-if="dt.meta_value === 'checkbox'" :disabled="PERMISSOES.contatos_gerir < 1" class="foco" type="checkbox" :id="'fdsDSd' + index" v-model="dt.option.valor" @keydown.enter.prevent="FOCO">
            <label v-if="dt.meta_value === 'checkbox'" :for="'fdsDSd' + index">{{dt.meta_key}}</label>

            <span v-if="dt.meta_value === 'radio'" class="pull-left input-group-addon" style="width: auto">{{dt.meta_key}}</span>
            <span v-show="dt.meta_value === 'radio'" v-for="(dtt,index1) in dt.option.valores" :key="index1" class="pull-left">
              <input class="foco" type="radio" :name="'fds232dsa' + index" :id="'fds232dsa' + index + '-' + index1" @keydown.enter.prevent="FOCO" v-model="dt.option.valor" :value="dtt" :disabled="PERMISSOES.contatos_gerir < 1">
              <label :for="'fds232dsa' + index + '-' + index1">{{dtt}}</label>
            </span>

            <div v-if="dt.meta_value === 'image'" class="margin-left-10">
              <div v-if="dt.option.valor" class="pr text-left margin-top-5">
                <div v-if="dt.option.valor.name">
                  {{dt.option.valor.name}} <button type="button" @click="apagarFoto(index)" class="btn btn-danger btn-xs margin-right-5"><i class="glyphicon glyphicon-trash"></i></button>
                </div>
                <div v-else class="pr text-center">
                  <img class="img-responsive" style="max-width:400px;max-height:400px;" :src="($store.state.Demo || $store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/utilizador/imagem/contato/' + dt.option.valor" />
                  <button type="button" @click="apagarFoto(index)" class="btn btn-danger btn-xs margin-right-5 img-delete"><i class="glyphicon glyphicon-trash"></i></button>
                </div>
              </div>
              <span v-else class="btn btn-block btn-primary fileinput-button btn-block-mobile">
                <i class="glyphicon glyphicon-download-alt"></i>&nbsp;
                <span>{{dt.meta_key}}</span>
                <input type="file" @change="onFileChange1($event, index)">
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="panel margin-bottom-15" v-if="$store.state.utilizador.empresa.modulo_projetos && SelProjetos[0]">
      <div class="panel-heading">
        <h3 class="panel-title padding-10">{{l('projetos')}}</h3>
      </div>
      <div class="panel-body container-fluid padding-0">
        <div class="col-md-12 form-group form-material">
          <router-link :to="'/projetos/editar/' + dt.id" class="listaDelete btn btn-primary btn-xs" v-for="(dt, index) in SelProjetos" :key="index"><b>{{dt.codigo}}</b> {{dt.titulo}}</router-link>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <bot-form link="/contactos/1" :botCancelar="botCancelar && PERMISSOES.contatos_gerir > 0" :botSubmit="PERMISSOES.contatos_gerir > 0" :botText="'contactos.' + page1 + (botCancelar ? '.botao1' : '.botao')" @cancelar="cancelar" @submit="submit"></bot-form>
    <msg-permissoes :ok="PERMISSOES.contatos_gerir < 1"></msg-permissoes>
  </div>
</template>

<script>
import { contatosCriar, contatosUp, contatosId, metaAdMultiplos, itens, documentosCriar, enviarmail, projetos, estatisticasContato } from '../../resources/fn'
import { Principal, urlhashDocs, selectIds, checkNIF, v, gethora, getData } from '../../filters/index'
import { mapState, mapMutations } from 'vuex'
import { PAISES } from '../../filters/paises'

export default {
  name: 'pageContactosCriar',
  props: {
    modulo: { default: '', type: String }
  },
  data () {
    return {
      form: {
        pais: 'PT',
        prazo_vencimento: 0,
        metodo_pagamento_id: '',
        metodo_expedicao_id: '',
        morada: '',
        cidade: '',
        codigo_postal: '',
        nome: '',
        codigo: '',
        nif: '',
        retencao: 0,
        site: '',
        email: '',
        pessoa_contacto: '',
        telefone_contacto: '',
        lojas: [],
        lojas1: [],
        tipos: [],
        activar_alerta: false,
        desconto_padrao: '',
        lingua_padrao_documentos: 'pt',
        moradas: [
          { morada: '', codigo_postal: '', cidade: '', pais: 'PT' }
        ],
        envio_automatico_documento: false
      },
      botCancelar: false,
      metas: [],
      paises: PAISES(),
      lojas: [],
      SelProjetos: [],
      modalEncomendas: '',
      artigos: [],
      loja_id: '',
      observacoes: '',

      // relatoriosVendas: true,
      // relatoriosCompras: true,
      relatorios: []
    }
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1',
      page2: 'page2',
      page3: 'page3'
    }),
    PERMISSOES: function () {
      return this.$store.state.utilizador.permissoes
    },
    RETENCAO: function () {
      return this.$store.getters.RETENCAO
    },
    MOEDA: function () {
      return this.$auth.check() ? this.$store.getters.MOEDA : '€'
    }
  },
  watch: {
    '$route' () {
      let a = window.location.pathname.split('/')
      if (a[1] === 'contactos' && (a[2] === 'editar' || a[2] === 'criar')) {
        this.information()
      } else {
        this.botCancelar = true
      }
    },
    '$store.state.encomendarOrcamentar' (val) {
      if (val) {
        this.buscarArtigos()
        this.abrirModal(val.t)
        this.SET_ENCOMENDAR_ORCAMENTAR('')
      }
    }
  },
  mounted () {
    this.information()
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_LOAD',
      'SET_ERROR',
      'SET_TITULO',
      'SET_HELP',
      'SET_AVISO',
      'FOCO',
      'SET_SCROLLBOTTOM',
      'SET_ENCOMENDAR_ORCAMENTAR'
    ]),
    l (a) {
      return this.$t('contactos.' + a)
    },
    l1 (a) {
      return this.$t('stockestado.' + a)
    },
    information () {
      this.SET_LOAD(true)
      if (this.$store.state.tipos1 && this.$store.state.lojas1 && this.$store.state['metodos-expedicao1'] && this.$store.state['metodos-pagamento1'] && this.$store.state.utilizador1 && this.$store.state.contactosMeta1) {

        let id = window.location.pathname.split('/')
        this.lojas = [{ id: 'lojas', label: this.$t('todos'), children: this.$store.state.lojas }]
        if (id[1] === 'documentos' && this.$store.state.help.tipo !== 'contacto') {
          this.$router.replace('/documentos/' + id[2] + '/' + id[3] + (id[5] === 'contactos' ? '/' + id[4] : '') + urlhashDocs(), () => {})
        } else {
          this.metas = this.$store.state.contactosMeta
          this.botCancelar = false
          if (parseInt(id[3])) {
            contatosId(this.axios, {}, id[3], this.$store.state.modulo).then((res) => {
              this.form = res.data
              this.SET_TITULO(this.form.nome + '<sup class="text-success">' + this.$t('editar') + '</sup>')
              this.form.tipos = selectIds(res.data.tipo)
              this.form.lojas1 = selectIds(res.data.originario)

              if (this.form.usado > 0) {
                this.MenuContatoEstatisticas()
              }
              if (parseInt(this.form.nif) === 999999990) {
                this.form.nif = ''
                this.form.nif1 = 1
              }

              if (!this.form.metodo_pagamento_id) {
                this.form.metodo_pagamento_id = Principal(this.$store.state['metodos-pagamento']).id
              }
              if (!this.form.metodo_expedicao_id) {
                this.form.metodo_expedicao_id = Principal(this.$store.state['metodos-expedicao']).id
              }
              if (!this.form.prazo_vencimento && this.form.prazo_vencimento !== 0) {
                this.form.prazo_vencimento = this.$store.getters.VENCIMENTO
              }
              this.meta()
              this.SET_LOAD()
              // this.FOCO([])
            }, () => {
              this.SET_TOAST({ msg: this.l('nao_existe'), type: 'error' })
              this.$router.push('/contactos/1', () => {})
            })

            if (this.$store.state.utilizador.empresa.modulo_projetos) {
              projetos(this.axios, { pesquisa: { contatos: [this.$store.state.page2] }, limite: 200 }, 1, this.$store.state.modulo).then((res) => {
                this.SelProjetos = res.data.data
              }, () => {})
            }
          } else {
            this.SET_TITULO(this.l('criar.titulo'))
            this.SET_LOAD()
            this.form = this.initform()
            this.form.metodo_pagamento_id = Principal(this.$store.state['metodos-pagamento']).id
            this.form.metodo_expedicao_id = Principal(this.$store.state['metodos-expedicao']).id
            this.form.prazo_vencimento = this.$store.getters.VENCIMENTO
            this.form.lojas = []
            this.form.tipos = []
            this.meta()
            // this.FOCO([])
          }
          if (id[1] === 'documentos') {
            this.botCancelar = true
            if (this.$store.state.help.tipo === 'contacto') {
              this.form.nome = this.$store.state.help.valor.contato
            }
          }
        }
      } else {
        this._.delay(this.information, 150)
      }
    },
    meta () {
      let u = this.metas.length
      while (--u >= 0) {
        this.$set(this.metas[u].option, 'valor', '')
      }
      if (Array.isArray(this.form.meta)) {
        let i = this.form.meta.length
        if (i) {
          let x = this.metas.length
          u = this.metas.length
          while (--u >= 0) {
            this.$set(this.metas[u].option, 'valor', '')
            // this.metas[u].option.valor = ''
          }
          while (--i >= 0) {
            u = x
            while (--u >= 0) {
              if (this.form.meta[i].meta_key === this.metas[u].meta_key) {
                if (this.metas[u].meta_value === 'checkbox') {
                  this.$set(this.metas[u].option, 'valor', this.form.meta[i].meta_value > 0 ? 1 : 0)
                } else {
                  this.$set(this.metas[u].option, 'valor', this.form.meta[i].meta_value)
                }
                // this.metas[u].option.valor = this.form.meta[i].meta_value
                break
              }
            }
          }
        }
      }
    },
    submit () {
      try {
        if (this.PERMISSOES.contatos_gerir > 0) {
          let i = this.form.moradas.length
          let self = this
          while (--i >= 0) {
            if (this.form.moradas[i].cidade || this.form.moradas[i].morada || this.form.moradas[i].codigo_postal) {
              if (!this.form.moradas[i].morada) {
                this.$swal('', this.$t('270'), 'warning').then(() => {
                  self.delyFoco('morada' + i)
                }, () => {
                  self.delyFoco('morada' + i)
                })
                return
              }
              if (!this.form.moradas[i].codigo_postal) {
                this.$swal('', this.$t('270'), 'warning').then(() => {
                  self.delyFoco('codigo_postal' + i)
                }, () => {
                  self.delyFoco('codigo_postal' + i)
                })
                return
              }
              if (!this.form.moradas[i].cidade) {
                this.$swal('', this.$t('270'), 'warning').then(() => {
                  self.delyFoco('cidade' + i)
                }, () => {
                  self.delyFoco('cidade' + i)
                })
                return
              }
            } else {
              this.form.moradas.splice(i, 1)
            }
          }
          if (this.form.observacoes) {
            this.form.observacoes = this.form.observacoes.toString().trim()
          }
          if (this.form.lojas1[0]) {
            if (this.form.lojas1[0] === 'lojas') {
              this.form.lojas = this.$store.state.lojas.map(a => a.id)
            } else {
              this.form.lojas = this.form.lojas1
            }
          }
          // meta
          this.form.meta = {}
          if (this.form.nome) {
            this.SET_LOAD(true)
            if (this.form.id) {
              let img = 0
              let images = new window.FormData()
              if (this.metas[0]) {
                let k = this.metas.length
                let i = 0
                while (i < k) {
                  if (this.metas[i].meta_value === 'image' && this.metas[i].option.valor) {
                    images.append('meta[' + img + '][meta_key]', this.metas[i].meta_key)
                    images.append('meta[' + img + '][meta_value]', this.metas[i].option.valor || '')
                    images.append('meta[' + img + '][contato_id]', this.form.id)
                    ++img
                  } else {
                    this.form.meta[this.metas[i].meta_key] = this.metas[i].option.valor
                  }
                  ++i
                }
              }
              contatosUp(this.axios, this.form, this.$store.state.modulo).then(() => {
                if (img) {
                  metaAdMultiplos(this.axios, images, 'contactos', this.$store.state.modulo).then((res) => {
                    let i = res.data.length
                    let u = this.metas.length
                    let x
                    while (--i >= 0) {
                      if (res.data[i].meta_value) {
                        x = u
                        while (--x >= 0) {
                          if (res.data[i].meta_key === this.metas[x].meta_key) {
                            this.metas[x].option = { valor: res.data[i].meta_value }
                            break
                          }
                        }
                      }
                    }
                    this.SET_TOAST({ msg: this.l('sucessoEditar') })
                    window.scrollTo(0, 0)
                    this.FOCO([])
                  }, (er) => {
                    this.SET_AVISO(er.response)
                  })
                } else {
                  this.SET_TOAST({ msg: this.l('sucessoEditar') })
                  window.scrollTo(0, 0)
                  this.FOCO([])
                }
              }, (er) => {
                this.SET_AVISO(er.response)
              })
            } else {
              contatosCriar(this.axios, this.form, this.$store.state.modulo).then((res) => {
                if (this.metas[0]) {
                  let images = new window.FormData()
                  let k = this.metas.length
                  let i = 0
                  while (i < k) {
                    images.append('meta[' + i + '][meta_key]', this.metas[i].meta_key)
                    images.append('meta[' + i + '][meta_value]', this.metas[i].option.valor || '')
                    images.append('meta[' + i + '][contato_id]', res.data.id)
                    ++i
                  }
                  metaAdMultiplos(this.axios, images, 'contactos', this.$store.state.modulo).then(() => {
                    this.submitOk(res)
                  }, (er) => {
                    this.SET_AVISO(er.response)
                  })
                } else {
                  this.submitOk(res)
                }
              }, (er) => {
                this.SET_AVISO(er.response)
              })
            }
          } else {
            this.$swal({
              title: this.l('nome'),
              text: this.l('nomevazio'),
              icon: 'error',
              closeOnConfirm: false
            }).then(() => {
              self.delyFoco('nome')
            }, () => {
              self.delyFoco('nome')
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    submitOk (res) {
      let a = window.location.pathname.split('/')
      if (a[4] === 'contactos' || a[5] === 'contactos') {
        let xx = this.$store.state.help
        xx.tipo = 'contacto1'
        xx.valor.res = res.data
        this.SET_HELP(xx)
        this.cancelar()
      } else {
        this.SET_TOAST({ msg: this.l('sucessoCriar') })
        this.$router.push('/contactos/editar/' + res.data.id, () => {})
        window.scrollTo(0, 0)
        this.FOCO([])
      }
      this.SET_LOAD()
    },
    cancelar () {
      let a = window.location.pathname.split('/')
      if (a[5] === 'contactos') {
        this.$router.push('/documentos/' + this.page1 + '/' + this.page2 + '/' + this.page3 + urlhashDocs(), () => {})
      } else {
        this.$router.push('/documentos/' + this.page1 + '/' + this.page2 + urlhashDocs(), () => {})
      }
    },
    initform () {
      return {
        pais: 'PT',
        prazo_vencimento: 0,
        metodo_pagamento_id: '',
        metodo_expedicao_id: '',
        morada: '',
        cidade: '',
        codigo_postal: '',
        nome: '',
        codigo: '',
        nif: '',
        retencao: 0,
        site: '',
        email: '',
        pessoa_contacto: '',
        telefone_contacto: '',
        lojas: [],
        lojas1: [],
        tipos: [],
        activar_alerta: false,
        desconto_padrao: '',
        lingua_padrao_documentos: 'pt',
        envio_automatico_documento: false,
        moradas: [
          { morada: '', codigo_postal: '', cidade: '', pais: 'PT' }
        ]
      }
    },

    removeMorada (dt, index) {
      this.form.moradas.splice(index, 1)
      if (this.form.id > 0) {
        this.SET_TOAST({ msg: this.$t('santesSaiGravar') })
      }
      if (!this.form.moradas.length) {
        this.AdMorada()
      }
    },
    AdMorada () {
      this.form.moradas.push({ morada: '', codigo_postal: '', cidade: '', pais: this.form.pais })
      this.delyFoco('pais' + (this.form.moradas.length - 1))
      this.SET_SCROLLBOTTOM(true)
    },

    Fnif () {
      if (parseInt(this.form.nif) === 999999990 || !this.form.nif) {
        return
      }
      this.form.nif = this.form.nif.toString().trim()
      this.SET_LOAD(true)
      let self = this
      checkNIF(this.axios, this.form.nif, this.form).then(function (a) {
        self.SET_LOAD()
        if (a === -1) {
          if (self.form.pais === 'PT') {
            self.$swal('', self.$t(206), 'error').catch(self.$swal.noop)
          } else {
            self.$swal('', self.$t('erroNifPais'), 'error').catch(self.$swal.noop)
          }
        } else {
          if (self.form.pais === 'PT') {
            let nif = self.form.nif
            if (nif) {
              let c = parseInt(nif.charAt(0)) || ''
              if ((c === 5 || c === 9 || c === 7) && self.RETENCAO > 0) {
                self.form.retencao = true
              } else {
                self.form.retencao = false
              }
            }
            self.delyFoco('codigo')
          }
        }
      })
    },
    retencaoNif () {
      if (this.form.nif && this.form.pais === 'PT') {
        let i = parseInt(this.form.nif.toString().substring(0, 1))
        this.form.retencao = (i === 5 || i === 7 || i === 9) && this.RETENCAO > 0
      }
    },

    onFileChange1 (e, index) {
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.$set(this.metas[index], 'option', { valor: files[0] })
      e.target.value = ''
      // dt.option.valor = files[0]
    },
    apagarFoto (index) {
      this.$set(this.metas[index], 'option', { valor: '' })
    },

    // criar orçamentos ou criar encomendas do artigo
    abrirModal (documento) {
      this.artigos = []
      this.loja_id = this.$store.state.loja
      this.modalEncomendas = documento === 'orcamentar' ? 22 : 21
      this.observacoes = ''
      window.scrollTo(0, 100)
    },
    buscarArtigos () {
      this.SET_LOAD(true)
      itens(this.axios, { pesquisa: { fornecedores: [ this.form.id ] } }, 1, this.$store.state.modulo).then((res) => {
        this.artigos = res.data.data.map(a => {
          a.descricao_extra1 = false
          a.descricao_extra = ''
          return a
        })
        this.SET_LOAD()
      }, () => {})
    },
    criarDocumento () {
      let produtos = []
      for (let i in this.artigos) {
        if (this.artigos[i].quantidade > 0) {
          produtos.push({
            item_id: this.artigos[i].id,
            quantidade: this.artigos[i].quantidade,
            unidade: this.artigos[i].unidade_medida_id,
            nome: this.artigos[i].descricao,
            motivo_isencao_id: this.artigos[i].iva_compra ? '' : this.$store.state.isencao[0].id,
            imposto_id: this.artigos[i].iva_compra || 0,
            preco_unitario: this.modalEncomendas === 21 ? this.artigos[i].preco_custo || 0 : 0,
            descricao_extra: this.artigos[i].descricao_extra1 ? this.artigos[i].descricao_extra : ''
          })
        }
      }
      if (produtos[0]) {
        this.SET_LOAD(true)
        let data = getData() + ' ' + gethora(0, 1)
        documentosCriar(this.axios, { contato_id: this.form.id, data: data, serie_id: Principal(this.$store.state.series).id, loja_id: this.loja_id, tipo_documento_id: this.modalEncomendas, terminado: 1, produtos: produtos, prazo_vencimento: data, metodo_pagamento_id: this.form.metodo_pagamento_id, metodo_expedicao_id: this.form.metodo_expedicao_id, moeda: this.$store.getters.EMPRESA.moeda, observacoes: this.observacoes }, this.$store.state.modulo).then((res) => {
          if (this.modalEncomendas === 22) {
            this.SET_TOAST({ msg: this.l1('sucesso_orcamento').replace('@fornecedor', res.data.contato.nome).replace('@serial', res.data.invoice_number), load: true })
          } else {
            this.SET_TOAST({ msg: this.l1('sucesso_encomenda').replace('@fornecedor', res.data.contato.nome).replace('@serial', res.data.invoice_number), load: true })
          }
          // enviar email
          this._.delay(function (self, email, id, token, serial) {
            self.SET_LOAD()
            if ((self.$store.state.utilizador.plano.plano.valor_mensal > 10 || self.$store.state.utilizador.plano.plano.valor_anual > 100) && self.$store.state.template.length && self.$store.state.utilizador.empresa.smtp_privado > 0) {
              let i = self.$store.state.template.length
              let templates = []
              while (--i >= 0) {
                templates.unshift('<option value="' + self.$store.state.template[i].id + '" ' + (i ? '' : 'selected="selected"') + '>' + self.$store.state.template[i].nome + '</option>')
              }
              templates = templates.join('')
              self.$swal({
                title: self.$t('documentos.enviar_documento'),
                html: '<div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('email') + '</span><input autocomplete="off" type="email" id="s_email" value="' + email + '" class="form-control"></div></div><div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('documentos.nome') + '</span><select id="s_nome" class="form-control">' + templates + '</select></div></div><div class="form-material"><div class="input-group"><span class="input-group-addon">' + self.$t('documentos.mensagem') + '</span><textarea rows="5" id="s_mensagem" class="form-control"></textarea></div></div><a class="margin-vertical-10 pull-right" target="_blank" target="_blank" href="' + (this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + id + '/' + token + '"><i class="glyphicon glyphicon-print margin-right-5"></i>' + self.$t('imprimir') + ' - ' + serial + '</a>',
                showCancelButton: true,
                confirmButtonText: self.$t('confirmar'),
                cancelButtonText: self.$t('cancelar'),
                allowOutsideClick: false
              }).then(function (value) {
                if (value.value) {
                  if (v('s_nome') && v('s_email')) {
                    self.SET_LOAD(true)
                    enviarmail(self.axios, { id: id, email: v('s_email'), mensagem: v('s_mensagem'), template_id: v('s_nome') }, self.$store.state.modulo).then(() => {
                      self.SET_TOAST({ msg: self.$t('documentos.sucEmail') })
                    }, () => {
                      self.SET_TOAST({ msg: self.$t('documentos.erroEmail'), type: 'error' })
                    })
                  } else {
                    self.SET_TOAST({ msg: self.$t('documentos.erroEmailRequired'), type: 'error' })
                  }
                }
              }).catch(self.$swal.noop)
            } else {
              self.$swal({
                title: self.$t('documentos.enviar_documento'),
                input: 'email',
                inputPlaceholder: self.$t('email'),
                inputValue: email,
                html: '<a class="margin-vertical-10 pull-right" target="_blank" href=' + (this.$store.state.Demo || this.$store.state.dev ? 'https://dev.bill.pt' : 'https://app.bill.pt') + '/documentos/download/' + id + '/' + token + '"><i class="glyphicon glyphicon-print margin-right-5"></i>' + self.$t('imprimir') + ' - ' + serial + '</a>',
                showCancelButton: true,
                confirmButtonText: self.$t('confirmar'),
                cancelButtonText: self.$t('cancelar'),
                allowOutsideClick: false
              }).then(function (value) {
                if (value.value) {
                  self.SET_LOAD(true)
                  enviarmail(self.axios, { id: id, email: value.value }, self.$store.state.modulo).then(() => {
                    self.SET_TOAST({ msg: self.$t('documentos.sucEmail') })
                  }, () => {
                    self.SET_TOAST({ msg: self.$t('documentos.erroEmail'), type: 'error' })
                  })
                }
              }).catch(self.$swal.noop)
            }
          }, 3000, this, this.form.email, res.data.id, res.data.token_download, res.data.invoice_number)
          this.modalEncomendas = ''
        }, (er) => {
          this.SET_AVISO(er.response)
        })
      } else {
        this.SET_TOAST({ msg: this.l1('tem_que_ter_quantidades'), type: 'error' })
      }
    },
    limparPrecosLoja () {
      this.artigos = this.artigos.map(a => {
        a.preco_custo = ''
        return a
      })
    },

    MenuContatoEstatisticas () {
      if (this.PERMISSOES.relatorios) {
        estatisticasContato(this.axios, { contato_id: this.form.id }).then((res) => {
          this.relatorios = res.data
          /*
          if (res.data.vendas) {
            if (res.data.vendas[0].total_documentos > 0) {
              this.menu.push({texto: this.$t('contactos.vendas'), total: 'x', tipo: 5})
              this.menu.push({texto: this.$t('contactos.total_vendido'), total: res.data.vendas[0].gross_total, tipo: 5, moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_recebido'), total: res.data.vendas[0].total_pago, tipo: 5, class1: 'recebido', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_divida'), total: res.data.vendas[0].total_divida, tipo: 5, class1: 'divida', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.tax_total'), total: res.data.vendas[0].tax_total, tipo: 5, moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.net_total'), total: res.data.vendas[0].net_total, tipo: 5, moeda: this.MOEDA})

              if (val.retencao) {
                this.menu.push({texto: this.$t('contactos.retencao_total'), total: res.data.vendas[0].retencao_total, tipo: 5, moeda: this.MOEDA})
              }
              this.menu.push({texto: this.$t('contactos.total_desconto'), total: res.data.vendas[0].total_desconto, tipo: 5, moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_documentos'), total: res.data.vendas[0].total_documentos, tipo: 5})
            }
          }
          if (res.data.vendas_vencido && this.PERMISSOES.relatorio_extracto_pagamentos) {
            if (res.data.vendas_vencido[0].total_documentos > 0) {
              this.menu.push({texto: this.$t('contactos.vendas_vencidas'), total: 'x', tipo: 5})
              this.menu.push({texto: this.$t('contactos.total_divida'), total: res.data.vendas_vencido[0].total_divida, tipo: 5, class1: 'divida', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_documentos'), total: res.data.vendas_vencido[0].total_documentos, tipo: 5})
            }
          }
          if (res.data.compras) {
            if (res.data.compras[0].total_documentos > 0) {
              this.menu.push({texto: this.$t('contactos.compras'), total: 'x', tipo: 5})
              this.menu.push({texto: this.$t('contactos.total_comprado'), total: res.data.compras[0].gross_total, tipo: 5, moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_pago'), total: res.data.compras[0].total_pago, tipo: 5, class1: 'recebido', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_divida'), total: res.data.compras[0].total_divida, tipo: 5, class1: 'divida', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.tax_total'), total: res.data.compras[0].tax_total, tipo: 5})
              this.menu.push({texto: this.$t('contactos.net_total_compras'), total: res.data.compras[0].net_total, tipo: 5, moeda: this.MOEDA})

              if (val.retencao) {
                this.menu.push({texto: this.$t('contactos.retencao_total'), total: res.data.compras[0].retencao_total, tipo: 5, moeda: this.MOEDA})
              }
              this.menu.push({texto: this.$t('contactos.total_desconto'), total: res.data.compras[0].total_desconto, tipo: 5, moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_documentos'), total: res.data.compras[0].total_documentos, tipo: 5})
            }
          }
          if (res.data.compras_vencido && this.PERMISSOES.relatorio_pagamentos_em_falta) {
            if (res.data.compras_vencido[0].total_documentos > 0) {
              this.menu.push({texto: this.$t('contactos.compras_vencidas'), total: 'x', tipo: 5})
              this.menu.push({texto: this.$t('contactos.total_divida'), total: res.data.compras_vencido[0].total_divida, tipo: 5, class1: 'divida', moeda: this.MOEDA})
              this.menu.push({texto: this.$t('contactos.total_documentos'), total: res.data.compras_vencido[0].total_documentos, tipo: 5})
            }
          }
          */
        }, () => {})
      }
    },

    delyFoco (campo) {
      this._.delay(function (campo) {
        if (document.getElementById(campo)) {
          document.getElementById(campo).focus()
        }
      }, 150, campo)
    }
  }
}
</script>
