<template>
  <!-- class  botsForm -->
  <div class="margin-0" id="botsForm">
    <button id="botcancelar" type="button" @click="$emit('cancelar')" class="btn btn-default btn-pure pull-left btn-block-mobile" v-if="botCancelar"><i class="glyphicon glyphicon-remove"></i> {{$t('cancelar')}}</button>
    <router-link id="botcancelar" class="btn btn-default btn-pure pull-left btn-block-mobile" :to="link" v-if="!botCancelar && link"><i class="glyphicon glyphicon-remove"></i> {{$t('cancelar')}}</router-link>
    <button id="botfinalizar" type="button" class="btn btn-primary pull-right foco btn-block-mobile" @click="$emit('submit')" v-if="botSubmit"><i class="glyphicon glyphicon-floppy-disk"></i> {{$t(botText)}}</button>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  props: {
    link: { default: '', type: String },
    botCancelar: { default: false, type: Boolean },
    botSubmit: { default: false, type: Boolean },
    botText: { default: '', type: String }
  }
  /*
  mounted () {
    let panel = document.getElementsByClassName('panel')
    let i = panel.length
    let tamanhoPanel = 0
    while (--i >= 0) {
      tamanhoPanel += panel[i].clientHeight
    }
    console.log(tamanhoPanel)
    let el = panel[0]
    while (el) {
      tamanhoPanel += el.offsetTop - el.scrollTop + el.clientTop
      el = el.offsetParent
    }
    console.log(tamanhoPanel, '.,.,a,.,.sa.,', window.innerHeight)
    if (window.innerHeight > tamanhoPanel) {
      document.getElementById('botsForm').classList.remove('botsForm')
    }
  }
  */
}
</script>
