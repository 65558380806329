<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group form-material padding-left-0">
    <div class="input-group pr pesquisarFixo">
      <span class="input-group-addon">
        {{campoContacto}}<span v-if="obrigatorio" class="verm">*</span>
      </span>

      <input ref="contactos" type="text" class="form-control foco" :value="contacto1" v-$model="contacto1" :placeholder="$t('pesquisar_contato')" :disabled="disabled" id="contactos" required="required" @keydown.up.prevent.stop @keyup.prevent.stop="pesqClienteAntes($event)" @paste="pesqCliente()" autocomplete="off" @focus="SET_SCROLL()">

      <a href="javascript:void(0)" class="text-warning icoinput" @click="addContato()" v-if="hideCriarContato && rescontatos.length === 0" v-tooltip.top-start="l('ad_contacto')">
        <i class="glyphicon glyphicon-plus-sign"></i>
      </a>
      <a href="javascript:void(0)" class="text-danger icoinput" @click="rescontatos = []; contacto1 = ''; $emit('delContato')" v-if="deleteContato" v-tooltip.top-start="l('limpar_contacto')">
        <i class="glyphicon glyphicon-trash"></i>
      </a>

      <a v-if="rescontatos[0] && !deleteContato" href="javascript:void(0)" class="icoinput text-primary" @click="rescontatos = []; contacto1 = ''"><i class="glyphicon glyphicon-remove"></i></a>
    </div>
    <div v-if="!contatoID && contacto1.length > 2">
      <div id="divTable">
        <table id="tabelaFixa" class="table table-hover width-full table-condensed table-striped margin-0">
          <thead v-if="rescontatos[0]" id="tabelaFixaThead">
            <tr class="pesquisar">
              <th class="FundoHeaderPesquisar visible-xs visible-sm">
                <a href="javascript:void(0)" @click="formUrl(1, 'nif')" class="FundoHeaderPesquisar"><i :class="{seta: 1, glyphicon: 1, 'glyphicon-sort-by-order': pesq.pesquisa.order_by.nif == 'ASC', 'glyphicon-sort-by-order-alt': pesq.pesquisa.order_by.nif == 'DESC'}"></i>{{$t('nif')}}</a>
                <div class="clearfix"></div>
                <a href="javascript:void(0)" @click="formUrl(1, 'nome')" class="text-left FundoHeaderPesquisar"><i v-if="pesq.pesquisa.order_by.nome == 'ASC' || pesq.pesquisa.order_by.nome == 'DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.pesquisa.order_by.nome == 'ASC', 'glyphicon-sort-by-alphabet-alt': pesq.pesquisa.order_by.nome == 'DESC', 'margin-right-10': 1}"></i>{{campoContacto_}}</a>
              </th>
              <th class="FundoHeaderPesquisar width-100 hidden-xs hidden-sm">
                <a href="javascript:void(0)" @click="formUrl(1, 'nif')" class="text-right FundoHeaderPesquisar"><i :class="{seta: 1, glyphicon: 1, 'glyphicon-sort-by-order': pesq.pesquisa.order_by.nif == 'ASC', 'glyphicon-sort-by-order-alt': pesq.pesquisa.order_by.nif == 'DESC'}"></i>{{$t('nif')}}</a>
              </th>
              <th class="FundoHeaderPesquisar hidden-xs hidden-sm">
                <a href="javascript:void(0)" @click="formUrl(1, 'nome')" class="text-left FundoHeaderPesquisar"><i v-if="pesq.pesquisa.order_by.nome == 'ASC' || pesq.pesquisa.order_by.nome == 'DESC'" :class="{seta: 1, glyphicon: 1,'glyphicon-sort-by-alphabet': pesq.pesquisa.order_by.nome == 'ASC', 'glyphicon-sort-by-alphabet-alt': pesq.pesquisa.order_by.nome == 'DESC', 'margin-right-10': 1}"></i>{{campoContacto_}}</a>
              </th>
            </tr>
          </thead>
          <tbody id="listaClientes">
            <tr v-for="(dt,index) in rescontatos" :key="index" @click="selContato1(dt)" :class="{'selCliente': !index}" @mouseenter="mouseEnterFN(index)">
              <td class="text-left visible-xs visible-sm">
                <div><b v-html="dt.nif != '999999990' ? dt.nif.toString().replace(RegExp(contacto1, 'gi'), '<b>$&</b>') : ''"></b></div>
                <span v-html="dt.nome.toString().replace(RegExp(contacto1, 'gi'), '<b>$&</b>')"></span>
              </td>
              <td class="text-right mao hidden-xs hidden-sm" v-html="dt.nif != '999999990' ? dt.nif.toString().replace(RegExp(contacto1, 'gi'), '<b>$&</b>') : ''"></td>
              <td class="text-left mao hidden-xs hidden-sm" v-html="dt.nome.toString().replace(RegExp(contacto1, 'gi'), '<b>$&</b>')"></td>
            </tr>
          </tbody>
        </table>
        <button v-if="hideCriarContato" class="btn btn-primary btn-block" @click="addContato()">{{$t('criar_contacto')}} <span class="text-warning" v-if="contacto1.length">({{contacto1}})</span></button>
      </div>
      <div class="clearfix"></div>
      <paginacao1-paginacao1 v-if="rescontatos[0]" :pag="pag" :lastpage="lastpage" @formUrl="formUrl"></paginacao1-paginacao1>
    </div>
  </div>
</template>

<script>
import { ordernar, urlhashDocs } from '../../filters/index'
import { contatos } from '../../resources/fn'
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      pesq: {
        pesquisa: {
          texto: {},
          order_by: {},
          pesquisa_avancada: {}
        }
      },
      rescontatos: [],
      pag: 1,
      lastpage: 1,
      indexPesq: 0,
      contato: '',
      moradas: [],
      contacto1: '',
      pesqCliente: '',
      campoContacto: '',
      campoContacto_: ''
    }
  },
  watch: {
    'contacto1' (a) {
      this.$emit('update:contacto', a)
    },
    'contacto' (a) {
      this.contacto1 = a
    },
    '$route' () {
      this.rescontatos = []
    }
  },
  computed: {
    ...mapState({
      page: 'page',
      page1: 'page1',
      page2: 'page2',
      page3: 'page3'
    })
  },
  props: {
    hideCriarContato: { type: Boolean },
    deleteContato: { type: Boolean },
    contatoID: { type: Boolean },
    contacto: { default: '', type: String },
    disabled: { type: Boolean },
    obrigatorio: { type: Boolean },
    muitos: { type: Boolean },
    contactos: { type: Array },
    mostrarNome: { type: Boolean },
    cliente: { type: Boolean },
    fornecedor: { type: Boolean }
  },
  mounted () {
    this.contacto1 = this.contacto
    this.pesqCliente = this._.debounce(function () {
      this.pesq.pesquisa.order_by = {}
      // this.contacto1 += ' '
      this.formUrl(1)
    }, 550)
    if (this.muitos) {
      if (this.cliente) {
        this.campoContacto = this.$t('clientes')
        this.campoContacto_ = this.$t('cliente')
      } else {
        if (this.fornecedor) {
          this.campoContacto = this.$t('fornecedores')
          this.campoContacto_ = this.$t('fornecedor')
        } else {
          this.campoContacto = this.$t('contacto_')
          this.campoContacto_ = this.$t('contacto')
        }
      }
    } else {
      if (this.cliente) {
        this.campoContacto = this.$t('clientes')
        this.campoContacto_ = this.$t('cliente')
      } else {
        if (this.fornecedor) {
          this.campoContacto = this.$t('fornecedores')
          this.campoContacto_ = this.$t('fornecedor')
        } else {
          this.campoContacto = this.$t('contacto')
          this.campoContacto_ = this.$t('contacto')
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_SCROLL',
      'SET_HELP'
    ]),
    l (a) {
      return this.$t('documentos.' + a)
    },
    formUrl (pag, order) {
      if (!this.pesq.pesquisa.order_by) {
        this.pesq.pesquisa.order_by = {}
      }
      switch (order) {
        case 'codigo':
          this.pesq.pesquisa.order_by = { codigo: ordernar(this.pesq.pesquisa.order_by.codigo) }
          break
        case 'nif':
          this.pesq.pesquisa.order_by = { nif: ordernar(this.pesq.pesquisa.order_by.nif) }
          break
        case 'nome':
          this.pesq.pesquisa.order_by = { nome: ordernar(this.pesq.pesquisa.order_by.nome) }
          break
      }
      // this.contacto1 = this.contacto1.substring(0, this.contacto1.length - 1)
      if (this.contacto1.length < 3) {
        this.rescontatos = []
      } else {
        this.SET_LOAD(true)
        contatos(this.axios, { pesquisa: { texto: this.contacto1, visivel: true, order_by: this.pesq.pesquisa.order_by } }, pag, this.$store.state.modulo).then((res) => {
          this.indexPesq = 0
          this.rescontatos = res.data.data
          if (Array.isArray(this.contactos)) {
            let u = this.contactos.length
            let i
            while (--u >= 0) {
              i = this.rescontatos.length
              while (--i >= 0) {
                if (this.contactos[u].id === this.rescontatos[i].id) {
                  this.rescontatos.splice(i, 1)
                  break
                }
              }
            }
            this.pag = res.data.current_page
            this.lastpage = res.data.last_page
          }
          this.SET_LOAD()
        }, () => {
          this.SET_LOAD()
        })
      }
    },
    addContato () {
      this.SET_HELP({
        tipo: 'contacto',
        valor: {
          contato: this.contacto1
        }
      })
      this.$router.push('/' + this.page + '/' + this.page1 + '/' + this.page2 + '/contactos' + urlhashDocs(), () => {})
    },
    mouseEnterFN (index) {
      let b = document.getElementById('listaClientes').getElementsByTagName('tr')
      let i = b.length
      while (--i >= 0) {
        b[i].classList.remove('selCliente')
      }
      this.indexPesq = index
      b[this.indexPesq].setAttribute('class', 'selCliente')
    },
    pesqClienteAntes (ev) {
      let i = 0
      let b = ''
      switch (ev.which) {
        case 9:
        case 20:
        case 16:
        case 93:
        case 18:
        case 37:
        case 39:
          return
        case 13:
          if (!document.getElementById('listaClientes')) {
            return
          }
          /*
          if ((document.getElementById('listaClientes').getElementsByTagName('tr').length - 1) === this.indexPesq && this.hideCriarContato) {
            this.addContato()
          } else {
            this.selContato1(this.rescontatos[this.indexPesq])
          }
          */
          this.selContato1(this.rescontatos[this.indexPesq])
          return
        case 27:
          this.rescontatos = []
          this.contacto1 = ''
          return
        case 38:
          if (!document.getElementById('listaClientes')) {
            return
          }
          b = document.getElementById('listaClientes').getElementsByTagName('tr')
          i = b.length
          while (--i >= 0) {
            b[i].removeAttribute('class')
          }
          --this.indexPesq
          if (this.indexPesq >= 0) {
            window.scrollTo(0, document.documentElement.scrollTop - 31)
            b[this.indexPesq].setAttribute('class', 'selCliente')
          } else {
            this.indexPesq = 0
            b[0].setAttribute('class', 'selCliente')
          }
          return
        case 40:
          if (!document.getElementById('listaClientes')) {
            return
          }
          b = document.getElementById('listaClientes').getElementsByTagName('tr')
          i = b.length
          while (--i >= 0) {
            b[i].removeAttribute('class')
          }
          ++this.indexPesq
          if (this.rescontatos.length > this.indexPesq) {
            // window.scrollTo(0, document.getElementsByClassName('selCliente')[0].getBoundingClientRect().top)
            window.scrollTo(0, 31 + document.documentElement.scrollTop)
            b[this.indexPesq].setAttribute('class', 'selCliente')
          } else {
            this.indexPesq = this.rescontatos.length - 1
            b[this.indexPesq].setAttribute('class', 'selCliente')
          }
          return
      }
      this.pesqCliente()
    },
    selContato1 (dt) {
      this.$emit('selContato', dt)
      if (this.mostrarNome) {
        this.contacto1 = dt.nome
        // this.contatoID = dt.id
        this.$emit('update:contatoID', dt.id)
      } else {
        this.contacto1 = ''
        this.rescontatos = []
      }
    }
  }
}
</script>
