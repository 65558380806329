export const state = {
  modulo: false,
  http: '',
  load: true,
  error: false,
  page: '',
  page1: '',
  page2: '',
  page3: '',
  page4: '',
  limite: '',
  utilizador: {},
  utilizador1: false,
  utilizador2: false,
  'tipos-documento': {},
  'tipos-documento1': false,
  'tipos-documento2': false,
  'idDocumento': [],
  tpDocumento: [],
  isencao: {},
  isencao1: false,
  isencao2: false,
  series: {},
  series1: false,
  series2: false,
  seriestotal: 0,
  loja: '',
  lojas: {},
  lojas1: false,
  lojas2: false,
  lojastotal: 0,
  'metodos-pagamento': {},
  'metodos-pagamento1': false,
  'metodos-pagamento2': false,
  'metodos-expedicao': {},
  'metodos-expedicao1': false,
  'metodos-expedicao2': false,
  impostos: {},
  impostos1: false,
  impostos2: false,
  pos: {},
  pos1: false,
  pos2: false,
  'unidades-medida': {},
  'unidades-medida1': false,
  'unidades-medida2': false,
  viaturas: {},
  viaturas1: false,
  viaturas2: false,
  tipos: {},
  tiposX: [],
  tipos1: false,
  tipos2: false,
  categorias: {},
  categoriasX: [],
  categorias1: false,
  categorias2: false,
  estados: {},
  estados1: false,
  estados2: false,
  template: {},
  template1: false,
  template2: false,
  impressoes: {},
  impressoes1: false,
  impressoes2: false,
  utilizadores: [],
  utilizadores1: false,
  utilizadores2: false,
  postits: [], // todos oe meus postits
  postit: '', // editar ou add postit
  help: {},
  aviso: '',
  converter: {},
  converter_result1: {},
  converterCompras: {},
  converterCompras1: [],
  menu: {},
  documents_bts: {},
  MailDoc: {},
  Demo: window.location.hostname.indexOf('dev.bill.pt') !== -1, // se for a versao demo fica a true
  MovStock: {},
  titulo: '',
  toast: {},
  menuConfiguracoes: '',
  scrollBottom: false,
  upStockItens: 0, // quando mudo a loja no menu da direita se tiver na listagem dos artigos atualizo o stock
  stockNegativo: true, // para saber se uma loja aceita stocknegativo,
  menuStock: '', // configurar menu de quebra de stock do documento em aberto
  encomendarOrcamentar: '', // no menu dos artigos as encomendas e os orcamentos criar
  lastKeypressTime: 0,
  bandeiras: [
    { lang: 'en', ico: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAQCAMAAADUOCSZAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAF9UExURaN9qYh7rrl8ouWBkUpcrFFhr1VksNOAl3ZwrtF+l414rIV1ro57rN9/ksF8m0dYqbO32fh0fz9RpvRuekZXqexPYOGRo/BYZseXs4mNwe1VZUVZq0NYqu5XZ/Fteml6u05frfaZozxQpvd0f0NUp0NWqGd5u0lbq0daqlBir39xrPWgqXlwrIx1qWdprdl1jcp4lGxqrFtnseZ7jVpiq6BzoPS5wfBmdNbT5vBreF1jq8GAn+5hcNzU5VhmsM92kut1hexRYvRyf5F1p9N1jvaWn996jb53mPNvfLp2mbi93OV7jV9nrlJfrbK22epygtSBmOVzhox8sd1/k6ZynamVvOequN18kZV2qNJ4kfWQm1ZirfCbpsibtnaGwt11io+UxsWXsoeLwfBXZnh3te1UZL+SsbuTs++Pm/Weppl5qLN2m9Glve2Pm+OWp612ncunv+eWpe6kr4Z0q755m7F/pLemxvCird+SpZhzo7ymxsN4lqh9p+CTpOl5imHcJw4AAAAPdFJOU5maIZz8/Pog+Zv5+ZqdIVjzdp0AAAE/SURBVBjTXZEFb8JQFEbvFJgV2GMGRdviOtx9uEpwn7v7fvtKIVu2kyT35Xx5yRVgcdhSDGEYxufyBDwun34hTMrmsACK5bOMEqGfBCGlu14uAiyVAkGPLqtCs4T+oPMEo6VlwER+vSl9oFHLmUSu1iTTpohfhIAg+EJDxWi0ubkKgYJrtUV7FbOQTxAgkUgoqlu9OjlyhgVh5/HlbbVLUbSFnQmDTk12aneEHHatrNYZMA42p4jFYhd+iLvoOjOwzTDCcXxvAl1HUwVbDC/tZkzrDXm1sWb7c6pgl2bYf7qTXfgSgoTvRvb81h9OJCBEiiz1RvzcnKK7Thmu443XjIhECDBSqkumAxGLPEdPmpPP5iYxWBw/5At66+92VK37Qv59vAhzxq9Hs/TvRg0f+701mF9Yaan+XyG7ur7xDZXbQwthp2PMAAAAAElFTkSuQmCC' },
    { lang: 'pt', ico: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAQCAMAAADUOCSZAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABgUExURe9WZVeoZO5VXlioZFeoY+9VZO1TY1WqXlaoYlepYu1VZe5VZleoY+1VZfRRZVCsY5WGZO9WZpWFZFerY+zHVmSvYqu8W/Z5YPVlYvuwWbaeX3qzYMqtXPmgW/rRU7+/WRiqmPgAAAAMdFJOU/qZHpybnJoe/Pn8Huu7C8gAAABXSURBVBjTrdHBDkBAEAPQYlmYxQIA//+XHCWmcTHXl0maFtZkIlVduud5xCGCVt5yW4JUVHGAEHGf0jDpl4nI0O1el/OYiazb+GM29Ye1k8PQRm2kr1BcluYUeLA1g7sAAAAASUVORK5CYII' }
  ],
  tourStep: '', // usado para identificar quando selecciona o contacto para recarregar a ajuda do tour
  // menu superior <nav>
  menuLoja: false,
  menuBandeiras: false,
  menuFlutuante: false,
  menuMobile: false,
  // fim menu superior </nav>
  modalSuporte: false, // estado do modal do suporte
  dev: window.location.hostname === 'dev.bill.pt', // se true tou em ambiente de testes, e mete o menu superior a amarelo
  aff: '',
  corSimbLojas: {}, // cor e bimolo das lojas
  corSimbEstados: {}, // cor simbolos dos estados
  itensMeta: [],
  itensMetaOrder: '',
  itensMeta1: false,
  itensMeta2: false,
  contactosMeta: [],
  contactosMetaOrder: '',
  contactosMeta1: false,
  contactosMeta2: false,
  documentosMeta: [],
  documentosMetaOrder: '',
  documentosMeta1: false,
  documentosMeta2: false,
  modalAT: false, // estado modal AT
  modalATForm: {},
  modalImprimir: '', // opções de impressao
  langData: {}, // lang datepicker
  reload: false, // obrigar a fazer reload a um pedido
  apiToken: '',
  mimeTypes: {
    '3dmf': 'x-world/x-3dmf',
    '3dm': 'x-world/x-3dmf',
    avi: 'video/x-msvideo',
    ai: 'application/postscript',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    cab: 'application/x-shockwave-flash',
    c: 'text/plain',
    'c++': 'text/plain',
    class: 'application/java',
    css: 'text/css',
    csv: 'text/comma-separated-values',
    cdr: 'application/cdr',
    doc: 'application/msword',
    dot: 'application/msword',
    docx: 'application/msword',
    dwg: 'application/acad',
    eps: 'application/postscript',
    exe: 'application/octet-stream',
    gif: 'image/gif',
    gz: 'application/gzip',
    gtar: 'application/x-gtar',
    flv: 'video/x-flv',
    fh4: 'image/x-freehand',
    fh5: 'image/x-freehand',
    fhc: 'image/x-freehand',
    help: 'application/x-helpfile',
    hlp: 'application/x-helpfile',
    html: 'text/html',
    htm: 'text/html',
    ico: 'image/x-icon',
    imap: 'application/x-httpd-imap',
    inf: 'application/inf',
    jpe: 'image/jpeg',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'application/x-javascript',
    java: 'text/x-java-source',
    latex: 'application/x-latex',
    log: 'text/plain',
    m3u: 'audio/x-mpequrl',
    midi: 'audio/midi',
    mid: 'audio/midi',
    mov: 'video/quicktime',
    mp3: 'audio/mpeg',
    mpeg: 'video/mpeg',
    mpg: 'video/mpeg',
    mp2: 'video/mpeg',
    ogg: 'application/ogg',
    phtml: 'application/x-httpd-php',
    php: 'application/x-httpd-php',
    pdf: 'application/pdf',
    pgp: 'application/pgp',
    png: 'image/png',
    pps: 'application/mspowerpoint',
    ppt: 'application/mspowerpoint',
    ppz: 'application/mspowerpoint',
    pot: 'application/mspowerpoint',
    ps: 'application/postscript',
    qt: 'video/quicktime',
    qd3d: 'x-world/x-3dmf',
    qd3: 'x-world/x-3dmf',
    qxd: 'application/x-quark-express',
    rar: 'application/x-rar-compressed',
    ra: 'audio/x-realaudio',
    ram: 'audio/x-pn-realaudio',
    rm: 'audio/x-pn-realaudio',
    rtf: 'text/rtf',
    spr: 'application/x-sprite',
    sprite: 'application/x-sprite',
    stream: 'audio/x-qt-stream',
    swf: 'application/x-shockwave-flash',
    svg: 'text/xml-svg',
    sgml: 'text/x-sgml',
    sgm: 'text/x-sgml',
    tar: 'application/x-tar',
    tiff: 'image/tiff',
    tif: 'image/tiff',
    tgz: 'application/x-compressed',
    tex: 'application/x-tex',
    txt: 'text/plain',
    vob: 'video/x-mpg',
    wav: 'audio/x-wav',
    wrl: 'model/vrml',
    xla: 'application/msexcel',
    xls: 'application/msexcel',
    xlc: 'application/vnd.ms-excel',
    xml: 'text/xml',
    zip: 'application/zip'
  }
}
